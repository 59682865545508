/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export const withRouter = (Component) => {
  function Wrapper(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const searchParams = useSearchParams();

    return (
      <Component
        router={{
          location, navigate, params, searchParams,
        }}
        {...props}
      />
    );
  }

  return Wrapper;
};
