import * as React from 'react';

function SvgPartnerLevelPlatinum() {
  return (
    <svg viewBox="0 0 60 60">
      <linearGradient
        id="PartnerLevel_platinum_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.782}
        y1={0.76}
        x2={30.179}
        y2={53.966}
      >
        <stop offset={0} stopColor="#6a6b66" />
        <stop offset={1} stopColor="#5c5e5a" />
      </linearGradient>
      <path
        d="M30 0C13.4 0 0 13.4 0 30s13.4 30 30 30 30-13.4 30-30S46.6 0 30 0zM18.7 20.9c4.5 0 8.2 3.7 8.2 8.2 0 4.5-3.7 8.2-8.2 8.2-4.5 0-8.2-3.7-8.2-8.2-.1-4.6 3.6-8.2 8.2-8.2zm17 36.8l-.9.6c-1.3.2-2.7.3-4.1.3-10.9 0-20.3-6-25.3-14.8 2.3-2.3 5.5-3.8 9.1-3.8h8.4c7.1 0 12.9 5.8 12.9 12.9v4.8zm11.8-23.5l-5.9-3.1-5.9 3.1 1.1-6.6-4.8-4.7 6.6-1 3-6 3 6 6.6 1-4.8 4.7 1.1 6.6z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#PartnerLevel_platinum_svg__a)"
      />
    </svg>
  );
}

export default SvgPartnerLevelPlatinum;
