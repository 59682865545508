/* eslint-disable class-methods-use-this */
// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// React Spectrum Components
import {
  // Button,
  ProgressCircle,
} from '@adobe/react-spectrum';

// I18n
import { Translate } from 'react-redux-i18n';

// React Router
import { withRouter } from './withRouter';

// Redux Actions
import { getMoreResults } from '../actions/filterActions';

// Utils
import { addCommaSeparator } from '../util/util';

// Analytics
// import Analytics from './Analytics';

// Styles
import './css/LoadMore.scss';

class LoadMore extends Component {
  /**
   * Load More Results
   * load the next 9 cards from the search results
   */
  // loadMoreResults = () => {
  //   const { getMoreResultsAction } = this.props;
  //   getMoreResultsAction();

  //   // Analytics
  //   Analytics.setEvent('LoadMoreResults:Click', 'filterInteraction');
  //   Analytics.track('event');
  //   Analytics.clearEvent();
  // };

  renderResultCount = (results) => {
    if (results.loadingResults && Object.keys(results.cards).length > 0) {
      return (
        <ProgressCircle
          size="S"
          isIndeterminate
          aria-label="load-more-spinner"
        />
      );
    }
    if (results.totalResults > 0) {
      return (
        <Translate
          value="partner_finder.cards.display_number_of_cards"
          resultLength={addCommaSeparator(Object.keys(results.cards).length)}
          totalResults={addCommaSeparator(results.totalResults)}
        />
      );
    }
    return null;
  };

  render() {
    const { results } = this.props;

    return (
      <div className="cmp-loadMore">
        <div className="displayed-results">
          {this.renderResultCount(results)}
        </div>
      </div>
    );
  }
}

LoadMore.propTypes = {
  /**
   * getMoreResultsAction {Function} - A function provided by mapDispatchToProps
   * that fetches the next 9 card results from the redux store
   */
  // getMoreResultsAction: PropTypes.func.isRequired,
  /**
   * results {Object} - An object provided by mapStateToProps
   * that contains the total results with the current applied filters,
   * loading indicator and cards information fetched from the api
   */
  results: PropTypes.shape({
    totalResults: PropTypes.number,
    loadingResults: PropTypes.bool,
    cards: PropTypes.shape({}),
  }),
};

LoadMore.defaultProps = {
  results: {},
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  results: state.results,
});

const mapDispatchToProps = {
  getMoreResultsAction: getMoreResults,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadMore));
