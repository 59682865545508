import {
  SET_PARTNER_DETAIL_DATA,
  SET_PREVIEW_MODE,
} from '../constants';

const INITIAL_STATE = {
  companyInfo: {},
  preview: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_PARTNER_DETAIL_DATA: {
      if (!payload.error) {
        return { ...state, ...payload, error: null };
      }
      return { ...state, ...payload };
    }
    case SET_PREVIEW_MODE: {
      return { ...state, preview: payload };
    }
    default:
      return state;
  }
};
