// React
import React from 'react';

// React Spectrum
import {
  error, info, warning, success,
} from '@react/react-spectrum/Toast';

// I18n
import { Translate } from 'react-redux-i18n';

// Default function
export default (payload) => {
  // Depending on the type
  switch (payload.type) {
    case 'error':
      // Display a toast notification.
      error(<Translate value={payload.textPath} />, {
        timeout: payload.autoDismissable ? 20000 : 0,
      });
      break;
    case 'warning':
      warning(<Translate value={payload.textPath} />, {
        timeout: payload.autoDismissable ? 20000 : 0,
      });
      break;
    case 'info':
      info(<Translate value={payload.textPath} />, {
        timeout: payload.autoDismissable ? 20000 : 0,
      });
      break;
    case 'info-static':
      info(<Translate value={payload.textPath} />, {
        timeout: 0,
        closable: false,
      });
      break;
    case 'success':
      success(<Translate value={payload.textPath} />, {
        timeout: payload.autoDismissable ? 20000 : 0,
      });
      break;
    case 'success-formSubmit':
      success(<Translate value={payload.textPath} partnerName={payload.partnerName} />, {
        timeout: payload.autoDismissable ? 20000 : 0,
      });
      break;
    default:
      break;
  }
};
