// Lodash
import _ from 'lodash';

// API
import api from '../api/directoryApi';

// Error Notification to the user
import notify from '../util/displayToastNotification';

// Constants
import {
  SET_FACET_OPTIONS,
  LOADED_FACETS,
  LOADING_FACETS,
} from '../constants';

// Gets all facet data
export const getAllFacetData = () => async (dispatch) => {
  // Loading Facets
  dispatch({
    type: LOADING_FACETS,
  });

  try {
    const result = await api.get(
      '/v1/spp/facets',
      {
        headers:
          {
            'x-api-key': window.directoryConfig.adobeIO.apiKey,
          },
      },
    );

    const facetData = result.data;

    dispatch({
      type: SET_FACET_OPTIONS,
      payload: { name: 'location.geos', value: facetData.regions },
    });

    const solutions = facetData.solutions.filter((solution) => solution.name !== 'Experience Manager(Assets)' && solution.name !== 'Experience Manager(Sites)');
    facetData.solutions = solutions;

    dispatch({
      type: SET_FACET_OPTIONS,
      payload: { name: 'solution.options', value: facetData.solutions },
    });

    dispatch({
      type: SET_FACET_OPTIONS,
      payload: { name: 'industry.options', value: facetData.industries },
    });

    const levelsOrderShownOnUI = {
      Gold: 2, Platinum: 1, Silver: 3, Bronze: 4,
    };

    if (facetData.levels?.length > 0) {
      for (let i = 0; i < facetData.levels.length; i += 1) {
        facetData.levels[i] = {
          ...facetData.levels[i],
          sortOrder: levelsOrderShownOnUI[facetData.levels[i].name]
            ? levelsOrderShownOnUI[facetData.levels[i].name]
            : levelsOrderShownOnUI.length + 1,
        };
      }
    }

    facetData.levels = facetData.levels.sort((a, b) => a.sortOrder - b.sortOrder);

    dispatch({
      type: SET_FACET_OPTIONS,
      payload: { name: 'level.options', value: facetData.levels },
    });

    dispatch({
      type: LOADED_FACETS,
    });
  } catch (err) {
    // Notify the user
    notify({
      type: 'error',
      textPath: 'partner_finder.errors.facet_api_failed',
      autoDismissable: false,
    });
    // Log to the console.
    window.console.error('Error: Problem loading facet data, API call failed.', err);
    // Tell New Relic about it
    if (window.newrelic) {
      window.newrelic.noticeError(
        err.message,
        {
          page: 'Card View',
          section: 'Filters',
          api: '/v1/spp/facets',
        },
      );
    }
  }
};

// Gets countries for a specific geo
export const getCountriesInGeo = (geo) => async (dispatch, getState) => {
  // Find the matching geo in our facets.
  const geoObj = _.find(getState().facets.location.geos, (o) => o.id === geo);

  // If we found a geo object and it has countries in it..
  if (geoObj && _.has(geoObj, 'countries')) {
    // Alphabetize the country list.
    const geoObjAlphabetized = _.orderBy(geoObj.countries, ['name'], ['asc']);

    // Set the list of countries in our redux store.
    dispatch({
      type: SET_FACET_OPTIONS,
      payload: {
        name: 'country.options',
        value: geoObjAlphabetized,
      },
    });
  }
};
