import React from 'react';

// I18n
import { Translate } from 'react-redux-i18n';

// CSS
import './css/HeroBlade.scss';

const HeroBlade = () => (
  <div className="cmp-heroBlade">
    <h1><Translate value="partner_finder.seo.app_title" /></h1>
    <h2><Translate value="partner_finder.seo.filter_page_description" /></h2>
    <div className="heroBlade-link-container">
      <a href={window.directoryConfig.findAdobePartner} className="heroBlade-link" target="_blank" rel="noreferrer">
        <Translate value="partner_finder.seo.learn_link" />
      </a>
    </div>
  </div>
);

export default HeroBlade;
