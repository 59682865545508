// React Imports
import React from 'react';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// React Spectrum Components
import { ActionButton, Text } from '@adobe/react-spectrum';
import { info } from '@react/react-spectrum/Toast';
import Share from '@spectrum-icons/workflow/Share';

// Styles
import './css/ShareProfileButton.scss';

const copyUrlToClipboard = () => {
  const placeholder = document.createElement('input');
  const url = window.location.href.split('#')[0];

  document.body.appendChild(placeholder);
  placeholder.value = url;
  placeholder.select();
  document.execCommand('copy');
  document.body.removeChild(placeholder);
};

const removeToast = () => {
  const nodes = document.querySelectorAll('.react-spectrum-ToastContainer');
  Array.prototype.forEach.call(nodes, (node) => {
    node.parentNode.removeChild(node);
  });
};

const ShareProfileButton = () => (
  <ActionButton
    UNSAFE_className="shareProfile"
    data-test-id="share-profile-btn"
    isQuiet
    aria-label="shareProfile"
    onPress={() => copyUrlToClipboard()}
    onPressEnd={() => {
      info(<Translate value="partner_finder.detail.share_url" />, {
        actionLabel: I18n.t('partner_finder.detail.confirm'),
        onAction: () => removeToast(),
        timeout: 5000,
      });
    }}
  >
    <Share />
    <Text><Translate value="partner_finder.detail.share_profile" /></Text>
  </ActionButton>
);

export default ShareProfileButton;
