import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';

import Filter from './pages/FilterPage';
import Detail from './pages/DetailPage';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="detail/:id" element={<Detail />} />
        <Route path="/" element={<Filter />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
