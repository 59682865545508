const translations = {
  en_US: {
    global: {
      ims: {
        sign_in: 'Sign In',
        sign_out: 'Sign Out',
      },
      header: {
        app_prefix_bold: 'Adobe',
        app_name: 'Solution Partner Directory',
      },
    },
    partner_finder: {
      seo: {
        app_title: 'Adobe Solution Partner Directory',
        filter_page_title: 'Find a Partner',
        filter_page_description: 'Adobe partners with thousands of companies globally to offer clients services and offerings that span expertise, experience and geography. Search our database to find the right Experience or Document Cloud partner for your next project.',
        learn_link: 'Learn how to find an Adobe partner',
      },
      filters: {
        accredited_solutions_label: 'Partner Solutions',
        accredited_solutions_header: 'Only show partners with Adobe validated Partner Solutions.',
        accredited_solutions_description: 'Learn more <a href="%{accreditedSolutionLink}" target="_blank" rel="noreferrer" style={{ color: "#1473E6" }}>Partner Solution</a> and <a href="%{cscInfoLink}" target="_blank" rel="noreferrer" style={{ color: "#1473E6" }}>Content Supply Chain.</a>',
        accredited_solutions_link_label: 'Accredited or Rapid Deployment Solutions',
        specialized_label: 'Specialized',
        specialized_header: 'Only show Specialized partners',
        specialized_description: 'Partner has proven technical and implementation proficiency in these Adobe products. Customers have validated a successful experience working with the partner.',
        specialized_description_learn_more: 'Learn more',
        location_label: 'Geographies',
        geo_tooltip: 'Select your region to filter for partners who serve it.',
        countries_label: 'Countries',
        country_tooltip: 'Adobe partners serve entire regions and countries. If a partner has a country listed, they do not necessarily operate a physical location there, but you can narrow your search by office location.',
        physical_location: 'Has a physical location',
        all_regions: 'All Regions',
        all_countries: 'All Countries/Regions',
        products_label: 'Applications',
        products_info: 'Solution Partners provide expertise and services on <a href="https://www.adobe.com/experience-cloud.html" target="_blank">Adobe Experience Cloud applications</a>.',
        industries_label: 'Industries',
        industries_info: 'Choose your industry to filter for partners which serve it. <a href="https://business.adobe.com/solutions/industries/adobe-industries.html" target="_blank">Learn more</a> about how Adobe meets unique industry needs',
        partner_level_label: 'Partner Levels',
        partner_level_info_new: 'Choose the Partner\'s Solution Partner Program level.<br/><b>Platinum:</b> Platinum partners must have four Specializations and 100 Adobe certified individuals.<br/><b>Gold:</b> Gold partners must have 1 Specialization and 60 Adobe certified individuals.<br/><b>Silver:</b> Silver partners must have 10 individuals with expert-level certifications, and another 40 Adobe certified individuals.<br/><b>Bronze:</b> Bronze partners don\'t have certification requirements, but are building their Adobe practices toward the Silver level.<br/>Learn more about <a href="https://solutionpartners.adobe.com/home/benefits.html" target="_blank">partner levels</a>.',
        partner_level_info: 'Choose the Partner\'s Solution Partner Program level. Learn more about <a href="https://solutionpartners.adobe.com/home/benefits.html" target="_blank">partner levels</a>.',
        clear: 'Clear',
        clear_all: 'Clear All',
        apply: 'Apply',
        company_size_label: 'Company Size',
        company_size_tooltip: 'You can request a proposal from a company of any size. Adobe Partners range from small partners who have extensive industry and application expertise, to large companies with expertise in deploying global digital marketing campaigns.',
        filters: 'Filters',
        more_filters: 'More Filters',
        results: 'Results',
        preview_results: '%{resultsCount} Results',
        no_results_header: 'No matching partners found',
        no_results_description: 'Try broadening your search.',
      },
      search: {
        search_for_partners: 'Search for partners',
        search_number_by_name: 'Search %{results} partners by name',
        no_results: 'No results found.',
        please_try_another_word: 'Please try another keyword.',
      },
      cards: {
        load_more: 'Load More',
        display_number_of_cards: '%{resultLength} of %{totalResults} displayed',
        specialized: 'Specialized',
        partner: 'Partner',
        platinum_partner: 'Platinum Partner',
        platinum_regional: 'Platinum (Regional) Partner',
        community_plus: 'Community Plus Partner',
        community_free: 'Community Free Partner',
        gold_partner: 'Gold Partner',
        silver_partner: 'Silver Partner',
        bronze_partner: 'Bronze Partner',
        number_certified: '%{employees} Certified employees',
        certifications: '%{count} Certifications',
      },
      content_blade: {
        contact_adobe: 'Contact Adobe',
        question_about_spp: 'Have questions about the Solution Partner Program?',
        support_email_address: 'SPPHelp@adobe.com',
        contact_support: 'Contact our support team at:',
        chat_with_support: 'Chat with an Adobe representative:',
        chat_now: 'Chat Now',
        update_create_profile: 'Update or Create a Profile',
        top_lead_generators: 'Partner Directory is one of the top lead generators, make sure customers can find your profile.',
        learn_about_managing_listing: 'Learn more',
        how_to_manage: ' about how to manage your company\'s listing.',
        not_a_partner: 'Not a partner? ',
        join_today: 'Join today!',
      },
      detail: {
        share_profile: 'Share Profile',
        share_url: 'Link copied to clipboard',
        confirm: 'Okay',
        credentials: 'Credentials',
        certifiedEmployees: 'Certified employees',
        customerDeployment: 'Customer deployments',
        certifications: 'Certifications',
        specializations: 'Specializations',
        visit_website: 'Visit Website',
        bronze_partner: 'Bronze Partner',
        silver_partner: 'Silver Partner',
        gold_partner: 'Gold Partner',
        platinum_partner: 'Platinum Partner',
        partner: 'Partner',
        back_to_results: 'Back to Results',
        message_partner: 'Message Partner',
        show_phone: 'Show Phone Number',
        company_info: 'Company Info',
        expertise: 'Expertise',
        accredited_solutions: 'Solutions',
        services_and_industries: 'Services & Industries',
        locations: 'Locations',
        resources: 'Resources',
        company_info_tab: {
          about: 'About',
          subsidiary: ' is a subsidiary of ',
        },
        expertise_tab: {
          expertise_header: 'Solution Expertise',
          expertise_description: 'Specialized Partners demonstrate consistent customer success and have Adobe Certified Experts on staff to ensure successful customer implementations. Learn more about <a href="https://solutionpartners.adobe.com/solution-partners/specialization.html" target="_blank">specialized partners</a>.',
          solution_certification: '%{totalCertifications} Credentials',
          global_specialization: 'Global Specialization',
          specialized: 'Specialized',
          deployment_card_info: 'Partner\'s submitted customer deployment data from the last two years.',
          expertise_description_2: ' number of partner\'s submitted Customer Deployments by application within the past two years.',
        },
        accredited_solutions_tab: {
          accredited_solutions_header: 'Partner Solutions',
          accredited_solutions_description: 'Adobe verified and market tested solutions that accelerate transformation and solve business challenges.',
          solution_brief_solutions_header: 'Solutions',
          solution_brief_industries_header: 'Industries',
          modified_string_and: 'and',
          view_solution_brief: 'View Solution Brief',
          related_resources: 'Related Resources',
          solution_website: 'Solution website',
        },
        services_industries_tab: {
          services_header: 'Services Offered',
          services_description: 'The following lists all of the services the partner offers.',
          industries_header: 'Industries Served',
          industries_description: 'The following lists all of the industries the partner serves.',
          learn_more: 'Learn more',
        },
        locations_tab: {
          locations_header: 'Locations',
          headquarters: 'Headquarters',
        },
        resources_tab: {
          videos: 'Videos',
          view_video: 'View video',
          case_studies: 'Success Studies',
          case_study_link: 'View success study',
          white_papers: 'White Papers',
          white_paper_download: 'View white paper',
          data_sheets: 'Datasheets',
          data_sheet_view: 'View datasheet',
        },
        partner_not_found: 'Partner Not Found',
        partner_not_found_description: 'We weren\'t able to find that partner. Please check the URL or go back to search for a partner.',
        return_to_search: 'Return to Search',
      },
      contactForm: {
        contact_partner: 'Contact %{partnerName}',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phoneNumber: 'Phone Number',
        company: 'Company',
        country: 'Country',
        jobRole: 'Job Role',
        solutionOfInterest: 'Primary Application of Interest',
        message: 'Message',
        submit_success: 'Your message was sent successfully to %{partnerName}',
        send_message: 'Send Message',
        disabled_preview: 'Submission Disabled in Preview Mode',
        error_lastName: 'Last name is required!',
        error_firstName: 'First name is required!',
        error_country: 'Country is required!',
        error_company: 'Company is required!',
        error_jobRole: 'Role is required!',
        error_message: 'Message is required!',
        error_soluton: 'Select at least one solution of interest',
        error_email: 'Email is required!',
        error_recaptcha: 'reCAPTCHA validation failed. If the issue persists, please contact the partner by visiting their website.',
        invalid_email: 'Invalid email address',
        error_phoneNumber: 'Phone number is required!',
        invalid_phoneNumber: 'Invalid phone number',
        multi_select_placeholder: 'Select an option...',
      },
      errors: {
        facet_api_failed: 'There was a problem loading the available filters. Please refresh the page or try again later.',
        get_countries_api_failed: 'There was a problem loading the available countries. Please refresh the page, or try filtering with other criteria.',
        get_results_api_failed: 'There was a problem fetching the list of partners. Please try filtering again, or refresh the page.',
        get_preview_results_api_failed: 'There was a problem loading the number of potential partner matches. Please try filtering again, or refresh the page.',
        unrecognized_filter_param: 'We discovered unknown filter criteria in the link you used, it will not be applied to your search.',
        get_content_blades_api_failed: 'There was a problem loading the content blades on this page. Please refresh the page or try again later.',
        get_partner_detail_api_failed: 'There was a problem loading the partner detail on this page. Please refresh the page or try again later.',
        submit_contact_partner_form_api_failed: 'There was a problem sending your message to the partner. Please refresh the page or try again later.',
        get_partner_data_api_failed: 'There was a problem fetching the data of the partner. Please refresh the page or try again later.',
      },
      preview_mode: {
        viewing_preview: 'PREVIEW MODE: This is a preview of your draft listing and is only accessible to you.',
      },
    },
  },
};

export default translations;
