// API
import api from '../api/directoryApi';

// Notification
// import notify from '../util/displayToastNotification';

export default (programType) => new Promise((resolve, reject) => {
  // Make the API call
  api.get(
    `${window.directoryConfig.partner.apcEndPoint}v1/${programType}/partner`,
    {
      headers: {
        Authorization: window.adobeIMS.getAccessToken().token,
        'x-api-key': window.directoryConfig.adobeIO.partnerApiKey,
      },
    },
  ).then((result) => {
    resolve(result);
  }).catch((error) => {
    if (error && error.response && error.response.status === 403) {
      reject(error);
    } else {
      // API call failed, throw an error.
      // notify(
      //   {
      //     type: 'error',
      //     textPath: 'partner_finder.errors.get_partner_data_api_failed',
      //     autoDismissable: true,
      //   },
      // );
      reject(error);
    }
    window.console.error('Error: Problem with fetching partner data, call failed.', error);
  });
});
