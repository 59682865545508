import * as React from 'react';

function SvgPartnerLevelSilver() {
  return (
    <svg viewBox="0 0 60 60">
      <linearGradient
        id="PartnerLevel_silver_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.782}
        y1={0.76}
        x2={30.179}
        y2={53.966}
      >
        <stop offset={0} stopColor="#a5a6a8" />
        <stop offset={1} stopColor="#909193" />
      </linearGradient>
      <path
        d="M30 0C13.4 0 0 13.4 0 30s13.4 30 30 30 30-13.4 30-30S46.6 0 30 0zM18.7 20.9c4.5 0 8.2 3.7 8.2 8.2 0 4.5-3.7 8.2-8.2 8.2-4.5 0-8.2-3.7-8.2-8.2-.1-4.6 3.6-8.2 8.2-8.2zm17 36.8l-.9.6c-1.3.2-2.7.3-4.1.3-10.9 0-20.3-6-25.3-14.8 2.3-2.3 5.5-3.8 9.1-3.8h8.4c7.1 0 12.9 5.8 12.9 12.9v4.8zm3.6-24.3l-6.9-5.6 1.2-1.5 5.4 4.4 8.4-10.5 1.5 1.2-9.6 12z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#PartnerLevel_silver_svg__a)"
      />
    </svg>
  );
}

export default SvgPartnerLevelSilver;
