/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

export default {

  init() {
    // Any initialization can go here, including required data.
    window.digitalData = {
      search: {
        searchInfo: {},
        primaryFilter: {},
        filter: [],
      },
      primaryEvent: {},
    };
  },

  set(dataName, dataValue) {
    if (window.digitalData && typeof window.digitalData._set === 'function') {
      window.digitalData._set(dataName, dataValue);
    }
  },

  clear(dataName) {
    if (window.digitalData && typeof window.digitalData._delete === 'function') {
      window.digitalData._delete(dataName);
    }
  },

  // Sets multiple items at once.
  setMultipleProps(array) {
    array.forEach((item) => {
      this.set(item.path, item.value);
    });
  },

  // Clears multiple items at once.
  clearMultipleProps(array) {
    array.forEach((item) => {
      this.clear(item.path);
    });
  },

  setEvent(eventName, eventAction) {
    // Set the event name and action.
    this.set('primaryEvent.eventInfo.eventName', `PartnerDirectory:${eventName}`);
    this.set('primaryEvent.eventInfo.eventAction', eventAction);
  },

  clearEvent() {
    this.clear('primaryEvent.eventInfo.eventName');
    this.clear('primaryEvent.eventInfo.eventAction');
  },

  track(type) {
    let activeGroups = [];
    if (window.adobePrivacy && typeof window.adobePrivacy.activeCookieGroups === 'function') {
      activeGroups = window.adobePrivacy.activeCookieGroups();
    }

    if (window._satellite && (window.digitalData && typeof window.digitalData._snapshot === 'function') && activeGroups.indexOf('C0002') !== -1) {
      window._satellite.track(type, { digitalData: window.digitalData._snapshot() });
    }
  },

  // Returns an array we can use in Analytics.
  getSetFiltersFromState(state) {
    const setFilters = [];
    let updatedValue;

    _.forOwn(state, (value, key) => {
      switch (key) {
        case 'location':
          // Handle geo and country unique case
          _.forOwn(value, (locationValue, locationKey) => {
            setFilters.push(
              {
                filterInfo: {
                  keyword: locationValue,
                  category: locationKey,
                },
              },
            );
          });
          break;
        default:

          // Handle different data types.
          if (typeof value === 'object') {
            updatedValue = value.join(', ');
          } else if (typeof value === 'boolean') {
            updatedValue = 'Applied';
          } else {
            updatedValue = value;
          }

          // Set the key/value as the filter name/value
          setFilters.push(
            {
              filterInfo: {
                keyword: updatedValue,
                category: key,
              },
            },
          );
          break;
      }
    });

    return setFilters;
  },

};
