// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// i18n
import { Translate } from 'react-redux-i18n';

// Lodash
import _ from 'lodash';

// Spectrum
import {
  ProgressCircle,
} from '@adobe/react-spectrum';

// Framer Motion
import { AnimatePresence, motion } from 'framer-motion';

// Icons
import Ribbon from '@spectrum-icons/workflow/Ribbon';
import Trophy from '@spectrum-icons/workflow/Trophy';

// Preload action
import { getPartnerDetail } from '../actions/partnerDetailAction';

// Utils
import { addCommaSeparator } from '../util/util';

// CSS
import './css/ListingCard.scss';

class ListingCard extends Component {
  /** handleCardClick
     * Handles clicking on a card.
     */
  handleCardClick = (id) => {
    const { getPartnerDetailAction, router } = this.props;

    // Pre-loads partner data and then navigates.
    getPartnerDetailAction(id, true, router);
  };

  /** handleCardKey
     * Handles key press on a card.
     */
  handleCardKey = (e, id) => {
    const { getPartnerDetailAction, router } = this.props;

    if (e.key === 'Enter') {
      // Pre-loads partner data and then navigates.
      getPartnerDetailAction(id, true, router);
    }
  };

  /** renderCard
     * Renders a card.
     */
  renderCard = () => {
    const {
      preloading,
      url,
      partnerName,
      shortDescription,
      partnerLevel,
      partnerLogoUrl,
      certifications,
      certifiedEmployees,
      specialized,
      badge,
    } = this.props;

    /** renderPartnerLevel
       * Returns a translated partner level.
       */
    const renderPartnerLevel = () => {
      switch (partnerLevel) {
        case 'Bronze':
          return <Translate value="partner_finder.cards.bronze_partner" />;
        case 'Silver':
          return <Translate value="partner_finder.cards.silver_partner" />;
        case 'Gold':
          return <Translate value="partner_finder.cards.gold_partner" />;
        case 'Platinum':
          return <Translate value="partner_finder.cards.platinum_partner" />;
        case 'Platinum (Regional)':
          return <Translate value="partner_finder.cards.platinum_regional" />;
        case 'Community Plus':
          return <Translate value="partner_finder.cards.community_plus" />;
        case 'Community Free':
          return <Translate value="partner_finder.cards.community_free" />;
        default:
          return <Translate value="partner_finder.cards.partner" />;
      }
    };

    return (
      <div data-test-id="cmp-listing-card" className="cmp-listing-card" tabIndex={0} role="button" onClick={() => this.handleCardClick(url)} onKeyPress={(e) => this.handleCardKey(e, url)}>
        <AnimatePresence>
          { preloading ? (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.5 }}
              className="preloading-overlay"
            >
              <ProgressCircle aria-label="Loading…" isIndeterminate />
            </motion.div>
          ) : null }
        </AnimatePresence>
        { specialized ? (
          <div className="top-right-ribbon">
            <Translate value="partner_finder.cards.specialized" />
          </div>
        ) : null}
        <div className="logo-container">
          <div className="logo" style={{ backgroundImage: `url(${partnerLogoUrl})` }} />
          {badge && (
          <div className="award-info-container">
            <Trophy size="S" />
            <span className="award-info">
              {badge}
            </span>
          </div>
          )}
        </div>
        <div className="content-section">
          <span className="partner-level">
            {renderPartnerLevel()}
          </span>
          <h1 className="partner-name">
            {partnerName}
          </h1>
          <span className="short-description">
            {_.truncate(shortDescription, {
              length: 180,
              separator: /,? +/,
            })}
          </span>
          { (certifiedEmployees > 0 || certifications > 0)
            ? (
              <div className="card-certification-details">
                { certifiedEmployees > 0
                  ? (
                    <div className="certified-employees">
                      <Ribbon />
                      <Translate value="partner_finder.cards.number_certified" employees={addCommaSeparator(certifiedEmployees)} />
                    </div>
                  ) : null }

                { certifications > 0
                  ? (
                    <div className="certifications">
                      <svg viewBox="0 0 36 36" className="spectrum-Icon_368b34 spectrum-Icon--sizeM_368b34" focusable="false" aria-hidden="true" role="img"><circle cx="18" cy="18" r="16" /></svg>
                      <Translate value="partner_finder.cards.certifications" count={addCommaSeparator(certifications)} />
                    </div>
                  ) : null }
              </div>
            ) : null}
        </div>
      </div>
    );
  };

  render() {
    return this.renderCard();
  }
}

ListingCard.propTypes = {
  /**
   * router {Object} The router object provided by React Router. Used to navigate to other pages.
   */
  router: PropTypes.shape({
    navigate: PropTypes.func,
  }).isRequired,
  /**
   * getPartnerDetailAction {Function} The function to pre-load partner detail data. Provided by
   * mapDispatchToProps.
   */
  getPartnerDetailAction: PropTypes.func.isRequired,
  /**
   * preloading {Boolean} Whether the partner detail is being preloaded.
   */
  preloading: PropTypes.bool,
  /**
   * url {String} The id/url of the partner detail.
   */
  url: PropTypes.string.isRequired,
  /**
   * partnerName {String} The Partner's Name
   */
  partnerName: PropTypes.string.isRequired,
  /**
   * shortDescription {String} The short description of the partner.
   */
  shortDescription: PropTypes.string,
  /**
   * partnerLevel {String} The level of the partner.
   */
  partnerLevel: PropTypes.string.isRequired,
  /**
   * partnerLogoUrl {String} The URL of the partner's logo.
   */
  partnerLogoUrl: PropTypes.string,
  /**
   * certifiedEmployees {Number} How many certified employees partner has.
   */
  certifiedEmployees: PropTypes.number,
  /**
   *  {Number} of certifications.
   */
  certifications: PropTypes.number,
  /**
   * specialized {Boolean} Whether this partner is specialized.
   */
  specialized: PropTypes.bool,
  /**
   * badge {String} award the partner has got
   */
  badge: PropTypes.string,
};

ListingCard.defaultProps = {
  preloading: null,
  specialized: null,
  certifiedEmployees: 0,
  certifications: 0,
  shortDescription: '',
  partnerLogoUrl: '',
  badge: '',
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
  getPartnerDetailAction: getPartnerDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingCard);
