import * as React from 'react';

function BronzeLevel() {
  return (
    <svg viewBox="0 0 60 60">
      <linearGradient
        id="PartnerLevel_bronze_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.782}
        y1={0.76}
        x2={30.179}
        y2={53.966}
      >
        <stop offset={0} stopColor="#cb581c" />
        <stop offset={1} stopColor="#bd5319" />
      </linearGradient>
      <path
        d="M30 0C13.4 0 0 13.4 0 30s13.4 30 30 30 30-13.4 30-30S46.6 0 30 0zm0 22.6c4.2 0 7.7 3.4 7.7 7.7S34.2 38 30 38s-7.7-3.4-7.7-7.7 3.5-7.7 7.7-7.7zM45.9 54c-4.5 3.1-10 4.9-15.9 4.9S18.6 57.1 14.1 54v-1.5c0-6.6 5.4-12 12-12h7.8c6.6 0 12 5.4 12 12V54z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#PartnerLevel_bronze_svg__a)"
      />
    </svg>
  );
}

export default BronzeLevel;
