// React
import React from 'react';
import PropTypes from 'prop-types';

// React Spectrum components
import {
  Divider,
} from '@adobe/react-spectrum';

// Icon
import Check from '@spectrum-icons/workflow/CheckmarkCircle';

// I18n
import { Translate } from 'react-redux-i18n';

// Utils
import { addCommaSeparator } from '../util/util';

// Styles
import './css/RegionSpecialization.scss';
import { CUSTOMER_DEPLOYMENTS } from '../constants';

const RegionSpecialization = ({ region, type, geo }) => (
  <div className="region-container">
    <div className="region-upper-section">
      <div className="region-name">{type === CUSTOMER_DEPLOYMENTS ? geo : region.name}</div>
      {region.specialized && (
        <div className="specialized-box">
          <Check size="S" />
          <Translate value="partner_finder.detail.expertise_tab.specialized" />
        </div>
      )}
    </div>
    <div className="region-lower-section">
      {
        (type === CUSTOMER_DEPLOYMENTS && region)
          ? region.map((solution, index) => (
            <div className="specialized-staff" key={solution.solutionName}>
              <div className="staff-role">
                <div>{solution.solutionName}</div>
                <div>{addCommaSeparator(solution.count)}</div>
              </div>
              {index < region.length - 1 && <Divider size="S" />}
            </div>
          ))
          : region?.roles.map((role, index) => (
            <div className="specialized-staff" key={role.name}>
              <div className="staff-role">
                <div>{role.name}</div>
                <div>{addCommaSeparator(role.certifiedEmployees)}</div>
              </div>
              {index < region.roles.length - 1 && <Divider size="S" />}
            </div>
          ))
      }
    </div>
  </div>
);

//   region - PropTypes.shape({
//   name: PropTypes.string,
//   specialized: PropTypes.bool,
//   roles: PropTypes.arrayOf(PropTypes.object),
//   solutions: PropTypes.arrayOf(PropTypes.object),
// })

RegionSpecialization.propTypes = {
  region: PropTypes.any,
  type: PropTypes.any,
  geo: PropTypes.any,
};

RegionSpecialization.defaultProps = {
  region: null,
  type: '',
  geo: null,
};

export default RegionSpecialization;
