// React
import React from 'react';
import PropTypes from 'prop-types';

// React Spectrum components
import { Button } from '@adobe/react-spectrum';

// I18n
import { Translate } from 'react-redux-i18n';

// Utils
import { normalizeUrl } from '../util/util';

// Styles
import './css/OpenURLButton.scss';

const OpenURLButton = ({
  variant, translatePath, websiteUrl, target,
}) => {
  /**
   * openLink
   * Opens a new link.
   */
  const openLink = () => {
    window.open(normalizeUrl(websiteUrl), target);
  };

  return websiteUrl && (
    <Button
      variant={variant}
      onPress={openLink}
      data-test-id="open-url-button"
      UNSAFE_className="open-url-button"
    >
      <Translate value={translatePath} />
    </Button>
  );
};

OpenURLButton.propTypes = {
  variant: PropTypes.string.isRequired,
  translatePath: PropTypes.string.isRequired,
  websiteUrl: PropTypes.string,
  target: PropTypes.string.isRequired,
};
OpenURLButton.defaultProps = {
  websiteUrl: null,
};

export default OpenURLButton;
