export default {

  loadAEPTracking() {
    // Load  Library
    const aepTracking = document.createElement('script');
    aepTracking.src = window.directoryConfig.aep.jsLib;
    aepTracking.async = true;
    document.head.appendChild(aepTracking);
  },
  init() {
    this.loadAEPTracking();
  },
};
