// Content Blade Constants
export const ADD_CONTENT_BLADE = 'ADD_CONTENT_BLADE';
export const LOADING_CONTENT_BLADE = 'LOADING_CONTENT_BLADE';
export const LOADED_CONTENT_BLADE = 'LOADED_CONTENT_BLADE';

// Facet Constants
export const LOADING_FACETS = 'LOADING_FACETS';
export const LOADED_FACETS = 'LOADED_FACETS';
export const SET_FACET_OPTIONS = 'SET_FACET_OPTIONS';
export const GET_FACET_PREVIEW_MATCHES = 'GET_FACET_PREVIEW_MATCHES';
export const SET_FACET_PREVIEW_MATCHES = 'SET_FACET_PREVIEW_MATCHES';
export const SET_APP_INITIALIZED = 'SET_APP_INITIALIZED';

// Filter Constants
export const ADD_SINGLE_FILTER = 'ADD_SINGLE_FILTER';
export const REMOVE_SINGLE_FILTER = 'REMOVE_SINGLE_FILTER';
export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const EXPAND_FILTERS = 'EXPAND_FILTERS';
export const CONTRACT_FILTERS = 'CONTRACT_FILTERS';

// Results Constants
export const SET_TOTAL_RESULTS = 'SET_TOTAL_RESULTS';
export const LOADING_RESULTS = 'LOADING_RESULTS';
export const LOADING_MORE_RESULTS = 'LOADING_MORE_RESULTS';
export const ADD_RESULT_CARDS = 'ADD_RESULT_CARDS';
export const SET_RESULT_CARDS = 'SET_RESULT_CARDS';
export const PRELOADING_DETAIL = 'PRELOADING_DETAIL';
export const CLEAR_PRELOADING_DETAIL = 'CLEAR_PRELOADING_DETAIL';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';

// Partner Detail Page Constants
export const SET_PARTNER_DETAIL_DATA = 'SET_PARTNER_DETAIL_DATA';
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE';

// Form Constants
export const SUBMIT_FORM = 'SUBMIT_FORM';

export const CUSTOMER_DEPLOYMENTS = 'Customer Deployments';

export const CONTENT_SUPPLY_CHAIN = 'Content Supply Chain';
