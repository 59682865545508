import React from 'react';
import PropTypes from 'prop-types';

// Spectrum
import {
  IllustratedMessage,
  Heading,
  Content,
  Button,
} from '@adobe/react-spectrum';

// Spectrum Illustration
import NoSearchResults from '@spectrum-icons/illustrations/NoSearchResults';

// SEO
import { Helmet } from 'react-helmet';

// I18n
import { Translate, I18n } from 'react-redux-i18n';

// React Router
import { withRouter } from './withRouter';

// CSS
import './css/DetailNotFound.scss';

const returnToSearch = (router) => {
  router.navigate('/');
};

function DetailNotFound({ router }) {
  return (
    <div className="cmp-DetailNotFound">
      <Helmet titleTemplate={`%s | ${I18n.t('partner_finder.seo.app_title')}`}>
        <title>
          {I18n.t('partner_finder.detail.partner_not_found')}
        </title>
        <meta name="description" content={I18n.t('partner_finder.detail.partner_not_found')} />

        <meta property="og:title" content={`${I18n.t('partner_finder.detail.partner_not_found')} | ${I18n.t('partner_finder.seo.app_title')}`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={I18n.t('partner_finder.seo.app_title')} />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/thumb.png`} />
        <meta property="og:description" content={I18n.t('partner_finder.detail.partner_not_found')} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="480" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${I18n.t('partner_finder.detail.partner_not_found')} | ${I18n.t('partner_finder.seo.app_title')}`} />
        <meta name="twitter:description" content={I18n.t('partner_finder.detail.partner_not_found')} />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/img/thumb.png`} />
        <meta name="twitter:image:alt" content="Adobe" />
        <meta name="twitter:site" content="@Adobe" />
        <meta name="twitter:creator" content="@Adobe" />
      </Helmet>
      <IllustratedMessage>
        <NoSearchResults />
        <Heading><Translate value="partner_finder.detail.partner_not_found" /></Heading>
        <Content><Translate value="partner_finder.detail.partner_not_found_description" /></Content>
        <Button
          variant="cta"
          data-test-id="return-to-search"
          onPress={() => returnToSearch(router)}
          UNSAFE_className="return-to-results-btn"
        >
          <Translate value="partner_finder.detail.return_to_search" />
        </Button>
      </IllustratedMessage>

    </div>
  );
}
DetailNotFound.propTypes = {
  router: PropTypes.shape({}).isRequired,
};

export default withRouter(DetailNotFound);
