import _ from 'lodash';

import {
  ADD_SINGLE_FILTER,
  REMOVE_SINGLE_FILTER,
  LOADING_RESULTS,
  LOADING_MORE_RESULTS,
  SET_TOTAL_RESULTS,
  ADD_RESULT_CARDS,
  SET_RESULT_CARDS,
  SET_FACET_PREVIEW_MATCHES,
  GET_FACET_PREVIEW_MATCHES,
  EXPAND_FILTERS,
  CONTRACT_FILTERS,
  CLEAR_ALL_FILTERS,
} from '../constants';
import { getCountriesInGeo } from './facetsActions';
import api from '../api/directoryApi';

// Error Notification to the user
import notify from '../util/displayToastNotification';

/**
 * Get Params From State
 * Loops through the selected values in redux and adds them to a URLSearchParams object.
 * @param {Function} getState - the getState function provided by redux.
 * @param {Boolean} returnAsString - whether to return the URLParams object or just a string.
 * @param {Array} extraParams - an optional list of extra parameters - an array of objects.
 * @returns {URLSearchParams} - A URLSearchParams object with the current filter values.
 */
const getParamsFromState = (getState, returnAsString, extraParams) => {
  // Create the object.
  const params = new URLSearchParams();

  // For every selected item
  _.forOwn(getState().filter.selected, (value, key) => {
    switch (key) {
      case 'location':
        // Handle geo and country unique case
        _.forOwn(value, (locationValue, locationKey) => {
          params.set(locationKey, locationValue);
        });
        break;
      default:
        // Set the key/value as the filter name/value
        params.set(key, value);
        break;
    }
  });

  // Add extra parameters.
  if (extraParams) {
    _.each(extraParams, (obj) => {
      params.set(obj.key, obj.value);
    });
  }

  if (returnAsString) {
    // If we have parameters, return as a string with question mark, otherwise return nothing.
    return params.toString().length ? `?${params.toString()}` : '';
  }

  return params;
};

/**
 * Update Query Params from Filter State
 * Updates the browser's URL with the selected filter data.
 * @param {Object} router - this router object from React Router
 */
export const updateQueryParamsFromFilterState = (router) => async (dispatch, getState) => {
  // Get the current params object based on the current filter state.
  const params = getParamsFromState(getState);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = router.searchParams;

  // Update the browser's URL
  setSearchParams(params.toString());
};

/**
 * Get Results with Set Filters
 * On filter change, gets results with the currently set filters.
 */
export const getResultsWithSetFilters = () => async (dispatch, getState) => {
  // Loading indicator
  dispatch({ type: LOADING_RESULTS });

  // Get the params object with filters.
  const params = getParamsFromState(getState, true);

  // Make the API call
  try {
    const result = await api.get(
      `/v1/spp/listings${params}`,
      {
        headers:
        {
          'x-api-key': window.directoryConfig.adobeIO.apiKey,
        },
      },
    );

    // Get the results
    const { listings, totalResults } = result.data;

    // Set number of results and the actual cards.
    dispatch({ type: SET_TOTAL_RESULTS, payload: totalResults });
    dispatch({ type: SET_RESULT_CARDS, payload: listings });
    dispatch({
      type: SET_FACET_PREVIEW_MATCHES,
      payload: {
        name: 'country',
        value: totalResults,
      },
    });
    dispatch({
      type: SET_FACET_PREVIEW_MATCHES,
      payload: {
        name: 'solution',
        value: totalResults,
      },
    });
    dispatch({
      type: SET_FACET_PREVIEW_MATCHES,
      payload: {
        name: 'industry',
        value: totalResults,
      },
    });
    dispatch({
      type: SET_FACET_PREVIEW_MATCHES,
      payload: {
        name: 'level',
        value: totalResults,
      },
    });
  } catch (err) {
  // Notify the user
    notify({
      type: 'error',
      textPath: 'partner_finder.errors.get_results_api_failed',
      autoDismissable: true,
    });
    // Log to the console.
    window.console.error('Error: Problem fetching results data, API call failed.', err);
    // Tell New Relic about it
    if (window.newrelic) {
      window.newrelic.noticeError(
        err.message,
        {
          page: 'Card View',
          section: 'Results',
          api: '/v1/spp/listings',
        },
      );
    }
  }
};

/**
 * Get More Results
 * Gets results starting from the last card we have so far.
 */
export const getMoreResults = () => async (dispatch, getState) => {
  // Display loading indicator.
  dispatch({ type: LOADING_MORE_RESULTS });

  // Get the starting page (based on how many cards we have currently)
  const page = Math.ceil(Object.keys(getState().results.cards).length / 9);

  const params = getParamsFromState(getState, true, [{ key: 'page', value: page }]);

  // Make the API call.
  try {
    const result = await api.get(
      `/v1/spp/listings${params}`,
      {
        headers:
        {
          'x-api-key': window.directoryConfig.adobeIO.apiKey,
        },
      },
    );

    const { listings, totalResults } = result.data;

    // Update total results if it changed, and append the cards to the end.
    dispatch({ type: SET_TOTAL_RESULTS, payload: totalResults });
    dispatch({ type: ADD_RESULT_CARDS, payload: listings });
  } catch (err) {
  // Notify the user
    notify({
      type: 'error',
      textPath: 'partner_finder.errors.get_results_api_failed',
      autoDismissable: true,
    });
    // Log to the console.
    window.console.error('Error: Problem fetching results data, API call failed.', err);
    // Tell New Relic about it
    if (window.newrelic) {
      window.newrelic.noticeError(
        err.message,
        {
          page: 'Card View',
          section: 'Results',
          api: '/v1/spp/listings',
        },
      );
    }
  }
};

/**
 * Get Preview Matches
 * Gets preview matches for a specified filter.
 * @param {String} filterName - the filter name we want to get preview matches for.
 */
export const getPreviewMatches = (filterName, localState) => async (dispatch, getState) => {
  dispatch({ type: GET_FACET_PREVIEW_MATCHES, payload: { name: filterName } });

  // Grab all selected filters.
  const params = getParamsFromState(getState);

  // If the filter we're previewing is already set, disregard it.
  if (params.has(filterName)) {
    params.delete(filterName);
  }

  // Instead, set the URL with our preview options.
  if (localState.length) {
    params.set(filterName, localState);
  }

  // Make the API call
  try {
    const result = await api.get(
      `/v1/spp/listings?${params.toString()}`,
      {
        headers:
      {
        'x-api-key': window.directoryConfig.adobeIO.apiKey,
      },
      },
    );

    const resultData = result.data;

    // Set the number of preview results for the specific filter.
    dispatch(
      {
        type: SET_FACET_PREVIEW_MATCHES,
        payload: { name: filterName, value: resultData.totalResults },
      },
    );
  } catch (err) {
  // Notify the user
    notify({
      type: 'error',
      textPath: 'partner_finder.errors.get_preview_results_api_failed',
      autoDismissable: true,
    });
    // Log to the console.
    window.console.error('Error: Problem fetching preview results data, API call failed.', err);
    // Tell New Relic about it
    if (window.newrelic) {
      window.newrelic.noticeError(
        err.message,
        {
          page: 'Card View',
          section: 'Filter Preview Results',
          api: '/v1/spp/listings',
        },
      );
    }
  }
};

/**
 * Add Single Filter
 * Adds a single filter to the store.
 * @param {Object} filterData - the payload key/value pair to set the filter.
 * @param {Object} router - the router object provided by React Router
 */
export const addSingleFilter = (filterData, router) => async (dispatch) => {
  dispatch({
    type: ADD_SINGLE_FILTER,
    payload: filterData,
  });
  setTimeout(() => {
    dispatch(updateQueryParamsFromFilterState(router));
    dispatch(getResultsWithSetFilters());
  }, [10]);
};

/**
 * Remove Single Filter
 * Removes a single filter from the store.
 * @param {Object} filterData - the payload key/value pair to set the filter.
 * @param {Object} router - the router object provided by React Router
 */
export const removeSingleFilter = (filterData, router) => async (dispatch) => {
  dispatch({
    type: REMOVE_SINGLE_FILTER,
    payload: filterData,
  });
  dispatch(updateQueryParamsFromFilterState(router));
  dispatch(getResultsWithSetFilters());
};

/**
 * TODO: Add Multiple Filters (array)
 */

/**
 * Remove Multiple Filters
 * Removes an array of filters from the store.
 * @param {Array[String]} filterData - an array of filter names
 */
export const removeMultipleFilters = (filterData, router) => async (dispatch) => {
  // Loop through each filter.
  if (filterData.length) {
    filterData.forEach((filter) => {
      dispatch({
        type: REMOVE_SINGLE_FILTER,
        payload: filter,
      });
    });
  }

  // Then update and fetch results.
  dispatch(updateQueryParamsFromFilterState(router));
  dispatch(getResultsWithSetFilters());
};

/**
 * Rehydrate Filter State from Query Params
 * Takes the query parameters and sets the filters in the state.
 */
export const rehydrateFilterStateFromQueryParams = () => async (dispatch, getState) => {
  // Create new object from browser URL.
  const params = new URLSearchParams(window.location.search);

  // Loop through each query parameter.
  params.forEach((value, key) => {
    switch (key) {
      // Handle geo/country.
      case 'geo':
        // If we have a matching geo in facets, we're good.
        if (_.find(getState().facets.location.geos, (o) => o.id === value)) {
          // Set the filter.
          dispatch({
            type: ADD_SINGLE_FILTER,
            payload: {
              name: 'location.geo',
              value,
            },
          });
          // Grab countries for that specified geo.
          dispatch(getCountriesInGeo(value));
        } else {
          notify(
            {
              type: 'warning',
              textPath: 'partner_finder.errors.unrecognized_filter_param',
              autoDismissable: true,
            },
          );
        }
        break;
      case 'country':
        // We may or may not have the country yet, but let's set it anyway if
        // it's a 2 character country code. When we get the countries list,
        // it'll be selected.
        if (value.match(/^[a-zA-Z]{2}$/)) {
          dispatch({
            type: ADD_SINGLE_FILTER,
            payload: {
              name: 'country',
              value,
            },
          });
        } else {
          notify(
            {
              type: 'warning',
              textPath: 'partner_finder.errors.unrecognized_filter_param',
              autoDismissable: true,
            },
          );
        }
        break;
        /* commenting this code to remove company-size filter from view -
        JIRA Ref:   ESSSAL-113018 */
      /* case 'company-size':
        if (_.find(getState().facets['company-size'].options, (o) => o.id === value)) {
          // Set the filter.
          dispatch({
            type: ADD_SINGLE_FILTER,
            payload: {
              name: 'company-size',
              value,
            },
          });
        } else {
          notify(
            {
              type: 'warning',
              textPath: 'partner_finder.errors.unrecognized_filter_param',
              autoDismissable: true,
            },
          );
        }
        break; */
      case 'solution':
      case 'industry':
      case 'level':
        // If we have a matching facet:
        if (_.has(getState().facets, key)) {
          // If we have multiple values.
          if (params.get(key).includes(',')) {
            // Split them into an array.
            const values = params.get(key).split(',');
            const validOptions = [];
            // Make sure we have these options before we select them.
            // Only valid options will be selected.
            values.forEach((filterValue) => {
              if (_.find(getState().facets[key].options, (o) => o.id === filterValue)) {
                if (!validOptions.includes(filterValue)) {
                  validOptions.push(filterValue);
                }
              }
            });
            if (values.length !== validOptions.length) {
              notify(
                {
                  type: 'warning',
                  textPath: 'partner_finder.errors.unrecognized_filter_param',
                  autoDismissable: true,
                },
              );
            }
            // Add the verified options to "selected" state.
            dispatch({
              type: ADD_SINGLE_FILTER,
              payload: {
                name: key,
                value: validOptions,
              },
            });
          } else if (_.find(getState().facets[key].options, (o) => o.id === value)) {
            dispatch({
              type: ADD_SINGLE_FILTER,
              payload: {
                name: key,
                value: new Array(value),
              },
            });
          } else {
            notify(
              {
                type: 'warning',
                textPath: 'partner_finder.errors.unrecognized_filter_param',
                autoDismissable: true,
              },
            );
          }
        }
        break;
      case 'accredited-solutions':
        if (value === 'true') {
          dispatch({
            type: ADD_SINGLE_FILTER,
            payload: {
              name: 'accredited-solutions',
              value: true,
            },
          });
        }
        break;
      case 'specialized':
        if (value === 'true') {
          dispatch({
            type: ADD_SINGLE_FILTER,
            payload: {
              name: 'specialized',
              value: true,
            },
          });
        }
        break;
      case 'physical-location':
        if (value === 'true') {
          dispatch({
            type: ADD_SINGLE_FILTER,
            payload: {
              name: 'physical-location',
              value: true,
            },
          });
        }
        break;
      default:
        // Don't touch it.
        break;
    }
  });
};

/**
 * Expand Filters
 * Expands the filters to view all available filters.
 */
export const expandFilters = () => async (dispatch) => {
  dispatch({
    type: EXPAND_FILTERS,
  });
};

/**
 * Contract Filters
 * Contracts the filters to the first 3.
 */
export const contractFilters = () => async (dispatch) => {
  dispatch({
    type: CONTRACT_FILTERS,
  });
};

/**
 * Clear All Filters
 * Clears all set filters.
 */
export const clearAllFilters = (router) => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_FILTERS,
  });
  dispatch(updateQueryParamsFromFilterState(router));
  dispatch(getResultsWithSetFilters());
};
