// React Imports
import React from 'react';

// Routing
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';

// Local project components
import Header from './global/Header';
import Footer from './global/Footer';

// Animated Routes
import AnimatedRoutes from './AnimatedRoutes';

// Styles
import './css/App.scss';

function App() {
  return (
    <div className="react-spectrum-provider spectrum spectrum--light spectrum--medium">
      <BrowserRouter basename={window.directoryConfig.app.basepath}>
        <Routes>
          {/* Render header component for every route. */}
          <Route path="*" element={<Header />} />
        </Routes>
        {/* Animated routes (fade in/out for detail and listing page). */}
        <AnimatedRoutes />
        <Routes>
          {/* Render footer component for every route. */}
          <Route path="*" element={<Footer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
