import * as React from 'react';

function SvgPartnerLevelGold() {
  return (
    <svg viewBox="0 0 60 60">
      <linearGradient
        id="PartnerLevel_gold_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.782}
        y1={0.76}
        x2={30.179}
        y2={53.966}
      >
        <stop offset={0} stopColor="#c19b3d" />
        <stop offset={1} stopColor="#ad8325" />
      </linearGradient>
      <path
        d="M30 0C13.4 0 0 13.4 0 30s13.4 30 30 30 30-13.4 30-30S46.6 0 30 0zM18.7 20.9c4.5 0 8.2 3.7 8.2 8.2 0 4.5-3.7 8.2-8.2 8.2-4.5 0-8.2-3.7-8.2-8.2-.1-4.6 3.6-8.2 8.2-8.2zm17 36.8l-.9.6c-1.3.2-2.7.3-4.1.3-10.9 0-20.3-6-25.3-14.8 2.3-2.3 5.5-3.8 9.1-3.8h8.4c7.1 0 12.9 5.8 12.9 12.9v4.8zM50.5 29c0 .2-.1.2-.2.2h-6v6.3c0 .2-.1.2-.2.2h-2.4c-.2 0-.2-.1-.2-.2v-6.3h-6c-.2 0-.2-.1-.2-.2v-2.3c0-.1.1-.2.2-.2h6v-6.3c0-.1.1-.2.2-.2h2.4c.1 0 .2.1.2.2v6.2h6c.2 0 .2.1.2.2V29z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#PartnerLevel_gold_svg__a)"
      />
    </svg>
  );
}

export default SvgPartnerLevelGold;
