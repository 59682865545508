import { reset } from 'redux-form';

// Constants
import { SUBMIT_FORM } from '../constants';

// API
import api from '../api/directoryApi';

// Error Notification to the user
import notify from '../util/displayToastNotification';

export const submitForm = (values, id) => async (dispatch, getState) => {
  const contactObject = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    country: values.country,
    company: values.company,
    jobRole: values.jobRole,
    solution: values.solution,
    message: values.message,
    'g-recaptcha-token': values.captcharesponse,
  };

  dispatch({ type: SUBMIT_FORM, payload: contactObject });

  try {
    const result = await api.post(
      `/v1/spp/partnerlead/${id}`,
      contactObject,
      {
        headers:
        {
          'x-api-key': window.directoryConfig.adobeIO.apiKey,
        },
      },
    );

    if (result.status === 200) {
      dispatch(reset('contactForm'));
      notify({
        type: 'success-formSubmit',
        textPath: 'partner_finder.contactForm.submit_success',
        partnerName: getState().partnerDetail.companyInfo.name,
        autoDismissable: true,
      });
    }
  } catch (err) {
    // Notify the user
    notify({
      type: 'error',
      textPath: 'partner_finder.errors.submit_contact_partner_form_api_failed',
      autoDismissable: true,
    });
    // Log to the console.
    window.console.error('Error: Problem submitting contact form data, API call failed.', err);
    // Tell New Relic about it
    if (window.newrelic) {
      window.newrelic.noticeError(
        err.message,
        {
          page: 'Detail Page',
          section: 'Contact Form',
          api: `/v1/spp/partnerlead/${id}`,
        },
      );
    }
  }
};

export default submitForm;
