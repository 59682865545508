// I18n
import { setLocale } from 'react-redux-i18n';
import { SET_APP_INITIALIZED } from '../constants';
import { supportedLocales, fallbackLocale } from '../i18n/config';

// Other Actions
import { getAllFacetData } from './facetsActions';
import { rehydrateFilterStateFromQueryParams, updateQueryParamsFromFilterState, getResultsWithSetFilters } from './filterActions';

export const setLocaleWithFallback = (locale) => async (dispatch) => {
  const finalLocale = Object.values(supportedLocales).includes(locale)
    ? locale
    : fallbackLocale;
    // Store the preferred locale in session storage.
  localStorage.setItem('i18n_lang', finalLocale);

  dispatch(setLocale(finalLocale));
};

// Initialize Filters
export const initializeFilters = (router) => async (dispatch) => {
  await dispatch(getAllFacetData());
  await dispatch(rehydrateFilterStateFromQueryParams());
  await dispatch(updateQueryParamsFromFilterState(router));
  await dispatch(getResultsWithSetFilters());
  dispatch({
    type: SET_APP_INITIALIZED,
  });
};
