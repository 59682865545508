// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// React Router

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// React Spectrum Components
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Close from '@spectrum-icons/workflow/Close';
import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Switch,
} from '@adobe/react-spectrum';

import { withRouter } from './withRouter';

// Redux Actions
import { addSingleFilter, removeSingleFilter } from '../actions/filterActions';

// Analytics
import Analytics from './Analytics';

// Styles
import './css/FilterCommon.scss';
import './css/SpecializedToggleFilter.scss';

class SpecializedToggleFilter extends Component {
  // Mobile global var.
  isMobile = false;

  constructor(props) {
    super(props);
    // Detect mobile on init.
    this.detectMobile();
  }

  /**
   * Clear Filter
   * Clears a filter from the redux store and refreshes the component.
   */
  clearFilter = () => {
    const { removeSingleFilterAction, router } = this.props;

    removeSingleFilterAction({
      name: 'specialized',
    }, router);
  };

  /**
   * Handle specialized  Toggle Switch
   * Handles the specialized  toggle switch that turns on/off of specialized  filter.
   * @param {Event} e - value of the toggle event.
   */
  handleSpecializedToggler = (e) => {
    const {
      addSingleFilterAction, removeSingleFilterAction, router, appliedFilters,
    } = this.props;

    if (e) {
      addSingleFilterAction({
        name: 'specialized',
        value: true,
      }, router);

      // Capture analytics event.
      Analytics.setEvent('FilterApplied', 'filterInteraction');

      const analyticsKeys = [
        { path: 'search.primaryFilter.filterInfo', value: { keyword: 'Applied', category: 'specialized' } },
        { path: 'search.filter', value: Analytics.getSetFiltersFromState(appliedFilters) },
      ];

      Analytics.setMultipleProps(analyticsKeys);
      Analytics.track('event');
      Analytics.clearMultipleProps(analyticsKeys);
      Analytics.clearEvent();
    } else {
      removeSingleFilterAction({
        name: 'specialized',
      }, router);
    }
  };

  /**
   * Detect Mobile
   * Detects the viewport and sets the global isMobile variable.
   */
  detectMobile() {
    // Detect if we are on a mobile device.
    const mql = window.matchMedia('(max-width: 600px)');
    this.isMobile = mql.matches;
  }

  /**
   * Render specialized  Filter
   * Renders the specialized  filter component
   */
  renderSpecializedToggleFilter() {
    const { appliedFilters } = this.props;
    const selectedClass = appliedFilters.specialized ? 'selected' : '';
    const selected = _.has(appliedFilters, 'specialized');

    // JSX for the Dropdown button
    const dropdownButton = (
      <ActionButton UNSAFE_className={`filterButton ${selectedClass}`} data-test-id="specialized-toggle-filter-btn">
        <Translate value="partner_finder.filters.specialized_label" />
        <div className="caret"><ChevronDown size="XS" /></div>
      </ActionButton>
    );

    // JSX for the "X" clear filter button.
    const clearFilterButton = (
      <ActionButton data-test-id="specialized-filter-clear" UNSAFE_className={`clearFilterButton ${selectedClass}`} onPress={() => this.clearFilter()}>
        <div className="separator" />
        <div className="smallCaret"><ChevronDown size="XS" /></div>
        <div className="clearFilter"><Close size="XS" /></div>
      </ActionButton>
    );

    // If we are on Desktop view
    if (!this.isMobile) {
      return (
        <>
          <DialogTrigger
            hideArrow
            type="popover"
            placement="bottom left"
            data-test-id="specialized-toggle-filter-menuTrigger"
          >
            {dropdownButton}
            <Dialog UNSAFE_className="specialized-dialog">
              <div className="specialized-top-section">
                <Heading UNSAFE_className="specialized-dropdown-title">
                  <Translate value="partner_finder.filters.specialized_header" />
                </Heading>
                <div className="specialized-switch-section">
                  <Switch
                    aria-label={I18n.t('partner_finder.filters.specialized_label')}
                    isEmphasized
                    UNSAFE_className="specialized-toggle-switch"
                    isSelected={selected}
                    onChange={(e) => this.handleSpecializedToggler(e)}
                  />
                </div>
              </div>
              <Content UNSAFE_className="specialized-dropdown-content">
                <Translate value="partner_finder.filters.specialized_description" />
                {' '}
                <a href={window.directoryConfig.specializationLink} target="_blank" rel="noreferrer">
                  <Translate value="partner_finder.filters.specialized_description_learn_more" />
                </a>

              </Content>
            </Dialog>
          </DialogTrigger>
          {clearFilterButton}
        </>
      );
    }
    // Hidden on mobile view
    return null;
  }

  // Render the component
  render() {
    return (
      <div className="cmp-specializedToggleFilter" id="specialized-filter" style={{ whiteSpace: 'nowrap' }}>
        {this.renderSpecializedToggleFilter()}
      </div>
    );
  }
}

SpecializedToggleFilter.propTypes = {
  /**
   * addSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that adds a filter to the redux store.
   */
  addSingleFilterAction: PropTypes.func.isRequired,
  /**
   * removeSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that removes a filter from the redux store.
   */
  removeSingleFilterAction: PropTypes.func.isRequired,
  /**
   * appliedFilters {Object} - An object provided by mapStateToProps
   * that contains the selected filter data.
   */
  appliedFilters: PropTypes.shape({
    specialized: PropTypes.bool,
  }),
  /**
   * router {Object} - An object provided by React Router that allows navigation.
   */
  router: PropTypes.shape({}).isRequired,
};

SpecializedToggleFilter.defaultProps = {
  appliedFilters: {},
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  appliedFilters: state.filter.selected,
});

const mapDispatchToProps = {
  addSingleFilterAction: addSingleFilter,
  removeSingleFilterAction: removeSingleFilter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecializedToggleFilter));
