// React
import React from 'react';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// React Spectrum
import {
  Grid,
  View,
  Flex,
} from '@adobe/react-spectrum';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// Framer Motion
import { motion } from 'framer-motion';
import { DetailTabAnimation } from '../../../util/FramerMotionConfig';

// Content Card
import ContentCard from '../../ContentCard';

// Styles
import '../../css/TabCommon.scss';

const ResourcesTab = ({ resources }) => {
  /**
   * renderVideosSection
   * Renders the videos section.
   */
  const renderVideosSection = () => {
    // If we have a video object
    if (_.has(resources, 'Video')) {
      // Create cards for each video.
      const cards = resources.Video.map((o) => (
        <ContentCard
          key={`video-${o.name}`}
          linkUrl={o.link}
          linkIcon="link"
          linkText={I18n.t('partner_finder.detail.resources_tab.view_video')}
          thumbnailImageUrl={o.link}
          header={o.name}
          description={o.description}
          cardWidth="2"
        />
      ));

      // Find how many extra spots we have in the row.
      const cardRemainder = _.size(resources.Video) % 2;

      // Add spacer divs.
      if (cardRemainder) {
        // For every space we have, add a spacer div.
        cards.push(
          <div key="cmp-spacer" className="cmp-video-card-spacer" />,
        );
      }

      // Return header/description and cards.
      return (
        <div className="video-section resource-section">
          <h2><Translate value="partner_finder.detail.resources_tab.videos" /></h2>
          <Flex gap="35px" wrap>
            {cards}
          </Flex>
        </div>
      );
    }
    return null;
  };

  // Renders the case studies section.
  const renderCaseStudiesSection = () => {
    if (_.has(resources, 'Success story')) {
      const cards = resources['Success story'].map((o) => (
        <ContentCard
          key={`successStudy-${o.name}`}
          linkUrl={o.link}
          linkIcon="link"
          linkText={I18n.t('partner_finder.detail.resources_tab.case_study_link')}
          header={o.name}
          description={o.description}
          cardWidth="2"
        />
      ));

      return (
        <div className="case-study-section resource-section">
          <h2><Translate value="partner_finder.detail.resources_tab.case_studies" /></h2>
          <Flex gap="35px" wrap>
            {cards}
          </Flex>
        </div>
      );
    }
    return null;
  };

  // Renders the whitepapers section.
  const renderWhitePapersSection = () => {
    if (_.has(resources, 'White paper')) {
      const cards = resources['White paper'].map((o) => (
        <ContentCard
          key={`whitepaper-${o.name}`}
          linkUrl={o.link}
          linkIcon="link"
          linkText={I18n.t('partner_finder.detail.resources_tab.white_paper_download')}
          header={o.name}
          description={o.description}
          cardWidth="2"
        />
      ));

      return (
        <div className="white-paper-section resource-section">
          <h2><Translate value="partner_finder.detail.resources_tab.white_papers" /></h2>
          <Flex gap="35px" wrap>
            {cards}
          </Flex>
        </div>
      );
    }
    return null;
  };

  // Renders the datasheets section.
  const renderDataSheetsSection = () => {
    if (_.has(resources, 'Data sheet')) {
      const cards = resources['Data sheet'].map((o) => (
        <ContentCard
          key={`datasheet-${o.name}`}
          linkUrl={o.link}
          linkIcon="link"
          linkText={I18n.t('partner_finder.detail.resources_tab.data_sheet_view')}
          header={o.name}
          description={o.description}
          cardWidth="2"
        />
      ));

      return (
        <div className="data-sheets-section resource-section">
          <h2><Translate value="partner_finder.detail.resources_tab.data_sheets" /></h2>
          <Flex gap="35px" wrap>
            {cards}
          </Flex>
        </div>
      );
    }
    return null;
  };

  return (
    <motion.div
      initial={DetailTabAnimation.initial}
      animate={DetailTabAnimation.animate}
      exit={DetailTabAnimation.exit}
      transition={DetailTabAnimation.transition}
    >
      <Grid UNSAFE_className="tab-content-container">
        <View gridArea="tab-content" UNSAFE_className="tab-content">
          {renderVideosSection()}
          {renderCaseStudiesSection()}
          {renderWhitePapersSection()}
          {renderDataSheetsSection()}
        </View>
      </Grid>

    </motion.div>
  );
};
ResourcesTab.propTypes = {
  resources: PropTypes.shape({
    'Success story': PropTypes.arrayOf(PropTypes.object),
    Video: PropTypes.arrayOf(PropTypes.object),
    'White paper': PropTypes.arrayOf(PropTypes.object),
    'Data sheet': PropTypes.arrayOf(PropTypes.object),
  }),
};
ResourcesTab.defaultProps = {
  resources: {},
};

export default ResourcesTab;
