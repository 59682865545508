// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Framer Motion
import { AnimatePresence, motion } from 'framer-motion';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// Spectrum Components
import Autocomplete from '@react/react-spectrum/Autocomplete';
import SearchField from '@react/react-spectrum/Search';
import Dialog from '@react/react-spectrum/Dialog';
import { ActionButton } from '@adobe/react-spectrum';
import CrossSmall from '@spectrum-icons/ui/CrossSmall';

// Search Actions
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import fetchSuggestions from '../actions/searchActions';

// React Router
import { withRouter } from './withRouter';

// Utils
import { addCommaSeparator } from '../util/util';

// CSS
import './css/Search.scss';

/**
 * fetchSuggestionsDebounced
 * Makes use of the AwesomeDebouncePromise plugin, that waits
 * 800 milliseconds after the last invocation of a function to
 * actually invoke it to prevent excess API calls to the server.
 */
const fetchSuggestionsDebounced = AwesomeDebouncePromise(fetchSuggestions, 800, { leading: true });

class Search extends Component {
  // Mobile global var.
  isMobile = false;

  // eslint-disable-next-line react/sort-comp
  handleMutations = (mutationsList) => {
    const { fullScreenSearchVisible } = this.state;

    // Adds the full screen search class to the popover.
    const addClassToNode = (node) => {
      setTimeout(() => {
        node.classList.add('fs-autocomplete-popover');
      }, 10);
    };

    // If we are in the full screen search experience
    if (fullScreenSearchVisible) {
      // Loop through our mutations
      mutationsList.forEach((mutation) => {
        // Find any popovers, and add classes to them.
        if (mutation.addedNodes.length) {
          mutation.addedNodes.forEach((node) => {
            if (node.classList && node.classList.contains('spectrum-Popover') && !node.classList.contains('fs-autocomplete-popover')) {
              addClassToNode(node);
            }
          });
        }
      });
    }
  };

  // Create an observer instance linked to the callback function
  observer = new MutationObserver(this.handleMutations);

  // fetch the results for Autocomplete
  fetchAutoCompleteData = async (text) => {
    const result = await fetchSuggestionsDebounced(text);
    this.setState({
      noResultsFlag: !(result.length > 0),
      searchResults: result,
    });
    return result;
  };

  constructor(props) {
    super(props);
    // Detect mobile on init.
    this.detectMobile();

    // Initial State, keeps track of the search term and if
    // the full screen search is visible on mobile.
    this.state = {
      searchTerm: '',
      fullScreenSearchVisible: false,
      noResultsFlag: false,
      searchResults: [],
    };
  }

  componentDidMount() {
    // Start observing the target node for configured mutations
    this.observer.observe(document.body, { childList: true, subtree: true });
    // add a event listener to handle outside click of an element
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Stop observing.
    this.observer.disconnect();
    // remove the event listener for outside click of an element added while did mount
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // check if click is outside the search results element to hide it
  handleClickOutside = (event) => {
    // grab the element
    const noResultEle = document.getElementById('resultEle');
    // check if the element is present and user has clicked outside that element to hide it
    if (noResultEle && !noResultEle.contains(event.target)) {
      this.setState({
        noResultsFlag: false,
        searchResults: [],
      });
    }
  };

  // Renders an autocomplete item in the dropdown.
  renderAutocompleteItem = (item) => {
    // The user-entered search term.
    const { searchTerm, fullScreenSearchVisible } = this.state;

    // Escape Regex function
    const escapeRegex = (s) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // If we have a user-entered search term that is not whitespace, and matches the current item.
    if (searchTerm && searchTerm.match(/^ *$/) === null && item.name.match(new RegExp(escapeRegex(searchTerm), 'i')) !== null) {
      // Wrap the matching text with <strong> tags.
      const matchBolded = item.name.replace(new RegExp(`(${escapeRegex(searchTerm)})`, 'gi'), '<strong>$1</strong>');
      // Because we are creating a HTML string, we have to use the unsafe method,
      // but no user-supplied data is being written to the screen, only matching
      // text from the server is being bolded.
      const htmlObject = { __html: matchBolded };
      // Return
      return (
        <div
          key={item.name}
          className={!fullScreenSearchVisible && 'autocomplete-itemRow'}
          onClick={() => this.handleSelectItem(item)}
          onKeyDown={() => this.handleSelectItem(item)}
          role="button"
          tabIndex="0"
        >
          {/* eslint-disable-next-line */ }
            <span className="autocomplete-company-name" dangerouslySetInnerHTML={htmlObject} />
        </div>
      );
    }
    // Otherwise just return the company name with no styling.
    return (
      <div
        key={item.name}
        data-testing-id="serach-term"
        className={!fullScreenSearchVisible && 'autocomplete-itemRow'}
        onClick={() => this.handleSelectItem(item)}
        onKeyDown={() => this.handleSelectItem(item)}
        role="button"
        tabIndex="0"
      >
        <span className="autocomplete-company-name">{item.name}</span>
      </div>
    );
  };

  /** getSearchTerm
     * Returns the search term from local state.
     */
  getSearchTerm = () => {
    const { searchTerm } = this.state;
    return searchTerm;
  };

  /** setSearchTerm
     * Sets the local state with the search term and
     * also sets the no results flag to false if search is empty
     * once searched call the api with the searched term
     */
  setSearchTerm = async (term) => {
    this.setState({
      searchTerm: term,
      noResultsFlag: !term && false,
    });
    // call the api with the searched term
    this.fetchAutoCompleteData(term);
  };

  /** getTotalResults
     * Returns the total number of results from Redux Store.
     */
  getTotalResults = () => {
    const { totalResults } = this.props;
    return totalResults;
  };

  /** handleSelectItem
     * Navigates to the partner's detail page.
     */
  handleSelectItem = (item) => {
    const { router } = this.props;
    router.navigate(`/detail/${item.url}`);
  };

  /**
     * Detect Mobile
     * Detects the viewport and sets the global isMobile variable.
     */
  detectMobile() {
    // Detect if we are on a mobile device.
    const mql = window.matchMedia('(max-width: 600px)');
    this.isMobile = mql.matches;
  }

  /** renderSearchBox
     * Renders the component.
     */
  renderSearchBox = () => {
    /** fullScreenSearchDialog
       * Sets the active state for the full screen search dialog.
       */
    const fullScreenSearchDialog = () => {
      // Set the state of full screen dialog shown
      this.setState({
        fullScreenSearchVisible: true,
      });
    };

    /** hideFullScreenSearchDialog
       * Hides the full screen search dialog and clears the search term.
       */
    const hideFullScreenSearchDialog = () => {
      // Set the state of full screen dialog shown
      this.setState({
        fullScreenSearchVisible: false,
        searchTerm: null,
      });
    };

    /** renderFullScreenSearchDialog
       * Returns a full-screen search dialog if
       * state is set to true.
       */
    const renderFullScreenSearchDialog = () => {
      const { fullScreenSearchVisible, noResultsFlag, searchTerm } = this.state;
      // If state is true
      if (fullScreenSearchVisible) {
        return (
          <Dialog
            isDismissible
            mode="fullscreenTakeover"
            open
            role="dialog"
            trapFocus
            className="full-screen-search-overlay"
          >
            <Autocomplete
              renderItem={(item) => this.renderAutocompleteItem(item)}
              getCompletions={this.fetchAutoCompleteData}
              value={this.getSearchTerm()}
              onSelect={(item) => this.handleSelectItem(item)}
            >
              <SearchField
                autoFocus
                placeholder={
                    I18n.t('partner_finder.search.search_number_by_name', { results: addCommaSeparator(this.getTotalResults()) })
                  }
                onChange={this.setSearchTerm}
                className="full-screen-dialog-searchbox"
              />
              <ActionButton
                aria-label="Icon only"
                UNSAFE_className="clear-mobile-search"
                onPress={hideFullScreenSearchDialog}
              >
                <CrossSmall />
              </ActionButton>
            </Autocomplete>
            {searchTerm && searchTerm.length > 1 && noResultsFlag && (
            <div className="mobile-noresults">
              <Translate value="partner_finder.search.no_results" />
              <br />
              <Translate value="partner_finder.search.please_try_another_word" />
            </div>
            )}
          </Dialog>
        );
      }
      return null;
    };

    // If we are on mobile, return the full screen search dialog and a readOnly
    // search box that opens the full screen search.
    if (this.isMobile) {
      return (
        <>
          <SearchField
            data-test-id="mobile-search-field"
            placeholder={
                  I18n.t('partner_finder.search.search_number_by_name', { results: addCommaSeparator(this.getTotalResults()) })
                }
            readOnly
            onClick={fullScreenSearchDialog}
          />
          {renderFullScreenSearchDialog()}
        </>
      );
    }
    const { noResultsFlag, searchTerm, searchResults } = this.state;
    // Otherwise, return the normal autocomplete search experience.
    return (
      <>
        <SearchField
          placeholder={
                    I18n.t('partner_finder.search.search_for_partners')
                  }
          onChange={this.setSearchTerm}
          value={searchTerm}
        />
        <AnimatePresence>
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
          >
            {searchResults.length > 0 && (
            <div className="result-wrapper" id="resultEle">
              {searchResults.map((item) => (
                this.renderAutocompleteItem(item)
              ))}
            </div>
            )}
            {searchTerm && searchTerm.length > 1 && noResultsFlag && (
            <div className="result-wrapper noResults" id="resultEle">
              <Translate value="partner_finder.search.no_results" />
              <br />
              <Translate value="partner_finder.search.please_try_another_word" />
            </div>
            )}
          </motion.div>
        </AnimatePresence>
      </>
    );
  };

  // Render the component
  render() {
    return (
      <div className="cmp-search">
        {this.renderSearchBox()}
      </div>
    );
  }
}
Search.propTypes = {
  /**
   * totalResults {Number} The number of total matching results in the redux store.
   */
  totalResults: PropTypes.number.isRequired,
  /**
   * router {Object} The router object provided by React Router. Used to navigate to other pages.
   */
  router: PropTypes.shape({
    navigate: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  totalResults: state.results.totalResults,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
