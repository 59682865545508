/*
 *  Mapping of IMS locale codes to supported app language.
 *  See https://git.corp.adobe.com/intl/adobe-locales under "IMS" for all
 *  supported IMS locales.
*/
export const supportedLocales = {
  en: 'en_US',
};

/* Friendly language mapping.
  * Used on the language selector component.
  * When adding a new language, make sure to include it here.
*/
export const friendlyLanguageName = {
  en_US: 'US - English',
};

export const fallbackLocale = 'en_US';
