// React
import React from 'react';
import PropTypes from 'prop-types';

// React Spectrum components
import {
  Divider,
} from '@adobe/react-spectrum';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

// I18n
import { Translate, I18n } from 'react-redux-i18n';

// Utils
import { normalizeUrl } from '../util/util';

// Styles
import './css/SolutionBrief.scss';
import { CONTENT_SUPPLY_CHAIN } from '../constants';

/**
 * renderLink
 * Returns a link and icon.
 */
const renderLink = (linkUrl, linkText, linkDescription, linkType) => {
  if (linkType !== 'solution-brief') {
    return (
      <div className="resource-link-button" key={linkText}>
        <LinkOut size="S" />
        <a href={normalizeUrl(linkUrl)} title={linkDescription} target="_blank" rel="noreferrer">
          {linkText}
        </a>
      </div>
    );
  }

  return null;
};

/**
 * renderText
 * Returns a modified string for solutions/industries.
 */
const renderText = (stringArray) => {
  switch (stringArray.length) {
    case 1:
      return stringArray[0];
    case 2:
      return stringArray.join(` ${I18n.t('partner_finder.detail.accredited_solutions_tab.modified_string_and')} `);
    default:
      return `${stringArray.slice(0, -1).join(', ')}, ${I18n.t('partner_finder.detail.accredited_solutions_tab.modified_string_and')} ${stringArray.slice(-1)}`;
  }
};

const SolutionBrief = ({ accreditedSolution }) => (
  <div className="solution-brief-container">
    <div className="data-badge" style={accreditedSolution.status === CONTENT_SUPPLY_CHAIN ? { backgroundColor: '#5C5CE0', color: 'white', fontWeight: 'normal' } : {}}>
      {accreditedSolution.status}
    </div>
    <div className="solution-brief-text">
      <div className="solution-brief-title">
        {accreditedSolution.headline}
      </div>
      <div className="solution-brief-description">
        {accreditedSolution.description}
      </div>
    </div>
    { accreditedSolution.industries?.length > 0
      ? (
        <div className="solution-brief-industry">
          <div className="solution-brief-industry-title">
            <Translate value="partner_finder.detail.accredited_solutions_tab.solution_brief_industries_header" />
          </div>
          <div className="industries">
            {renderText(accreditedSolution.industries)}
          </div>
        </div>
      )
      : null }
    { accreditedSolution.solutions?.length > 0
      ? (
        <div className="soution-brief-solutions">
          <div className="solution-brief-solutions-title">
            <Translate value="partner_finder.detail.accredited_solutions_tab.solution_brief_solutions_header" />
          </div>
          <div className="solutions">
            {renderText(accreditedSolution.solutions)}
          </div>
        </div>
      )
      : null}
    { accreditedSolution.solutionWebsite
       && (
       <div>
         <div className="solution-website">
           <div className="solution-website-title">
             <Translate value="partner_finder.detail.accredited_solutions_tab.solution_website" />
           </div>
           <div className="solution-website-link">
             <a href={normalizeUrl(accreditedSolution.solutionWebsite)} target="_blank" rel="noreferrer">
               {accreditedSolution.solutionWebsite}
             </a>
           </div>
         </div>
       </div>
       ) }
    { accreditedSolution.resources?.length > 0
      ? (
        <div>
          <Divider size="S" />
          <div className="related-resources">
            <div className="related-resources-title">
              <Translate value="partner_finder.detail.accredited_solutions_tab.related_resources" />
            </div>
            <div className="related-resources-link">
              {accreditedSolution.resources.map((resource) => (
                renderLink(resource.link, resource.name, resource.description, resource.type)
              ))}
            </div>
          </div>
        </div>
      ) : null}

  </div>
);

SolutionBrief.propTypes = {
  /**
   * accreditedSolution {Object} - An object provided by AccreditedSolutionsTab
   * that contains info of the solutions brief that the partner provides
   */
  accreditedSolution: PropTypes.shape({
    headline: PropTypes.string,
    description: PropTypes.string,
    industries: PropTypes.arrayOf(PropTypes.string),
    solutions: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    solutionWebsite: PropTypes.string,
    resources: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.string,
    })),
  }),
};

SolutionBrief.defaultProps = {
  accreditedSolution: {
    solutions: [],
    industries: [],
    resources: [],
  },
};

export default SolutionBrief;
