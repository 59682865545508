// Lodash
import _ from 'lodash';

// API
import api from '../api/directoryApi';

// Notification
import notify from '../util/displayToastNotification';

export default (query) => new Promise((resolve) => {
  if (query && query.match(/^ *$/) === null && query.length > 1) {
    // Make the API call
    api.get(
      `/v1/spp/listings/search?search=${encodeURIComponent(query)}`,
      {
        headers: {
          'x-api-key': window.directoryConfig.adobeIO.apiKey,
        },
      },
    ).then((result) => {
      // If we have data.
      if (result.data.listings) {
        if (!result.data.listings.length) {
          resolve([]);
        } else {
          // Resolve
          resolve(_.slice(result.data.listings, 0, 10));
        }
      } else {
        // Throw an error.
        window.console.error('Error: Problem with fetching autocomplete suggestions, no suggestion payload.', result);
        resolve([]);
      }
    }).catch((error) => {
      // API call failed, throw an error.
      notify(
        {
          type: 'error',
          textPath: 'partner_finder.errors.get_results_api_failed',
          autoDismissable: true,
        },
      );
      window.console.error('Error: Problem with fetching autocomplete suggestions, call failed.', error);
      resolve([]);
    });
  } else {
    resolve([]);
  }
});
