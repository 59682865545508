// React Imports
import React from 'react';

// Styles
import '../css/Footer.scss';

/**
 * Component for displaying the global footer.
 *
 * This component displays the global footer. It requires the language selector component
 * and the Jarvis chat component, and includes AdChoices integration.
 *
 * @component
 * @example
 *
 * return (
 *   <Footer />
 * )
 */

const Footer = () => (
  <div id="feds-footer" />
);

export default Footer;
