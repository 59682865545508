/* eslint-disable react/destructuring-assignment */
// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import Lodash
import _ from 'lodash';

// React Spectrum components
import {
  Grid,
  View,
  ProgressCircle,
  Heading,
  Text,
  ButtonGroup,
} from '@adobe/react-spectrum';

// V2 Components
import { TabView, Tab } from '@react/react-spectrum/TabView';

// i18n
import { I18n } from 'react-redux-i18n';

// SEO
import { Helmet } from 'react-helmet';

// Framer Motion
import { AnimatePresence, motion } from 'framer-motion';
import { PageVariants, PageTransitions, LoadingAnimation } from '../../util/FramerMotionConfig';

// withRouter
import { withRouter } from '../withRouter';

// Local project components
import ShareProfileButton from '../ShareProfileButton';
import HeroBanner from '../HeroBanner';
import ReturnToResultsButton from '../ReturnToResultsButton';
import ContactPartnerButton from '../ContactPartnerButton';
import ContactPartnerForm from '../ContactPartnerForm';
import OpenURLButton from '../OpenURLButton';
import ViewPhoneNumberButton from '../ViewPhoneNumberButton';
import PartnerInfoCard from '../PartnerInfoCard';
import ContactBlade from '../ContactBlade';
import DetailNotFound from '../DetailNotFound';

// Tabs
import CompanyInfoTab from './tabs/CompanyInfoTab';
import ExpertiseTab from './tabs/ExpertiseTab';
import AccreditedSolutionsTab from './tabs/AccreditedSolutionsTab';
import ServicesIndustriesTab from './tabs/ServicesIndustriesTab';
import LocationsTab from './tabs/LocationsTab';
import ResourcesTab from './tabs/ResourcesTab';

// Redux Actions
import { getPartnerDetail } from '../../actions/partnerDetailAction';
import { getAllFacetData } from '../../actions/facetsActions';
import { submitForm } from '../../actions/formActions';

// Styles
import '../css/DetailPage.scss';

class DetailPage extends Component {
  // Tracking the expertise tab so we can click it.
  expertiseTabIndex = 0;

  // // Track the default tab in the event of a direct link.
  // defaultTab = '';

  // Tabs included on this page.
  visibleTabs = [];

  constructor(props) {
    super(props);
    const {
      getPartnerDetailAction,
      getAllFacetDataAction,
      partnerDetail,
      router,
      solution,
    } = this.props;

    this.state = { defaultTab: 'companyInfo', selectedTabIndex: null };

    if (!_.has(partnerDetail, 'companyInfo.listingUrl') || decodeURI(partnerDetail.companyInfo.listingUrl) !== router?.params?.id) {
      getPartnerDetailAction(router?.params?.id);
    }
    if (solution?.options?.length === 0) {
      getAllFacetDataAction();
    }

    // Check for default tab.

    // Allowed tab hashes in the URL.
    const tabHashes = ['companyInfo', 'expertise', 'accreditedSolutions', 'servicesAndIndustries', 'locations', 'resources'];
    const { hash } = window.location;

    // If we have a hash, and it's one of the allowed tabs, set the default tab.
    if (hash && tabHashes.includes(hash.substring(1))) {
      this.setState({
        defaultTab: hash.substring(1),
        selectedTabIndex: tabHashes.indexOf(hash.substring(1)),
      });
    }
  }

  componentDidMount() {
    // Allowed tab hashes in the URL.
    const tabHashes = ['companyInfo', 'expertise', 'accreditedSolutions', 'servicesAndIndustries', 'locations', 'resources', 'deployments'];
    const { hash } = window.location;

    // If we have a hash, and it's one of the allowed tabs, set the default tab.
    if (hash && tabHashes.includes(hash.substring(1))) {
      this.setState({ defaultTab: hash.substring(1) });
    }
  }

  componentWillUnmount() {
    window.scroll({ top: 0 });
  }

  /**
   * switchToExpertiseTab
   * Switches to the expertise tab.
   */
  switchToExpertiseTab = () => {
    // Find the expertise tab.
    const tab = document.querySelector(`.spectrum-Tabs--horizontal .spectrum-Tabs-item:nth-child(${this.expertiseTabIndex})`);
    // Click it if it exists.
    if (tab) tab.click();

    // If we're on mobile.
    const mql = window.matchMedia('(max-width: 600px)');
    if (mql.matches && tab) {
      // Scroll down to that tab if we're on mobile.
      window.scroll({
        top: tab.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      });
    }
  };

  /**
   * detailPageLoadingSpinner
   * Renders a full screen loading spinner while the data is being fetched from the API.
   */
  detailPageLoadingSpinner = () => {
    const { partnerDetail, router } = this.props;

    if (_.has(partnerDetail, 'error') && partnerDetail.error === 404) {
      return <DetailNotFound />;
    }

    return (
      <AnimatePresence>
        {(!_.has(partnerDetail, 'companyInfo.listingId') || decodeURI(partnerDetail.companyInfo.listingUrl) !== router.params.id) && (
          <motion.div
            initial={LoadingAnimation.initial}
            animate={LoadingAnimation.animate}
            exit={LoadingAnimation.exit}
            transition={LoadingAnimation.transition}
            className="detail-loading-overlay"
          >
            <ProgressCircle
              UNSAFE_className="detail-loading-spinner"
              aria-label="detail-loading-spinner"
              zIndex={3}
              isIndeterminate
            />
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  /**
   * getPartnerData
   * Gets a requested attribute from the store.
   * @param {String} attr - The attribute you'd like to fetch from the partner data store.
   */
  getPartnerData = (attr) => {
    const { partnerDetail } = this.props;
    return (_.get(partnerDetail, attr, ''));
  };

  /**
   * comingFromFilterPage
   * Returns a boolean whether we have any results fetched,
   * and if we do, we assume the user came from the search page.
   */
  // comingFromFilterPage = () => {
  //   const { results } = this.props;
  //   return results;
  // };

  submit = (values) => {
    const { submitFormAction } = this.props;
    submitFormAction(values, this.getPartnerData('companyInfo.listingId'));
  };

  /**
   * renderAvailableTabs
   * Renders tabs based on the data available in the partnerDetail store in Redux.
   */
  renderAvailableTabs = () => {
    const { partnerDetail } = this.props;

    // Centers the selected tab on mobile.
    const centerSelectedTab = (selectedIndex) => {
      const mql = window.matchMedia('(max-width: 600px)');
      // If we are on mobile.
      if (mql.matches) {
        // Tab Container and Selected Elements
        const container = document.querySelector('.spectrum-Tabs--horizontal');
        const selectedElement = document.querySelector(`.spectrum-Tabs--horizontal .spectrum-Tabs-item:nth-child(${selectedIndex + 1})`);

        // Offset of selected item, and width of selected element.
        const selectedOffset = selectedElement ? selectedElement.offsetLeft : 0;
        const selectedElementWidth = selectedElement ? selectedElement.offsetWidth : 0;

        if (container) {
          // Center the selected tab on mobile view.
          container.scroll({
            left: selectedOffset - (container.offsetWidth / 2) + (selectedElementWidth / 2),
            behavior: 'smooth',
          });
        }
      }
    };

    // Adds a hash to the current URL.
    const addTabHashToURL = (selectedIndex) => {
      // Grab the name of the hash based on what tab they just selected.
      const tabId = this.visibleTabs[selectedIndex];

      // If we have replaceState available to us, set the hash that way.
      if (window.history.replaceState) {
        // This will not add a new history entry.
        window.history.replaceState(null, null, `#${tabId}`);
      } else {
        // Otherwise use the old way of doing it.
        window.location.hash = `#${tabId}`;
      }
    };

    // When the tab in the TabView has changed.
    const handleTabChange = (selectedIndex) => {
      this.setState({ selectedTabIndex: selectedIndex });
      // Add the hash to the URL for bookmarking purposes.
      addTabHashToURL(selectedIndex);
      // Center the selected tab on mobile.
      centerSelectedTab(selectedIndex);
    };

    // If we have a partnerDetail object.
    if (_.has(partnerDetail, 'companyInfo.listingId')) {
      // Array of tabs we'll push to.
      const tabs = [];

      // Company Info Tab
      if (_.has(partnerDetail, 'companyInfo.shortDescription')) {
        tabs.push(
          <Tab selected={this.state.defaultTab === 'companyInfo'} key="companyInfoTab" label={I18n.t('partner_finder.detail.company_info')}>
            <CompanyInfoTab
              shortDescription={partnerDetail.companyInfo.shortDescription}
              globalParentId={partnerDetail.companyInfo.globalParentId}
              longDescription={partnerDetail.companyInfo.longDescription}
              companyName={partnerDetail.companyInfo.name}
              globalParentName={partnerDetail.companyInfo.globalParentName}
            />
          </Tab>,
        );
        // Add to global visible tabs variable for hashes.
        this.visibleTabs.push('companyInfo');
      }
      // Expertise Tab
      if ((_.has(partnerDetail, 'expertise') && _.size(partnerDetail.expertise) > 0) || _.size(partnerDetail.deployments)) {
        this.expertiseTabIndex = tabs.length + 1;
        tabs.push(
          <Tab selected={this.state.defaultTab.includes('expertise')} key="expertiseTab" label={I18n.t('partner_finder.detail.expertise')}>
            <ExpertiseTab
              solutionExpertise={partnerDetail.expertise}
              customerDeployments={partnerDetail.deployments}
              accentColor={partnerDetail.companyInfo.accentColor}
            />
          </Tab>,
        );
        // Add to global visible tabs variable for hashes.
        this.visibleTabs.push('expertise');
      }

      // Accredited Solution Tab
      if (_.has(partnerDetail, 'accreditedSolutions')
        && _.size(partnerDetail.accreditedSolutions) > 0) {
        tabs.push(
          <Tab selected={this.state.defaultTab === 'accreditedSolutions'} key="accreditedSolutionsTab" label={I18n.t('partner_finder.detail.accredited_solutions')}>
            <AccreditedSolutionsTab
              accreditedSolutions={partnerDetail.accreditedSolutions}
            />
          </Tab>,
        );
        // Add to global visible tabs variable for hashes.
        this.visibleTabs.push('accreditedSolutions');
      }

      // Services and Industries Tab
      if ((_.has(partnerDetail, 'industries')
        && _.size(partnerDetail.industries) > 0)
        || (_.has(partnerDetail, 'services')
          && _.size(partnerDetail.services) > 0)) {
        tabs.push(
          <Tab selected={this.state.defaultTab === 'servicesAndIndustries'} key="ServicesIndustriesTab" label={I18n.t('partner_finder.detail.services_and_industries')}>
            <ServicesIndustriesTab
              services={partnerDetail.services}
              industries={partnerDetail.industries}
            />
          </Tab>,
        );
        // Add to global visible tabs variable for hashes.
        this.visibleTabs.push('servicesAndIndustries');
      }

      // Locations Tab
      if (_.has(partnerDetail, 'addresses')
        && _.size(partnerDetail.addresses) > 0) {
        tabs.push(
          <Tab selected={this.state.defaultTab === 'locations'} key="locationsTab" label={I18n.t('partner_finder.detail.locations')}>
            <LocationsTab locations={partnerDetail.addresses} />
          </Tab>,
        );
        // Add to global visible tabs variable for hashes.
        this.visibleTabs.push('locations');
      }

      // Resources Tab
      // check if resources are available and its not badges or CSC badges
      if (_.has(partnerDetail, 'resources')
        && _.size(partnerDetail.resources) > 0
        && (((_.size(partnerDetail.resources) === 1 && !(_.has(partnerDetail, 'resources.Badge') || _.has(partnerDetail, 'resources.CSC Badge'))) || _.size(partnerDetail.resources) > 1)
        && !(_.size(partnerDetail.resources) === 2 && _.has(partnerDetail, 'resources.Badge') && _.has(partnerDetail, 'resources.CSC Badge')))) {
        tabs.push(
          <Tab selected={this.state.defaultTab === 'resources'} key="resourcesTab" label={I18n.t('partner_finder.detail.resources')}>
            <ResourcesTab resources={partnerDetail.resources} />
          </Tab>,
        );
        // Add to global visible tabs variable for hashes.
        this.visibleTabs.push('resources');
      }

      return (
        <TabView id="tab-component" onSelect={handleTabChange} selectedIndex={this.state.selectedTabIndex}>
          {tabs}
        </TabView>
      );
    }

    return null;
  };

  render() {
    const { solution, partnerDetail } = this.props;

    return !partnerDetail.error ? (
      <motion.div
        exit="out"
        animate="in"
        initial="out"
        variants={PageVariants}
        transition={PageTransitions}
      >
        <Helmet titleTemplate={`%s | ${I18n.t('partner_finder.seo.app_title')}`}>
          <title>{this.getPartnerData('companyInfo.name')}</title>
          <meta name="description" content={this.getPartnerData('companyInfo.shortDescription')} />

          <meta property="og:title" content={`${this.getPartnerData('companyInfo.name')} | ${I18n.t('partner_finder.seo.app_title')}`} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={I18n.t('partner_finder.seo.app_title')} />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/thumb.png`} />
          <meta property="og:description" content={this.getPartnerData('companyInfo.shortDescription')} />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="480" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={`${this.getPartnerData('companyInfo.name')} | ${I18n.t('partner_finder.seo.app_title')}`} />
          <meta name="twitter:description" content={this.getPartnerData('companyInfo.shortDescription')} />
          <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/img/thumb.png`} />
          <meta name="twitter:image:alt" content="Adobe" />
          <meta name="twitter:site" content="@Adobe" />
          <meta name="twitter:creator" content="@Adobe" />
        </Helmet>
        {this.detailPageLoadingSpinner()}
        <HeroBanner />
        <Grid
          id="detail-page-container"
        >
          <View gridArea="info" UNSAFE_className="info-section">
            <PartnerInfoCard
              switchTab={this.switchToExpertiseTab}
              updateCurrentTab={() => { this.setState({ selectedTabIndex: 1 }); }}
            />
            <ShareProfileButton />
          </View>
          <View gridArea="main" UNSAFE_className="main-section">
            {!partnerDetail.preview ? <ReturnToResultsButton /> : null}
            <div className="partner-name-section">
              <Heading level={2}>{this.getPartnerData('companyInfo.name')}</Heading>
              <Text>{this.getPartnerData('companyInfo.location')}</Text>
              <ButtonGroup UNSAFE_className="button-group">
                <ContactPartnerButton scrollTargetId="contact" />
                <ViewPhoneNumberButton phoneNumber={this.getPartnerData('companyInfo.phone')} />
                <OpenURLButton
                  variant="primary"
                  translatePath="partner_finder.detail.visit_website"
                  websiteUrl={this.getPartnerData('companyInfo.website')}
                  target="_blank"
                />
              </ButtonGroup>
            </div>
            <div className="partner-tabs-section">
              <AnimatePresence>
                {this.renderAvailableTabs()}
              </AnimatePresence>
            </div>
          </View>
        </Grid>
        <ContactPartnerForm
          onSubmit={this.submit}
          partnerName={this.getPartnerData('companyInfo.name')}
          partnerMessage={this.getPartnerData('companyInfo.contactUsHeader')}
          solution={solution.options}
        />
        <ContactBlade />
      </motion.div>
    ) : (
      <motion.div
        exit="out"
        animate="in"
        initial="out"
        variants={PageVariants}
        transition={PageTransitions}
      >
        {this.detailPageLoadingSpinner()}
      </motion.div>
    );
  }
}

DetailPage.propTypes = {
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  partnerDetail: PropTypes.shape({
    preview: PropTypes.bool.isRequired,
    companyInfo: PropTypes.shape({
      listingId: PropTypes.string,
      accentColor: PropTypes.string,
      shortDescription: PropTypes.string,
      longDescription: PropTypes.string,
      listingUrl: PropTypes.string,
      name: PropTypes.string,
      globalParentName: PropTypes.string,
      globalParentId: PropTypes.string,
    }),
    contactForm: PropTypes.shape({
      description: PropTypes.string,
    }),
    expertise: PropTypes.arrayOf(PropTypes.object),
    deployments: PropTypes.objectOf(PropTypes.array),
    accreditedSolutions: PropTypes.arrayOf(PropTypes.object),
    industries: PropTypes.arrayOf(PropTypes.string),
    services: PropTypes.arrayOf(PropTypes.object),
    addresses: PropTypes.arrayOf(PropTypes.object),
    resources: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.object),
    }),
    error: PropTypes.number,
  }),
  getPartnerDetailAction: PropTypes.func.isRequired,
  getAllFacetDataAction: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  // results: PropTypes.bool.isRequired,
  solution: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object),
  }),
};

DetailPage.defaultProps = {
  partnerDetail: {},
  solution: {},
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  partnerDetail: state.partnerDetail,
  results: state.results.totalResults > 0,
  solution: state.facets.solution,
});

const mapDispatchToProps = {
  getPartnerDetailAction: getPartnerDetail,
  getAllFacetDataAction: getAllFacetData,
  submitFormAction: submitForm,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailPage));
