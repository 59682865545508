/* eslint-disable class-methods-use-this */
// React Imports
import React, { Component } from 'react';
import partnerDataAction from '../../actions/partnerDataAction';

// Styles
import '../css/Header.scss';

/**
 * Component for displaying the application header.
 */
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueEmpty: true,
    };
  }

  componentDidMount() {
    // wait for profile to be ready and then get the partner data to load feds GNAV lib
    window.addEventListener('adobeIMS:profileReady', this.showFedsGnav);
    // wait for the feds profile data to load and disable the profile header click actions
    window.addEventListener('feds.events.profile_data.loaded', () => {
      const interval = setInterval(() => {
        if (document.querySelector('.Profile-header')) {
          clearInterval(interval);
          // remove the click from the profile header section
          this.disableProfileAction();
          this.updateSearchAction();
        }
      }, 100);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('adobeIMS:profileReady');
    window.removeEventListener('feds.events.profile_data.loaded');
  }

  /**
   * Load Feds library
   */
  loadFeds = () => {
    const fedsLib = document.createElement('script');
    fedsLib.src = window.directoryConfig.feds.jslib;
    document.body.appendChild(fedsLib);
  };

  /**
   * remove the click from the profile header section
   */
  disableProfileAction = () => {
    // get the profile header
    const profileHeader = document.querySelector('.Profile-header');
    // get the profile avatar
    const avatar = document.querySelector('.Profile-avatar');
    if (profileHeader) {
      // prevent click on profile header
      profileHeader.addEventListener('click', (event) => {
        event.preventDefault();
      });
      // make sure its not clickable
      profileHeader.removeAttribute('href');
    }
    if (avatar) {
      // make sure its not clickable
      avatar.removeAttribute('data-url');
    }
  };

  /**
   * remove the sales center links from feds Gnav
   */
  removeSalesCenterLink = () => {
    // get all the sales center center elements
    const elms = document.querySelectorAll("[id='Globalnav.Adobe_Experience_Cloud']");
    for (let i = 0; i < elms.length; i += 1) {
      const data = elms[i].querySelector('.feds-navList-headline');
      const spans = data.getElementsByTagName('span');
      for (let j = 0; j < spans.length; j += 1) {
        // hide the sales center elements
        if (spans[j].innerHTML === 'Sales Center') {
          elms[i].style.display = 'none';
        }
      }
    }
  };

  /**
   * set the Gnav fedsConfig profile details and then load the post-loggedin GNAV
   * @param {Object} data - partner data
   */
  setPartnerData = (data) => {
    // set post-loggedin GNAV experience
    window.fedsConfig.content.experience = window.directoryConfig.feds.loggedInExperience;

    // set feds profile data
    window.fedsConfig.profile.localMenu.title = data.contactInfo && data.contactInfo.primaryJobRole ? data.contactInfo.primaryJobRole : 'No role selected';
    window.fedsConfig.profile.localMenu.links[0].label = data.contactInfo && data.contactInfo.primaryJobRole ? 'Update your profile' : 'Update Your Profile and Role';
    window.fedsConfig.profile.localMenu.links[2].label = data.partnerInfo && data.partnerInfo.name;
    window.fedsConfig.profile.localMenu.links[3].label = data.partnerInfo && data.partnerInfo.level;

    // if user has access to sales center, add sales center link in profile menu
    if (data.contactInfo && data.contactInfo.salesCenterAccess) {
      window.fedsConfig.profile.localMenu.links[5] = {
        label: 'Go to Sales Center',
        action: `${window.directoryConfig.salesCenterLink}`,
        class: 'profile-action-btn',
        target: '_self',
      };
    }
    // Wait for FEDS to fully load and then remove the sales center links from GNAV,
    // if user doesnt have access to sales center else append aToken to sales center links
    window.addEventListener('feds.events.experience.loaded', () => {
      if (!(data.contactInfo && data.contactInfo.salesCenterAccess)) {
        this.removeSalesCenterLink();
      }
    });
    // load the feds lib with post-loggedin GNAV
    this.loadFeds();
  };

  /**
   * fetch the partner data and show feds public/post-loggedin gnav based on the details
   * @param {Object} profile - user information
   */
  fetchPartnerData = () => {
    partnerDataAction(window.directoryConfig.app.programType).then((data) => {
      const result = data && data.data;
      // check if partner is valid then load post-loggedin GNAV
      if (result.partnerInfo && result.partnerInfo.name) {
        // set the profile data in fedsconfig and then load the post-loggedin GNAV
        this.setPartnerData(result);
      } else {
        // load the feds lib with public GNAV
        this.loadFeds();
      }
    })
      .catch(() => {
        // load the feds lib with public GNAV
        this.loadFeds();
      });
  };

  /**
   * get the user profile detail from adobeIMS
   */
  showFedsGnav = () => {
    const urlParams = new URLSearchParams(window.location.search);
    // check if page is in preview mode to load public GNAV
    if (urlParams.has('preview') && urlParams.get('preview') === 'true') {
      // load the feds lib with public GNAV
      this.loadFeds();
    } else {
      // fetch the partner data to show the public/post-loggedin gnav
      this.fetchPartnerData();
    }
  };

  // show search results
  showSearchResults = (data, userInput) => {
    // get search result wrapper
    const searchResultWrapper = document.querySelector('.feds-searchResults-wrapper');
    const { valueEmpty } = this.state;
    // check if input is empty, then hide the search results
    if (valueEmpty) {
      searchResultWrapper.classList.add('hide');
    } else {
      searchResultWrapper.classList.add('show');
      let innerHTML = '';
      // create list of generated results
      if (data && data.suggest && data.suggest.suggestions && data.suggest.suggestions.length > 0) {
        innerHTML = '<ul class="feds-searchResults results-list search-content" id="feds-searchResults" role="region" daa-ll="search-results:suggested-search:click">';
        data.suggest.suggestions.forEach((item) => {
          const regexInput = new RegExp(userInput, 'i');
          const nameMatch = item.suggestion.replace(regexInput, '<strong>$&</strong>');
          innerHTML += `<li class="list-item feds-searchResult"><a href="${window.directoryConfig.searchUrl}?search=${item.suggestion}" class="feds-searchResult-links font-weight-400" aria-label="${item.suggestion}">${nameMatch}</a></li>`;
        });
        innerHTML += '</ul>';
      }

      innerHTML += `<div class="feds-advancedSearch" style="display: block;"><a class="feds-advancedSearch-link" href="${window.directoryConfig.searchUrl}?search=${userInput}">See all results</a></div>`;
      searchResultWrapper.innerHTML = innerHTML;
      // add click event to all the links
      // this.addClicktoLinks(userInput);
    }
  };

  // add click event to all the links
  // addClicktoLinks = (userInput) => {
  //   // grab all the search result links and click event to open the search result
  //   const links = document.querySelectorAll('.feds-searchResult-links');
  //   links.forEach((item) => {
  //     item.addEventListener('click', (event) => {
  //       event.preventDefault();
  //       window.open(`${window.directoryConfig.searchUrl}?
  //        search=${item.getAttribute('aria-label')}`, '_self');
  //     });
  //   });

  //   // grab all results links and open the search results
  //   document.querySelector('.feds-advancedSearch-link').addEventListener('click', (event) => {
  //     event.preventDefault();
  //     window.open(`${window.directoryConfig.searchUrl}?search=${userInput}`, '_self');
  //   });
  // };

  // hide the search results
  hideSearchResults = () => {
    this.setState({
      valueEmpty: true,
    }, () => {
      const searchResultWrapper = document.querySelector('.feds-searchResults-wrapper');
      searchResultWrapper.classList.add('hide');
    });
  };

  fetchSearchResults = async (searchText) => {
    const endpoint = `${window.directoryConfig.searchApi}${searchText}`;
    const resp = await fetch(endpoint);
    return resp;
  };

  // create a new input search element and replace it with old input
  updateSearchAction = () => {
    // get the close icon of search popup
    const closeIcon = document.querySelector('.feds-search-close');
    // grab the form element
    const oldForm = document.querySelector('.feds-searchForm');
    // update the action attribute
    oldForm.action = '/solution-partners/home/search.html';
    // create a new input search element
    const newInput = document.createElement('INPUT');
    newInput.setAttribute('autofocus', 'on');
    newInput.setAttribute('autocomplete', 'off');
    newInput.type = 'text';
    newInput.name = 'search';
    newInput.placeholder = 'Search';
    newInput['aria-label'] = 'Search';
    newInput.className = 'feds-searchInput';
    newInput.ariaAutoComplete = 'list';
    // add input event to the input ssearch element
    newInput.addEventListener('input', (e) => {
      // if input has value then show the search results
      if (e.target.value) {
        // grab the search icon of in input
        const searchIcon = document.querySelector('.feds-searchIcons');
        // add a class to it to show clear icon
        searchIcon.classList.add('feds-searchIcons--hasInput');
        // add a click event to it to clear the results
        searchIcon.addEventListener('click', () => {
          newInput.value = '';
          this.hideSearchResults();
        });
        // remove focused class from form element
        oldForm.classList.remove('feds-searchForm--focused');
        this.setState({
          valueEmpty: false,
        }, async () => {
          // fetch the search results based on the userInput
          const resp = await this.fetchSearchResults(e.target.value);
          const result = await resp.json();
          // grab the search results
          const searchResultWrapper = document.querySelector('.feds-searchResults-wrapper');
          // remove hide class to show the search results
          searchResultWrapper.classList.remove('hide');
          // show the search results
          this.showSearchResults(result, e.target.value);
        });
      } else {
        // hide the search icon and also the search results
        const searchIcon = document.querySelector('.feds-searchIcons');
        searchIcon.classList.remove('feds-searchIcons--hasInput');
        this.hideSearchResults();
      }
    });
    // add a click event on close icon of the popup to hide the search results
    closeIcon.addEventListener('click', () => {
      newInput.value = '';
      this.hideSearchResults();
    });

    // grab the search icon to open search popup
    const searchIcon = document.querySelector('.feds-search-trigger');
    // add a click event to it to add focus to input box
    searchIcon.addEventListener('click', () => {
      newInput.value = '';
      newInput.focus();
      oldForm.classList.add('feds-searchForm--focused');
      this.hideSearchResults();
    });

    // grab the input element
    const oldInput = document.querySelector('.feds-searchInput');
    // replace the input with new input element
    oldInput.replaceWith(newInput);
    // update the submit function of form
    oldForm.addEventListener('submit', (event) => {
      event.preventDefault();
      if (newInput.value) {
        window.open(`${window.directoryConfig.searchUrl}?search=${newInput.value}`, '_self');
      }
    });
  };

  render() {
    return (
      <>
        <div className="topnavSpacing" style={{ height: 65 }} />
        <div>
          <div id="feds-header" />
        </div>
      </>
    );
  }
}

export default Header;
