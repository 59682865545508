// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Icons
import Download from '@spectrum-icons/workflow/Download';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import PlayCircle from '@spectrum-icons/workflow/PlayCircle';

// Lodash
import _ from 'lodash';

// Util
import { normalizeUrl } from '../util/util';

// CSS
import './css/ContentCard.scss';

class ContentCard extends Component {
  // Constructor
  constructor(props) {
    super(props);
    // State used for fetching external thumbnails
    this.state = {
      thumbnailImageUrlResolved: null,
    };
  }

  /**
   * cardWidthClass
   * Returns a class name based on the cardWidth prop.
   */
  cardWidthClass = () => {
    const { cardWidth } = this.props;
    switch (cardWidth) {
      case '1':
        return 'card-width-1';
      case '3':
        return 'card-width-3';
      default:
        return 'card-width-2';
    }
  };

  /**
   * renderPlayIcon
   * Renders a play icon div if a prop specifies it.
   */
  renderPlayIcon = () => {
    const { playIcon } = this.props;
    if (playIcon) {
      return (
        <div className="playIcon">
          <PlayCircle size="XL" />
        </div>
      );
    }
    return null;
  };

  /**
   * renderDescriptions
   * Returns a description, or several address lines if it is an address.
   */
  renderDescription = () => {
    const { descriptionIsAddress, description } = this.props;
    // If we have a description
    if (description) {
      // If the description is an address.
      if (descriptionIsAddress) {
        return description.map((addressLine) => (<p key={addressLine} className="addressLine">{addressLine}</p>));
      }
      // Otherwise just return the description.
      return <p>{description}</p>;
    }
    // Don't return anything if we don't have a description.
    return null;
  };

  /**
   * renderThumbnail
   * Returns a thumbnail image.
   */
  renderThumbnail = () => {
    const { thumbnailImageUrl } = this.props;
    const { thumbnailImageUrlResolved } = this.state;

    // If we have a URL in state that's not null, render out the image.
    if (thumbnailImageUrlResolved) {
      return (
        <div className="thumbnail" style={{ background: `url(${thumbnailImageUrlResolved})` }}>
          {this.renderPlayIcon()}
        </div>
      );
    }

    // YouTube Thumbnails
    if (thumbnailImageUrl.includes('youtube.com') || thumbnailImageUrl.includes('youtu.be')) {
      // Try to get the video ID.
      const videoId = thumbnailImageUrl.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/);
      // If we found a video ID.
      if (_.size(videoId) > 1) {
        // Return the video thumbnail URL.
        this.setState({ thumbnailImageUrlResolved: `https://img.youtube.com/vi/${videoId[1]}/maxresdefault.jpg` });
      }
    }

    // Vimeo Thumbnails
    if (thumbnailImageUrl.includes('vimeo.com')) {
    // Try to get the video metadata from Vimeo.
      fetch(`https://vimeo.com/api/oembed.json?url=${thumbnailImageUrl}`)
        .then((response) => response.json())
        .then((data) => {
          // If we have a thumbnail URL in the response, use it.
          if (_.has(data, 'thumbnail_url')) {
            this.setState({ thumbnailImageUrlResolved: data.thumbnail_url });
          }
        })
        .catch(() => {
          window.console.error('Unable to fetch Vimeo thumbnail.');
        });
    }

    // Otherwise, don't return an image.
    return null;
  };

  /**
   * renderRibbon
   * Returns a ribbon div
   */
  renderRibbon = () => {
    const { ribbonText } = this.props;
    if (ribbonText) {
      return (
        <div className="ribbon">
          {ribbonText}
        </div>
      );
    }
    return null;
  };

  /**
   * renderLink
   * Returns a link and icon.
   */
  renderLink = () => {
    const { linkText, linkIcon, linkUrl } = this.props;
    if (linkText) {
      let icon = null;
      switch (linkIcon) {
        case 'link':
          icon = <LinkOut size="S" />;
          break;
        case 'download':
          icon = <Download size="S" />;
          break;
        default:
          break;
      }
      return (
        <div className="link">
          {icon}
          <a href={normalizeUrl(linkUrl)} target="_blank" rel="noreferrer">
            {linkText}
          </a>
        </div>
      );
    }
    return null;
  };

  /**
   * renderCard
   * The main function for rendering a content card.
   */
  renderCard = () => {
    const {
      wrapCardInLink, linkUrl, ribbonText, thumbnailImageUrl, cardWidth, header,
    } = this.props;
    // If we want to wrap the entire card in a link, turn it into a button.
    if (wrapCardInLink) {
      return (
        <div className={`cmp-content-card ${this.cardWidthClass()} as-button`} role="button" tabIndex={0} onClick={() => window.open(normalizeUrl(linkUrl), '_blank')} onKeyPress={() => window.open(normalizeUrl(linkUrl), '_blank')}>
          {this.renderRibbon()}
          {this.renderThumbnail()}
          <div className={`content-section ${ribbonText && !thumbnailImageUrl && cardWidth !== '3' ? 'hasRibbon' : ''}`}>
            <h1>{header}</h1>
            {this.renderDescription()}
            {this.renderLink()}
          </div>
        </div>
      );
    }

    // If we do not need to return a button card.
    return (
      <div className={`cmp-content-card ${this.cardWidthClass()}`}>
        {this.renderRibbon()}
        {this.renderThumbnail()}
        <div className={`content-section ${ribbonText && !thumbnailImageUrl && cardWidth !== '3' ? 'hasRibbon' : ''}`}>
          <h1>{header}</h1>
          {this.renderDescription()}
          {this.renderLink()}
        </div>
      </div>
    );
  };

  render() {
    return this.renderCard();
  }
}
ContentCard.propTypes = {
  /**
   * thumbnailImageUrl {String} - The URL of a thumbnail image.
   */
  thumbnailImageUrl: PropTypes.string,
  /**
   * playIcon {Boolean} - Whether to show a play icon on a thumbnail image. If there is
   * no thumbnail image, this prop is ignored.
   */
  playIcon: PropTypes.bool,
  /**
   * ribbonText {String} - The text that should appear on the ribbon.
   */
  ribbonText: PropTypes.string,
  /**
   * header {String} - The card heading text.
   */
  header: PropTypes.string,
  /**
   * description {String or Array} - Text to show in the description area of the card.
   * If descriptionIsAddress is true, this value should be an array, otherwise a string.
   */
  description: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ],
  ),
  /**
   * descriptionIsAddress {Boolean} - Whether the description being passed is an array
   * or a string.
   */
  descriptionIsAddress: PropTypes.bool,
  /**
   * linkUrl {String} - The url of the link or button should navigate to.
   */
  linkUrl: PropTypes.string,
  /**
   * linkText {String} - The link text. Not mandatory if you also use wrapCardInLink.
   */
  linkText: PropTypes.string,
  /**
   * linkIcon {String} - The icon to show next to the link. Either "link" or "download"
   */
  linkIcon: PropTypes.string,
  /**
   * wrapCardInLink {Boolean} - Whether the entire card should act as a button. Will
   * show a hover effect denoting it is a button.
   */
  wrapCardInLink: PropTypes.bool,
  /**
   * cardWidth {String} - the width of the card, either "1" for 33% width, "2" for
   * 50%, or "3" for 100%
   */
  cardWidth: PropTypes.string,
};
ContentCard.defaultProps = {
  thumbnailImageUrl: '',
  playIcon: false,
  ribbonText: '',
  header: '',
  description: '',
  descriptionIsAddress: false,
  linkUrl: '',
  linkText: '',
  linkIcon: '',
  wrapCardInLink: false,
  cardWidth: 1,
};

export default ContentCard;
