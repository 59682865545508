// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// React Spectrum Components
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Close from '@spectrum-icons/workflow/Close';
import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Switch,
} from '@adobe/react-spectrum';

// React Router
import { withRouter } from './withRouter';

// Redux Actions
import { addSingleFilter, removeSingleFilter } from '../actions/filterActions';

// Styles
import './css/FilterCommon.scss';
import './css/ToggleFilter.scss';

class ToggleFilter extends Component {
  // Mobile global var.
  isMobile = false;

  constructor(props) {
    super(props);
    // Detect mobile on init.
    this.detectMobile();
  }

  /**
   * Clear Filter
   * Clears a filter from the redux store and refreshes the component.
   */
  clearFilter = () => {
    const { removeSingleFilterAction, router } = this.props;

    removeSingleFilterAction({
      name: 'accredited-solutions',
    }, router);
  };

  /**
   * Handle Toggle Switch
   * Handles the toggle switch that turns on/off of Accredited Solution filter.
   * @param {Event} e - value of the toggle event.
   */
  handleToggleSwitch = (e) => {
    const {
      addSingleFilterAction, removeSingleFilterAction, router,
    } = this.props;

    if (e) {
      addSingleFilterAction({
        name: 'accredited-solutions',
        value: true,
      }, router);
    } else {
      removeSingleFilterAction({
        name: 'accredited-solutions',
      }, router);
    }
  };

  /**
   * Detect Mobile
   * Detects the viewport and sets the global isMobile variable.
   */
  detectMobile() {
    // Detect if we are on a mobile device.
    const mql = window.matchMedia('(max-width: 600px)');
    this.isMobile = mql.matches;
  }

  /**
   * Render Toggle Filter
   * Renders the toggle filter component
   */
  renderToggleFilter() {
    const { appliedFilters } = this.props;
    const selectedClass = appliedFilters['accredited-solutions'] ? 'selected' : '';
    const selected = _.has(appliedFilters, 'accredited-solutions');

    // JSX for the Dropdown button
    const dropdownButton = (
      <ActionButton UNSAFE_className={`filterButton ${selectedClass}`} data-test-id="toggle-filter-btn">
        <Translate value="partner_finder.filters.accredited_solutions_label" />
        <div className="caret"><ChevronDown size="XS" /></div>
      </ActionButton>
    );

    // JSX for the "X" clear filter button.
    const clearFilterButton = (
      <ActionButton UNSAFE_className={`clearFilterButton ${selectedClass}`} onPress={() => this.clearFilter()} data-test-id="toggle-filter-clear">
        <div className="separator" />
        <div className="smallCaret"><ChevronDown size="XS" /></div>
        <div className="clearFilter"><Close size="XS" /></div>
      </ActionButton>
    );

    // If we are on Desktop view
    if (!this.isMobile) {
      return (
        <>
          <DialogTrigger
            hideArrow
            type="popover"
            placement="bottom left"
            data-test-id="toggle-filter-menuTrigger"
          >
            {dropdownButton}
            <Dialog UNSAFE_className="toggle-filter-dialog">
              <div className="dropdown-upper-section">
                <Heading UNSAFE_className="dropdown-title">
                  <Translate value="partner_finder.filters.accredited_solutions_header" />
                </Heading>
                <Switch
                  aria-label={I18n.t('partner_finder.filters.accredited_solutions_label')}
                  isEmphasized
                  UNSAFE_className="toggle-switch"
                  isSelected={selected}
                  onChange={(e) => this.handleToggleSwitch(e)}
                />
              </div>
              <Content UNSAFE_className="dropdown-content">
                <Translate
                  value="partner_finder.filters.accredited_solutions_description"
                  accreditedSolutionLink={window.directoryConfig.accreditedSolutionLink}
                  cscInfoLink={window.directoryConfig.cscInfoLink}
                  dangerousHTML
                />
              </Content>
            </Dialog>
          </DialogTrigger>
          {clearFilterButton}
        </>
      );
    }
    // Hidden on mobile view
    return null;
  }

  // Render the component
  render() {
    return (
      <div className="cmp-toggleFilter" id="accredited-filter" style={{ whiteSpace: 'nowrap' }}>
        {this.renderToggleFilter()}
      </div>
    );
  }
}

ToggleFilter.propTypes = {
  /**
   * addSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that adds a filter to the redux store.
   */
  addSingleFilterAction: PropTypes.func.isRequired,
  /**
   * removeSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that removes a filter from the redux store.
   */
  removeSingleFilterAction: PropTypes.func.isRequired,
  /**
   * appliedFilters {Object} - An object provided by mapStateToProps
   * that contains the selected filter data.
   */
  appliedFilters: PropTypes.shape({
    'accredited-solutions': PropTypes.bool,
  }),
  /**
   * router {Object} - An object provided by React Router that allows navigation.
   */
  router: PropTypes.shape({}).isRequired,
};

ToggleFilter.defaultProps = {
  appliedFilters: {},
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  appliedFilters: state.filter.selected,
});

const mapDispatchToProps = {
  addSingleFilterAction: addSingleFilter,
  removeSingleFilterAction: removeSingleFilter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToggleFilter));
