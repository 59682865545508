// React
import React from 'react';
import PropTypes from 'prop-types';

// React Spectrum
import {
  Grid,
  View,
} from '@adobe/react-spectrum';

// I18n
import { Translate } from 'react-redux-i18n';

// Framer Motion
import { motion } from 'framer-motion';
import { DetailTabAnimation } from '../../../util/FramerMotionConfig';

// Local project component
import SolutionBrief from '../../SolutionBrief';

// Styles
import '../../css/SolutionBrief.scss';
import '../../css/TabCommon.scss';

const AccreditedSolutionsTab = ({ accreditedSolutions }) => (
  <motion.div
    initial={DetailTabAnimation.initial}
    animate={DetailTabAnimation.animate}
    exit={DetailTabAnimation.exit}
    transition={DetailTabAnimation.transition}
  >
    <Grid UNSAFE_className="tab-content-container">
      <View gridArea="tab-content" UNSAFE_className="tab-content">
        <h2><Translate value="partner_finder.detail.accredited_solutions_tab.accredited_solutions_header" /></h2>
        <h3><Translate value="partner_finder.detail.accredited_solutions_tab.accredited_solutions_description" /></h3>
        <div className="solution-brief-content">
          {accreditedSolutions.map((solution) => (
            <SolutionBrief accreditedSolution={solution} key={solution.headline} />
          ))}
        </div>
      </View>
    </Grid>

  </motion.div>
);

AccreditedSolutionsTab.propTypes = {
  accreditedSolutions: PropTypes.arrayOf(PropTypes.shape({})),
};

AccreditedSolutionsTab.defaultProps = {
  accreditedSolutions: [],
};

export default AccreditedSolutionsTab;
