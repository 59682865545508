import _ from 'lodash';

import {
  SET_TOTAL_RESULTS,
  LOADING_RESULTS,
  LOADING_MORE_RESULTS,
  ADD_RESULT_CARDS,
  PRELOADING_DETAIL,
  CLEAR_PRELOADING_DETAIL,
  SET_RESULT_CARDS,
  SET_SCROLL_POSITION,
} from '../constants';

const INITIAL_STATE = {
  totalResults: 0,
  loadingResults: false,
  loadingMoreResults: false,
  cards: {},
  scrollPosition: 0,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_TOTAL_RESULTS: {
      return {
        ...state,
        totalResults: payload,
      };
    }
    case LOADING_RESULTS: {
      return {
        ...state,
        loadingResults: true,
      };
    }
    case LOADING_MORE_RESULTS: {
      return {
        ...state,
        loadingResults: true,
        loadingMoreResults: true,
      };
    }
    case ADD_RESULT_CARDS: {
      const newState = _.clone(state);
      return {
        ...newState,
        loadingResults: false,
        loadingMoreResults: false,
        cards: Object.assign(newState.cards, _.mapKeys(payload, 'id')),
      };
    }
    case SET_RESULT_CARDS: {
      return {
        ...state,
        loadingResults: false,
        loadingMoreResults: false,
        cards: _.mapKeys(payload, 'id'),
        scrollPosition: 0,
      };
    }
    // Payload: { id: '123' }
    case PRELOADING_DETAIL: {
      const newState = _.setWith(_.clone(state), `cards["${payload.id}"].preloading`, true, _.clone);
      return { ...state, ...newState };
    }
    // Payload: { id: '123' }
    case CLEAR_PRELOADING_DETAIL: {
      const newState = _.setWith(_.clone(state), `cards["${payload.id}"].preloading`, null, _.clone);
      return { ...state, ...newState };
    }
    case SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: payload,
      };
    }
    default:
      return state;
  }
};
