// React
import React from 'react';
import PropTypes from 'prop-types';

// React Spectrum components
import { Button } from '@adobe/react-spectrum';

// I18n
import { Translate } from 'react-redux-i18n';

// Styles
import './css/ContactPartnerButton.scss';

const ContactPartnerButton = ({ scrollTargetId }) => {
/**
 * scrollToTarget
 * Scrolls the window to a specified element, provided by props.
 */
  const scrollToTarget = () => {
    const element = document.getElementById(scrollTargetId);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Button
      variant="cta"
      data-test-id="contact-partner-button"
      onPress={scrollToTarget}
      UNSAFE_className="message-partner-button"
    >
      <Translate value="partner_finder.detail.message_partner" />
    </Button>
  );
};
ContactPartnerButton.propTypes = {
  scrollTargetId: PropTypes.string.isRequired,
};

export default ContactPartnerButton;
