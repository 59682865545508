// React
import React from 'react';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// React Spectrum
import {
  Grid,
  View,
  Flex,
} from '@adobe/react-spectrum';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// Framer Motion
import { motion } from 'framer-motion';
import { DetailTabAnimation } from '../../../util/FramerMotionConfig';

// Content Card
import ContentCard from '../../ContentCard';

// Styles
import '../../css/TabCommon.scss';

const renderCards = (locations) => {
  // Make a clone we can manipulate.
  const partnerLocations = _.clone(locations);

  // Sort items, bring headquarters to front of the list.
  partnerLocations.sort((x, y) => {
    if (x.isHeadquarter) {
      return -1;
    }
    if (y.isHeadquarter) {
      return 1;
    }
    return 0;
  });

  // Create a content card for each
  return partnerLocations.map((o) => (
    <ContentCard
      key={o.name}
      ribbonText={o.isHeadquarter ? I18n.t('partner_finder.detail.locations_tab.headquarters') : null}
      header={o.name}
      descriptionIsAddress
      description={[o.address1, `${o.city || ''}, ${o.state || ''} ${o.postalCode || ''}`, o.country]}
      cardWidth={o.isHeadquarter ? '3' : '2'}
    />
  ));
};

const LocationsTab = ({ locations }) => (
  <motion.div
    initial={DetailTabAnimation.initial}
    animate={DetailTabAnimation.animate}
    exit={DetailTabAnimation.exit}
    transition={DetailTabAnimation.transition}
  >
    <Grid UNSAFE_className="tab-content-container">
      <View gridArea="tab-content" UNSAFE_className="tab-content">
        <h2><Translate value="partner_finder.detail.locations_tab.locations_header" /></h2>
        <Flex gap="35px" wrap>
          {renderCards(locations)}
        </Flex>
      </View>
    </Grid>

  </motion.div>
);
LocationsTab.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
};
LocationsTab.defaultProps = {
  locations: [],
};

export default LocationsTab;
