/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
// React
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// lodash
import _ from 'lodash';

// React Spectrum
import {
  Divider,
  Grid,
  Link,
  View,
} from '@adobe/react-spectrum';
import Ribbon from '@spectrum-icons/workflow/Ribbon';
import GlobeOutline from '@spectrum-icons/workflow/GlobeOutline';

// I18n
import { Translate } from 'react-redux-i18n';

// Framer Motion
import { motion } from 'framer-motion';
import { DetailTabAnimation } from '../../../util/FramerMotionConfig';

// Local project component
import RegionSpecialization from '../../RegionSpecialization';

// Styles
import '../../css/ExpertiseTab.scss';
import '../../css/TabCommon.scss';
import { CUSTOMER_DEPLOYMENTS } from '../../../constants';

const renderRegionsSpecialization = (regions, type) => {
  if (type === CUSTOMER_DEPLOYMENTS) {
    const deployments = regions;
    const geos = Object.keys(regions);
    const geosRowChunk = _.chunk(geos, 2);
    return (
      <>
        {geosRowChunk.map((geosItem, index) => (
          <div className="region-section-row" key={geosItem}>
            {geosItem.map((geo) => (
              <RegionSpecialization region={deployments[geo]} type={type} geo={geo} />
            ))}
          </div>
        ))}
      </>
    );
  }
  const regionRow = _.chunk(regions, 2);
  return (
    <>
      {regionRow.map((region, index) => (
        <div className="region-section-row" key={region[index] ? region[index].name : null}>
          {region.map((item) => (
            <RegionSpecialization region={item} key={type || item.name} />
          ))}
        </div>
      ))}
    </>
  );
};

const renderIcon = () => (
  <div className="ribbon-circle">
    <Ribbon size="M" />
  </div>
);

const ExpertiseTab = ({ solutionExpertise, customerDeployments }) => {
  const deploymentSection = useRef(null);

  const goToDeploymentSection = () => {
    if (deploymentSection && deploymentSection.current) {
      const headerOffset = 150;
      const elementPosition = deploymentSection.current?.getBoundingClientRect()?.top || 0;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash === '#expertise#deployments') goToDeploymentSection();
  }, [window.location.hash]);

  return (
    <motion.div
      initial={DetailTabAnimation.initial}
      animate={DetailTabAnimation.animate}
      exit={DetailTabAnimation.exit}
      transition={DetailTabAnimation.transition}
    >
      <Grid UNSAFE_className="tab-content-container">
        <View gridArea="tab-content" UNSAFE_className="tab-content">
          <h2><Translate value="partner_finder.detail.expertise_tab.expertise_header" /></h2>
          <h3>
            <Translate value="partner_finder.detail.expertise_tab.expertise_description" dangerousHTML />
            {_.size(customerDeployments) ? (
              <>
                <br />
                <div className="expertise_description_2">
                  <Link UNSAFE_style={{ color: '#1473e6', textDecoration: 'none' }} onPress={() => goToDeploymentSection()}>View</Link>
                  {' '}
                  <Translate value="partner_finder.detail.expertise_tab.expertise_description_2" />
                </div>
              </>
            ) : null}
          </h3>

          <div className="solution-expertise-container">
            <Divider UNSAFE_className="solution-expertise-divider" size="S" />
            <div>
              {solutionExpertise.map((solution, index) => {
                if (solution.name === 'Customer references') return null;
                return (
                  <div className="region-expertise" key={solution.name}>
                    <div className="solution-header-container">
                      <div className="solution-header">
                        {renderIcon()}
                        <div>
                          <div className="solution-name">
                            {solution.name}
                          </div>
                          <div className="solution-certification">
                            <Translate
                              value="partner_finder.detail.expertise_tab.solution_certification"
                              totalCertifications={solution.totalCertifications}
                            />
                          </div>
                        </div>
                      </div>
                      {solution.globalSpecialization
                        && (
                          <div className="global-specialization">
                            <GlobeOutline size="XS" UNSAFE_className="globe-icon" />
                            <Translate value="partner_finder.detail.expertise_tab.global_specialization" />
                          </div>
                        )}

                    </div>

                    <div className="region-section">
                      {renderRegionsSpecialization(solution.regions)}
                    </div>
                    <Divider size="S" />
                  </div>
                );
              })}

              {_.size(customerDeployments) ? (
                <div className="region-expertise" key={customerDeployments}>
                  <div className="solution-header-container">
                    <div className="solution-header">
                      <div className="solution-name">
                        <Translate value="partner_finder.detail.customerDeployment" />
                      </div>
                    </div>
                  </div>

                  <div ref={deploymentSection} className="deploymentSectionInfo" id="deploymentSection"><Translate value="partner_finder.detail.expertise_tab.deployment_card_info" /></div>
                  <div className="region-section">
                    {renderRegionsSpecialization(customerDeployments, CUSTOMER_DEPLOYMENTS)}
                  </div>
                </div>
              ) : null}

            </div>
          </div>
        </View>
      </Grid>

    </motion.div>
  );
};

ExpertiseTab.propTypes = {
  solutionExpertise: PropTypes.arrayOf(PropTypes.shape({
    globalSpecialization: PropTypes.bool,
    name: PropTypes.string,
    regions: PropTypes.arrayOf(PropTypes.object),
    totalCertifications: PropTypes.number,
  })),
  customerDeployments: PropTypes.objectOf(PropTypes.array),
};

ExpertiseTab.defaultProps = {
  solutionExpertise: [],
  customerDeployments: {},
};

export default ExpertiseTab;
