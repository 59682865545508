/* eslint-disable react/no-unused-class-component-methods */
// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// I18n
import { Translate } from 'react-redux-i18n';

// React Spectrum Components
import {
  Flex,
  View,
  Button,
  Text,
  Grid,
} from '@adobe/react-spectrum';

// Icons
import FilterIcon from '@spectrum-icons/workflow/Properties';
import Add from '@spectrum-icons/workflow/Add';

// Framer Motion
import { AnimatePresence, motion } from 'framer-motion';

// React Router
import { withRouter } from './withRouter';

// Actions
import { expandFilters, clearAllFilters } from '../actions/filterActions';

// Components
import Search from './Search';

// Utils
import { addCommaSeparator } from '../util/util';

// Styles
import './css/FilterContainer.scss';

class FilterContainer extends Component {
  // Mobile global var.
  isMobile = false;

  constructor(props) {
    super(props);
    // Detect mobile on init.
    this.detectMobile();
  }

  /**
     * Detect Mobile
     * Detects the viewport and sets the global isMobile variable.
     */
  detectMobile() {
    // Detect if we are on a mobile device.
    const mql = window.matchMedia('(max-width: 600px)');
    this.isMobile = mql.matches;
  }

  /**
     * Render Container
     * Renders the filter container.
     */
  renderContainer() {
    const {
      children,
      expanded,
      expandFiltersAction,
      appliedFilters,
      clearAllFiltersAction,
      router,
      totalResults,
      loadingResults,
      appInitialized,
    } = this.props;

    // Do we have industry, level, or companySize set already?
    const hiddenFiltersAlreadyApplied = (
      _.has(appliedFilters, 'industry')
        || _.has(appliedFilters, 'level')
        || _.has(appliedFilters, 'specialized')
        /* commenting
         this as part of removing company-size from view
          || _.has(appliedFilters, 'company-size') */
    );

    // If there are filters set that we are not displaying, expand to show.
    if (hiddenFiltersAlreadyApplied && !expanded) {
      expandFiltersAction();
    }

    // Render only the first 3 filters if we are not expanded
    const renderChildren = () => {
      if (!expanded) {
        // Return only the first three children and a More Filters button
        const filteredChildren = React.Children.map(children, (child, i) => {
          if (i < 3) {
            return child;
          }
          return null;
        });
          // Return Filtered Children 0-2 and More Filters Button
        return (
          <>
            { filteredChildren }
            <Button id="more-filters" variant="primary" isQuiet onPress={expandFiltersAction}>
              <Add />
              <Text><Translate value="partner_finder.filters.more_filters" /></Text>
            </Button>
          </>
        );
      }
      return children;
    };

    // Renders the Clear All Filters Link
    const renderClearAllLink = () => {
      if (Object.keys(appliedFilters).length) {
        return (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
            className="clear-all-motion-container"
          >
            <View id="clear-all-section" UNSAFE_className={Object.keys(appliedFilters).length ? 'filters-set' : null}>
              <Button variant="secondary" isQuiet onPress={() => clearAllFiltersAction(router)} id="clear-all-filters-button" data-test-id="clear-all-filters-button">
                <Translate value="partner_finder.filters.clear_all" />
              </Button>
            </View>
          </motion.div>
        );
      }
      return null;
    };

    // Renders the number of results
    const renderResultsCounter = () => {
      if (!loadingResults && appInitialized) {
        return (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
          >
            <div className="resultsCounter">
              <Text id="result-count">{addCommaSeparator(totalResults)}</Text>
              {' '}
              <Text><Translate value="partner_finder.filters.results" /></Text>
            </div>
          </motion.div>
        );
      }
      return null;
    };

    return (
      <Grid
        UNSAFE_className="filterContainer"
      >
        <View gridArea="filters" id="filter-section">
          <Flex alignItems="flex-start" gap="7px" UNSAFE_className="filter-flex-parent">
            <FilterIcon size="S" id="filterIcon" />
            <Translate value="partner_finder.filters.filters" className="filterLabel" />
            <Flex wrap="wrap" alignItems="center" gap="7px" UNSAFE_className="filter-flex-parent">
              {renderChildren()}
            </Flex>
          </Flex>
        </View>
        <AnimatePresence>
          {renderClearAllLink()}
        </AnimatePresence>
        <View gridArea="search" id="result-search-section">
          <AnimatePresence>
            {renderResultsCounter()}
          </AnimatePresence>
          <Search />
        </View>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        {this.renderContainer()}
      </div>
    );
  }
}
FilterContainer.propTypes = {
  /**
   * expanded {Boolean} - A boolean provided by mapStateToProps
   * that contains whether we should show all filters.
   */
  expanded: PropTypes.bool.isRequired,
  /**
   * children {React Elements} - Child react elements, all filters.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * expandFiltersAction {Function} - A function provided by mapDispatchToProps
   * that expands filters.
   */
  expandFiltersAction: PropTypes.func.isRequired,
  /**
   * appliedFilters {Object} - An object provided by mapStateToProps
   * that contains the selected filter data.
   */
  appliedFilters: PropTypes.shape({
    location: PropTypes.shape({
      geo: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
  /**
   * clearAllFiltersAction {Object} - An action provided by mapDispatchToProps
   * that clears all set filters.
   */
  clearAllFiltersAction: PropTypes.func.isRequired,
  /**
   * router {Object} - An object provided by React Router that allows navigation.
   */
  router: PropTypes.shape({}).isRequired,
  /**
   * totalResults {Number} - a number of total results provided by mapStateToProps.
   */
  totalResults: PropTypes.number.isRequired,
  /**
   * loadingResults {Boolean} - whether we are loading results or not, provided by
   * mapStateToProps.
   */
  loadingResults: PropTypes.bool.isRequired,
  /**
   * appInititialized {Boolean} - whether the app is initialized, provided by
   * mapStateToProps.
   */
  appInitialized: PropTypes.bool.isRequired,
};
FilterContainer.defaultProps = {
  appliedFilters: {},
};

const mapStateToProps = (state) => ({
  expanded: state.filter.expanded,
  appliedFilters: state.filter.selected,
  totalResults: state.results.totalResults,
  loadingResults: state.results.loadingResults,
  appInitialized: state.facets.initialized,
});

const mapDispatchToProps = {
  expandFiltersAction: expandFilters,
  clearAllFiltersAction: clearAllFilters,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterContainer));
