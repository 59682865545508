import React, { Component } from 'react';
import PropTypes from 'prop-types';

// React Spectrum components
import {
  Button,
} from '@adobe/react-spectrum';

// I18n
import { Translate } from 'react-redux-i18n';

// CSS
import './css/ViewPhoneNumberButton.scss';

class ViewPhoneNumberButton extends Component {
  // Set initial state.
  constructor(props) {
    super(props);
    this.state = {
      showingPhone: false,
    };
  }

  /**
   * renderButtonContent
   * Returns either "Show Phone Number" or the actual phone number
   * depending on the state.
   */
  renderButtonContent = () => {
    const { showingPhone } = this.state;
    const { phoneNumber } = this.props;

    // If state is default, show message.
    if (!showingPhone) {
      return (
        <div className="phoneText">
          <Translate value="partner_finder.detail.show_phone" />
        </div>
      );
    }
    // Otherwise return the phone number.
    return <div className="phoneNumber">{phoneNumber}</div>;
  };

  /**
   * handleButtonPress
   * Handles the press event on a button.
   */
  handleButtonPress = () => {
    const { showingPhone } = this.state;
    const { phoneNumber } = this.props;

    // If state is default, set the new state.
    if (!showingPhone) {
      this.setState({ showingPhone: true });
    } else {
      // Default tel: behavior
      window.open(`tel:${phoneNumber}`);
    }
  };

  render() {
    return (
      <Button
        variant="primary"
        data-test-id="view-phonenumber-button"
        onPress={this.handleButtonPress}
        UNSAFE_className="cmp-viewPhoneButton"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.2 48.51">
          <path d="M45.03,40.68c-0.71-1.69-1.21-2.04-4.11-4.68c-2.07-1.89-3.15-2.74-4.51-3.88
     c-1.33-1.02-3.2-0.98-4.49,0.1l0,0l-2.99,2.4c-1.26,1.06-3.07,1.13-4.41,0.17c-2.46-1.94-4.76-4.09-6.84-6.43
     c-2.03-2.35-3.85-4.89-5.43-7.56c-0.75-1.42-0.35-3.17,0.94-4.12l2.99-2.4l0,0c1.33-0.97,1.71-2.79,0.89-4.21
     c-0.94-1.48-1.62-2.64-3.21-4.94c-2.22-3.19-2.49-3.72-4.11-4.68c-1.69-0.78-3.67-0.52-5.1,0.67l0,0C2.67,2.38,1.15,4.25,0.33,6.45
     c-1.65,6.99,2.98,17.71,10.99,27.02l0,0c8.2,9.15,18.43,15.37,25.91,15.03c2.36-0.37,4.54-1.47,6.24-3.14l0,0
     C44.91,44.28,45.54,42.41,45.03,40.68z"
          />
        </svg>
        {this.renderButtonContent()}
      </Button>
    );
  }
}

ViewPhoneNumberButton.propTypes = {
  phoneNumber: PropTypes.string,
};
ViewPhoneNumberButton.defaultProps = {
  phoneNumber: null,
};

export default ViewPhoneNumberButton;
