// Lodash
import _ from 'lodash';

// Constants
import {
  SET_PARTNER_DETAIL_DATA,
  PRELOADING_DETAIL,
  CLEAR_PRELOADING_DETAIL,
  SET_SCROLL_POSITION,
  SET_PREVIEW_MODE,
  CONTENT_SUPPLY_CHAIN,
} from '../constants';

// API
import api from '../api/directoryApi';

// Error Notification to the user
import notify from '../util/displayToastNotification';

/* eslint-disable import/prefer-default-export */
export const getPartnerDetail = (id, preload, router) => async (dispatch, getState) => {
  // Headers for the request.
  const headers = {
    'x-api-key': window.directoryConfig.adobeIO.apiKey,
  };

  // If we are in the preview mode.
  if (getState().partnerDetail?.preview) {
    // Define the fetch method to be called when ready.
    const fetchPreviewData = async () => {
      try {
        // Update our headers.
        _.set(headers, 'Authorization', window.adobeIMS.getAccessToken().token);

        // Make the API Call
        const result = await api.get(`/v1/spp/partner/listing/preview/${id}`, { headers });

        // Prepare the data
        const partnerDetailData = result.data;

        // Set the URL in the payload as well.
        _.set(partnerDetailData, 'companyInfo.listingUrl', id);

        // Dispatch the action to save it to the store.
        dispatch({
          type: SET_PARTNER_DETAIL_DATA,
          payload: partnerDetailData,
        });

        // Display Preview Mode Notification
        notify({
          type: 'info-static',
          textPath: 'partner_finder.preview_mode.viewing_preview',
        });
      } catch (err) {
        // If we get a 404.
        if (err.response && (err.response.status === 404 || err.response.status === 403
          || err.response.status === 400)) {
          // We should show the not found screen.
          dispatch({
            type: SET_PARTNER_DETAIL_DATA,
            payload: { error: 404 },
          });

          // Clear the preview mode flag.
          dispatch({
            type: SET_PREVIEW_MODE,
            payload: false,
          });
          window.sessionStorage.removeItem('mandatorySignIn');
        } else {
          // Notify the user
          notify({
            type: 'error',
            textPath: 'partner_finder.errors.get_partner_detail_api_failed',
            autoDismissable: false,
          });
        }

        // Log to the console.
        window.console.error('Error: Problem fetching partner detail data, API call failed.', err);
        // Tell New Relic about it
        if (window.newrelic) {
          window.newrelic.noticeError(
            err.message,
            {
              page: 'Detail Page',
              section: 'Partner Detail',
              api: `/v1/spp/listing/${id}`,
            },
          );
        }
      }
    };

    // Check if the user is signed in.
    if (!window.adobeIMS || !window.adobeIMS.isSignedInUser()) {
      // If they are not, wait for it to be ready.
      window.addEventListener('adobeIMS:profileReady', fetchPreviewData);
    } else {
      fetchPreviewData();
    }
  // Otherwise, fetch as normal.
  } else {
    if (preload) {
      dispatch({
        type: PRELOADING_DETAIL,
        payload: { id },
      });
    }

    try {
    // Make the API Call
      const result = await api.get(`/v1/spp/listing/${id}`, { headers });

      // Prepare the data
      const partnerDetailData = result.data;

      // Set the URL in the payload as well.
      _.set(partnerDetailData, 'companyInfo.listingUrl', id);

      const { accreditedSolutions } = partnerDetailData;
      const cscSoultion = [];
      const otherSolution = [];
      accreditedSolutions.forEach((item) => {
        if (item.status === CONTENT_SUPPLY_CHAIN) cscSoultion.push(item);
        else otherSolution.push(item);
      });

      _.set(partnerDetailData, 'accreditedSolutions', [...cscSoultion, ...otherSolution]);

      // Dispatch the action to save it to the store.
      dispatch({
        type: SET_PARTNER_DETAIL_DATA,
        payload: partnerDetailData,
      });

      // If we are preloading the data.
      if (preload) {
        // Clear preload spinner
        dispatch({
          type: CLEAR_PRELOADING_DETAIL,
          payload: { id },
        });
        // Remember the scroll position for when we return.
        dispatch({
          type: SET_SCROLL_POSITION,
          payload: window.pageYOffset,
        });
        // Navigate to the detail page.
        router.navigate(`/detail/${id}`);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
      // We should show the not found screen.
        dispatch({
          type: SET_PARTNER_DETAIL_DATA,
          payload: { error: 404 },
        });
      } else {
      // Notify the user
        notify({
          type: 'error',
          textPath: 'partner_finder.errors.get_partner_detail_api_failed',
          autoDismissable: false,
        });

        // Cancel the preload
        if (preload) {
          // Clear preload.
          dispatch({
            type: CLEAR_PRELOADING_DETAIL,
            payload: { id },
          });
        }
      }

      // Log to the console.
      window.console.error('Error: Problem fetching partner detail data, API call failed.', err);
      // Tell New Relic about it
      if (window.newrelic) {
        window.newrelic.noticeError(
          err.message,
          {
            page: 'Detail Page',
            section: 'Partner Detail',
            api: `/v1/spp/listing/${id}`,
          },
        );
      }
    }
  }
};
