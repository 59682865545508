// React Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// I18n
import { Translate } from 'react-redux-i18n';

// React Spectrum Components
import { ActionButton, Text } from '@adobe/react-spectrum';

// Icons
import ArrowLeft from '@spectrum-icons/ui/ArrowLeftMedium';

// React Router
import { withRouter } from './withRouter';

// Styles
import './css/ReturnToResultsButton.scss';

class ReturnToResultsButton extends Component {
  goBack = () => {
    const { router } = this.props;
    router.navigate('/');
  };

  render() {
    return (
      <ActionButton isQuiet data-test-id="return-to-btn" aria-label="Return to Results" onPress={this.goBack} UNSAFE_className="cmp-returnToResults">
        <ArrowLeft />
        <Text><Translate value="partner_finder.detail.back_to_results" /></Text>
      </ActionButton>
    );
  }
}
ReturnToResultsButton.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ReturnToResultsButton);
