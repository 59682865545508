// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// I18n
import { Translate, I18n } from 'react-redux-i18n';

// React Spectrum Components
import {
  Dialog, DialogTrigger,
  ActionButton,
  Content,
  Divider,
  Heading,
  ListBox,
  Item,
} from '@adobe/react-spectrum';

// Icons
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Close from '@spectrum-icons/workflow/Close';
import Info from '@spectrum-icons/workflow/InfoOutline';

// Animations
import { AnimatePresence, motion } from 'framer-motion';
import { LocationFilterAnimation } from '../util/FramerMotionConfig';

// React Router
import { withRouter } from './withRouter';

// Redux Actions
import { addSingleFilter, removeSingleFilter, removeMultipleFilters } from '../actions/filterActions';
import { getCountriesInGeo } from '../actions/facetsActions';

// Styles
import './css/FilterCommon.scss';
import './css/LocationFilter.scss';
import MultiSelectFilter from './MultiSelectFilter';

class LocationFilter extends Component {
  // Mobile global var.
  isMobile = false;

  constructor(props) {
    super(props);
    // Detect mobile on init.
    this.detectMobile();
    this.state = {
      dialogOpen: false,
    };
  }

  /**
   * Handle Selection
   * Handles the click of a listbox or dropdown menu item.
   * @param {String} type - the filter type (geo or country)
   * @param {Set} set - A JS Set of selected items.
   */
  handleSelection(type, set) {
    // Redux state and actions.
    const {
      addSingleFilterAction,
      getCountriesInGeoAction,
      appliedFilters,
      router,
    } = this.props;

    // Get first item in set.
    const iterator = set.values();
    const { value } = iterator.next();

    // The selection has not changed, don't do anything.
    if (value === undefined) {
      return false;
    }

    // If the user selects the "All Regions/Countries" default option.
    if (value === 'default') {
      // If they selected "All Regions" and already has a country selected.
      if (type === 'location.geo' && _.has(appliedFilters, 'country')) {
        // Clear both the geo and country filter.
        this.clearFilter([{ name: 'location' }, { name: 'physical-location' }]);
      } else if (type === 'location.geo') {
        this.clearFilter([{ name: 'location' }, { name: 'physical-location' }]);
      } else {
        // Clear what they wanted to clear.
        this.clearFilter([{ name: type }]);
      }
    } else {
      // Add the filter to the store
      addSingleFilterAction({
        name: type,
        value,
      }, router);
      // If we selected a geo
      if (type === 'location.geo') {
        /** Load countries if we haven't previously selected a region or the region
        is different than what is currently selected. */
        if (!_.has(appliedFilters, 'location.geo') || (_.has(appliedFilters, 'location.geo') && appliedFilters.location.geo !== value)) {
          getCountriesInGeoAction(value);
        }
        // If we have a country selected, clear it since we are loading new countries.
        if (_.has(appliedFilters, 'country')) {
          this.clearFilter([{ name: 'country' }, { name: 'physical-location' }]);
        }
      }
    }
    // We're good to close the dialog
    return true;
  }

  /**
   * Clear Filter
   * Clears a filter from the redux store and refreshes the component.
   * @param {String} filterName - The filter to clear, e.g. 'location.geo'
   */
  clearFilter(filter) {
    const { removeSingleFilterAction, removeMultipleFiltersAction, router } = this.props;

    if (Array.isArray(filter)) {
      removeMultipleFiltersAction(filter, router);
    } else {
      removeSingleFilterAction({
        name: filter,
      }, router);
    }
    this.forceUpdate();
  }

  /**
   * Detect Mobile
   * Detects the viewport and sets the global isMobile variable.
   */
  detectMobile() {
    // Detect if we are on a mobile device.
    const mql = window.matchMedia('(max-width: 600px)');
    this.isMobile = mql.matches;
  }

  /**
   * Render Geo Filter
   * Renders the region and country filter component.
   */
  renderGeoFilter() {
    const { facetData, appliedFilters } = this.props;
    const { dialogOpen } = this.state;
    const locationSelectedClass = _.has(appliedFilters, 'location.geo') ? 'selected selected-location' : '';

    // Add the "All Regions" option the start of our dataset.
    const items = _.clone(facetData.geos);
    items.unshift({ id: 'default', name: I18n.t('partner_finder.filters.all_regions') });

    // JSX for the "Location" button
    const locationButton = (
      <ActionButton zIndex={1} data-test-id="region-trigger" UNSAFE_className={`filterButton ${locationSelectedClass}`}>
        <Translate value="partner_finder.filters.location_label" />
        <div className="caret"><ChevronDown size="XS" /></div>
      </ActionButton>
    );

    // JSX for the "X" Clear button
    const clearLocationFilterButton = (
      <ActionButton zIndex={2} data-test-id="location-filter-clear" UNSAFE_className={`clearFilterButton ${locationSelectedClass}`} onPress={() => this.clearFilter([{ name: 'location' }, { name: 'physical-location' }])}>
        <div className="separator" />
        <div className="smallCaret"><ChevronDown size="XS" /></div>
        <div className="clearFilter"><Close size="XS" /></div>
      </ActionButton>
    );

    // If we are on mobile.
    if (this.isMobile) {
      // Render the dialog variant.
      return (
        <div className="locationFilterContainer">
          <DialogTrigger
            onOpenChange={
              (e) => {
                setTimeout(() => {
                  this.setState({ dialogOpen: e });
                }, 300);
              }
            }
            isDismissable
            data-test-id="location-filter-dialogTrigger"
          >
            {locationButton}
            {(close) => (
              <Dialog UNSAFE_className="filter-dialog-mobile">
                <Heading>
                  <Translate value="partner_finder.filters.location_label" />
                  <DialogTrigger type="popover">
                    <ActionButton isQuiet UNSAFE_className="multi-select-filter-description">
                      <Info aria-label="Information" size="S" />
                    </ActionButton>
                    <Dialog>
                      <Content UNSAFE_className="mobile-dialog-tooltip">
                        <Translate value="partner_finder.filters.geo_tooltip" className="multi-select-description-text" />
                      </Content>
                    </Dialog>
                  </DialogTrigger>
                </Heading>
                <Divider />
                <Content>
                  <ListBox
                    width="100%"
                    maxHeight="size-3400"
                    aria-label={I18n.t('partner_finder.filters.location_label')}
                    items={items}
                    selectionMode={!dialogOpen ? 'none' : 'single'}
                    selectedKeys={_.has(appliedFilters, 'location.geo') ? [appliedFilters.location.geo] : ['default']}
                    onSelectionChange={(geo) => {
                      if (this.handleSelection('location.geo', geo)) {
                        close();
                      }
                    }}
                  >
                    {(item) => <Item>{item.name}</Item>}
                  </ListBox>
                </Content>
              </Dialog>
            )}
          </DialogTrigger>
          {clearLocationFilterButton}
          <AnimatePresence>
            {this.renderCountryFilter()}
          </AnimatePresence>
        </div>
      );
    }
    // Otherwise, render the Dropdown Menu variant
    return (
      <div className="locationFilterContainer">
        <DialogTrigger
          isDismissable
          hideArrow={false}
          type="popover"
          placement="bottom left"
          data-test-id="location-filter-menuTrigger"
        >
          {locationButton}
          {(close) => (
            <Dialog UNSAFE_className="msf-dialog">
              <Heading>
                <Translate value="partner_finder.filters.location_label" />
                <DialogTrigger type="popover">
                  <ActionButton isQuiet UNSAFE_className="multi-select-filter-description">
                    <Info aria-label="Information" size="S" />
                  </ActionButton>
                  <Dialog>
                    <Content>
                      <Translate value="partner_finder.filters.geo_tooltip" className="multi-select-description-text" />
                    </Content>
                  </Dialog>
                </DialogTrigger>
              </Heading>
              <Divider UNSAFE_className="divider-short" />
              <Content>
                <ListBox
                  width="100%"
                  maxHeight="size-3400"
                  aria-label={I18n.t('partner_finder.filters.location_label')}
                  items={items}
                  selectionMode="single"
                  selectedKeys={_.has(appliedFilters, 'location.geo') ? [appliedFilters.location.geo] : ['default']}
                  onSelectionChange={(geo) => {
                    if (this.handleSelection('location.geo', geo)) {
                      close();
                    }
                  }}
                >
                  {(item) => <Item>{item.name}</Item>}
                </ListBox>
              </Content>
            </Dialog>
          )}
        </DialogTrigger>
        {clearLocationFilterButton}
        <AnimatePresence>
          {this.renderCountryFilter()}
        </AnimatePresence>
      </div>
    );
  }

  /**
   * Render Country Filter
   * Renders the country selection button.
   */
  renderCountryFilter() {
    const { facetData, appliedFilters } = this.props;
    // const { dialogOpen } = this.state;
    const isGeoSelected = _.has(appliedFilters, 'location.geo');
    const isCountrySelected = _.has(appliedFilters, 'country');
    // const countrySelectedClass = _.has(appliedFilters, 'country') ? 'selected' : '';

    // Add the "All Countries" option the start of our dataset.
    const items = _.clone(facetData.countries);
    items.unshift({ id: 'default', name: I18n.t('partner_finder.filters.all_countries') });

    if (isGeoSelected) {
      // Render Dropdown Variant
      return (
        <motion.div
          animate={isCountrySelected ? false : LocationFilterAnimation.animate}
          initial={isCountrySelected ? LocationFilterAnimation.animate
            : LocationFilterAnimation.initial}
          exit={LocationFilterAnimation.exit}
          transition={LocationFilterAnimation.transition}
          style={{ display: 'inline' }}
        >
          <MultiSelectFilter
            filterName="country"
            nameTranslationPath="partner_finder.filters.countries_label"
          />
        </motion.div>
      );
    }
    return null;
  }

  // Render the component.
  render() {
    return (
      <div className="cmp-locationFilter" id="location-filter">
        {this.renderGeoFilter()}
      </div>
    );
  }
}

LocationFilter.propTypes = {
  /**
   * facetData {Object} - An object provided by mapStateToProps
   * that contains geo/country facet options.
   */
  facetData: PropTypes.shape({
    geos: PropTypes.arrayOf(PropTypes.object),
    countries: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  /**
   * appliedFilters {Object} - An object provided by mapStateToProps
   * that contains the selected filter data.
   */
  appliedFilters: PropTypes.shape({
    location: PropTypes.shape({
      geo: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
  /**
   * getCountriesInGeoAction {Function} - A function provided by mapDispatchToProps
   * that gets the countries for a particular geo.
   */
  getCountriesInGeoAction: PropTypes.func.isRequired,
  /**
   * addSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that adds a filter to the redux store.
   */
  addSingleFilterAction: PropTypes.func.isRequired,
  /**
   * removeSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that removes a filter from the redux store.
   */
  removeSingleFilterAction: PropTypes.func.isRequired,
  /**
   * removeMultipleFiltersAction {Function} - A function provided by mapDispatchToProps
   * that removes multiple filter from the redux store.
   */
  removeMultipleFiltersAction: PropTypes.func.isRequired,
  /**
   * router {Object} - An object provided by React Router that allows navigation.
   */
  router: PropTypes.shape({}).isRequired,
};
LocationFilter.defaultProps = {
  appliedFilters: {},
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  facetData: state.facets.location,
  appliedFilters: state.filter.selected,
});

const mapDispatchToProps = {
  addSingleFilterAction: addSingleFilter,
  removeSingleFilterAction: removeSingleFilter,
  removeMultipleFiltersAction: removeMultipleFilters,
  getCountriesInGeoAction: getCountriesInGeo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationFilter));
