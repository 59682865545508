/* eslint-disable react/no-unstable-nested-components */
// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, isDirty } from 'redux-form';
import PropTypes from 'prop-types';

// React Spectrum Components
import {
  Button,
  Flex,
  Form,
  Item,
  Picker,
  TextArea,
  TextField,
} from '@adobe/react-spectrum';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// Recaptcha
import ReCaptcha from 'react-google-recaptcha';

// Error Notification to the user
import notify from '../util/displayToastNotification';

// Util
import { countriesList } from '../util/util';

// Styles
import './css/ContactPartnerForm.scss';

class ContactPartnerForm extends Component {
  constructor(props) {
    super(props);
    // Recaptcha Ref
    this.recaptchaRef = React.createRef();
  }

  // return spectrum validation css styling
  // eslint-disable-next-line class-methods-use-this
  checkValidationState = (value, meta) => {
    // Valid
    if (value && !meta.error) {
      return 'valid';
    }
    // Initial State
    if (meta.error && !meta.touched) {
      return null;
    }
    // Invalid
    if ((!value && meta.touched) || meta.error) {
      return 'invalid';
    }
    // Nothing
    return null;
  };

  // spectrum component for the form inputs
  spectrumTextField = ({
    label,
    input,
    meta,
  }) => (
    <TextField
      width="100%"
      flex
      label={label}
      placeholder={label}
      value={input.value}
      onChange={input.onChange}
      validationState={this.checkValidationState(input.value, meta)}
      isRequired
      necessityIndicator="icon"
      maxLength={255}
    />
  );

  // spectrum component for the country dropdown menu
  spectrumSelectField = ({
    label,
    input,
    meta,
    items,
  }) => (
    <Picker
      width="100%"
      flex
      label={label}
      items={items}
      selectedKey={input.value}
      onSelectionChange={input.onChange}
      validationState={this.checkValidationState(input.value, meta)}
      isRequired
      necessityIndicator="icon"
    >
      {(item) => <Item key={item.id}>{item.name}</Item>}
    </Picker>
  );

  // Field for Solution selection
  spectrumSolutionSelectField = ({
    label,
    input,
    meta,
    items,
  }) => (
    <Picker
      width="100%"
      flex
      label={label}
      items={items}
      selectedKey={input.value}
      onSelectionChange={input.onChange}
      validationState={this.checkValidationState(input.value, meta)}
      isRequired
      necessityIndicator="icon"
    >
      {(item) => <Item key={item.name}>{item.name}</Item>}
    </Picker>
  );

  // spectrum component for the partner message
  spectrumTextArea = ({
    label,
    input,
    meta,
  }) => (
    <TextArea
      minWidth="100%"
      flex
      maxLength={2000}
      label={label}
      placeholder={label}
      value={input.value}
      onChange={input.onChange}
      validationState={this.checkValidationState(input.value, meta)}
      isRequired
      necessityIndicator="icon"
    />
  );

  /**
   * renderContactPartnerForm
   * render the contact partner form component
   */
  renderContactPartnerForm = () => {
    const {
      handleSubmit,
      partnerName,
      solution,
      formIsDirty,
      previewMode,
    } = this.props;

    /**
     * executeRecaptcha
     * Executes recaptcha, and calls the submit functionality if it succeeds.
     * @param {Object} e - events object.
     * @param {Ref} ref - reference to reCaptcha.
     */
    const executeRecaptcha = async (e, ref) => {
      // Prevent the page from reloading.
      e.preventDefault();

      // Try to get a recaptcha token.
      try {
        const token = await ref.current.executeAsync();

        // If we get a token, submit the request.
        if (token) {
          handleSubmit();
        }
      } catch (err) {
        // Run validation
        handleSubmit();

        // There was a problem, notify the user.
        notify({
          type: 'error',
          textPath: 'partner_finder.errors.submit_contact_partner_form_api_failed',
          autoDismissable: true,
        });

        // Log the error
        if (window.newrelic) {
          window.newrelic.noticeError(
            err.message,
            {
              page: 'Detail View',
              section: 'Contact Form - Recaptcha',
              api: '/v1/spp/partnerlead',
            },
          );
        }
      }
    };

    // JSX for partnerName and partnerMessage section
    const formTitleSection = (
      <div className="form-title-section">
        <div className="partnerName">
          <Translate value="partner_finder.contactForm.contact_partner" partnerName={partnerName} />
        </div>
      </div>
    );

    // JSX for the partner contact form section
    const contactPartnerForm = (
      <Form onSubmit={(e) => executeRecaptcha(e, this.recaptchaRef)}>
        <Flex UNSAFE_className="form-container" gap="size-300" direction="column">
          <Flex gap="size-400" width="100%" UNSAFE_className="contact-field-container">
            <Field
              label={I18n.t('partner_finder.contactForm.firstName')}
              name="firstName"
              component={this.spectrumTextField}
            />
            <Field
              label={I18n.t('partner_finder.contactForm.lastName')}
              name="lastName"
              component={this.spectrumTextField}
            />
          </Flex>
          <Flex gap="size-400" width="100%" UNSAFE_className="contact-field-container">
            <Field
              label={I18n.t('partner_finder.contactForm.email')}
              name="email"
              component={this.spectrumTextField}
            />
            <Field
              label={I18n.t('partner_finder.contactForm.phoneNumber')}
              name="phone"
              component={this.spectrumTextField}
            />
          </Flex>
          <Flex gap="size-400" width="100%" UNSAFE_className="contact-field-container">
            <Field
              label={I18n.t('partner_finder.contactForm.company')}
              name="company"
              component={this.spectrumTextField}
            />
            <Field
              label={I18n.t('partner_finder.contactForm.country')}
              name="country"
              component={this.spectrumSelectField}
              items={countriesList}
            />
          </Flex>
          <Flex UNSAFE_className="flex-wrapper contact-field-container" gap="size-400" width="100%">
            <Field
              label={I18n.t('partner_finder.contactForm.jobRole')}
              name="jobRole"
              component={this.spectrumTextField}
            />
            <Field
              label={I18n.t('partner_finder.contactForm.solutionOfInterest')}
              name="solution"
              component={this.spectrumSolutionSelectField}
              items={solution}
            />
          </Flex>
          <Field
            label={I18n.t('partner_finder.contactForm.message')}
            name="message"
            component={this.spectrumTextArea}
          />
        </Flex>
        <Field
          name="captcharesponse"
          component={(props) => (
            <div>
              <ReCaptcha
                ref={this.recaptchaRef}
                size="invisible"
                sitekey={window.recaptchaOptions?.sitekey}
                onChange={props.input.onChange}
              />
              {this.checkValidationState(props.input.value, props.meta) === 'invalid' ? props.meta.error : null}
            </div>
          )}
        />
        <div className="form-submit-button">
          <Button
            UNSAFE_className="send-message-partner-button"
            variant="cta"
            type="submit"
            isDisabled={!formIsDirty || previewMode}
          >
            { previewMode ? <Translate value="partner_finder.contactForm.disabled_preview" />
              : <Translate value="partner_finder.contactForm.send_message" />}
          </Button>
        </div>
        <div className="recaptcha-tos">
          Protected by reCAPTCHA and subject to the Google
          {' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
          {' '}
          and
          {' '}
          <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a>
          .
        </div>
      </Form>
    );

    return (
      <>
        {formTitleSection}
        {contactPartnerForm}
      </>
    );
  };

  render() {
    return (
      <div className="contact-partner-form-container" id="contact">
        <div className="contact-partner-form">
          {this.renderContactPartnerForm()}
        </div>
      </div>
    );
  }
}

ContactPartnerForm.propTypes = {
  /**
   * handleSubmit {Function} - A function provided by redux-form
   * handles the form submit
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * partnerName {String} - A string provided by the FilterPage
   * name of the partner
   */
  partnerName: PropTypes.string,
  /**
   * solution {Object} - An object provided by mapStateToProps
   * that contains solution facet options.
   */
  solution: PropTypes.arrayOf(PropTypes.object),
  formIsDirty: PropTypes.bool.isRequired,
  previewMode: PropTypes.bool.isRequired,
};

ContactPartnerForm.defaultProps = {
  partnerName: null,
  solution: [],
};

// redux form validator
const formValidator = (values) => {
  const isEmptyOrSpaces = (str) => !str || str.match(/^\s*$/) !== null;

  const errors = {};

  if (isEmptyOrSpaces(values.firstName)) {
    errors.firstName = <Translate value="partner_finder.contactForm.error_firstName" />;
  }

  if (isEmptyOrSpaces(values.lastName)) {
    errors.lastName = <Translate value="partner_finder.contactForm.error_lastName" />;
  }

  if (isEmptyOrSpaces(values.email)) {
    errors.email = <Translate value="partner_finder.contactForm.error_email" />;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = <Translate value="partner_finder.contactForm.invalid_email" />;
  }

  if (isEmptyOrSpaces(values.phone)) {
    errors.phone = <Translate value="partner_finder.contactForm.error_phoneNumber" />;
  } else if (
    !/^[^-\sA-Za-z](\(?\+?[0-9]*\)?)?[0-9_\- ()]*(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
      values.phone,
    )
  ) {
    errors.phone = <Translate value="partner_finder.contactForm.invalid_phoneNumber" />;
  }

  if (!values.country) {
    errors.country = <Translate value="partner_finder.contactForm.error_country" />;
  }

  if (isEmptyOrSpaces(values.company)) {
    errors.company = <Translate value="partner_finder.contactForm.error_company" />;
  }

  if (isEmptyOrSpaces(values.jobRole)) {
    errors.jobRole = <Translate value="partner_finder.contactForm.error_jobRole" />;
  }

  if (!values.solution) {
    errors.solution = <Translate value="partner_finder.contactForm.error_solution" />;
  }

  if (isEmptyOrSpaces(values.message)) {
    errors.message = <Translate value="partner_finder.contactForm.error_message" />;
  }

  if (!values.captcharesponse) {
    errors.captcharesponse = <Translate value="partner_finder.contactForm.error_recaptcha" />;
  }

  return errors;
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  formIsDirty: isDirty('contactForm')(state),
  previewMode: state.partnerDetail.preview,
});

export default connect(mapStateToProps, null)(
  reduxForm({
    form: 'contactForm',
    validate: formValidator,
  })(ContactPartnerForm),
);
