// React Imports
import React from 'react';

// I18n
import { Translate } from 'react-redux-i18n';

// Styles
import './css/ContactBlade.scss';

const ContactBlade = () => (
  <div className="contentBladeContainer">
    <h3>
      <Translate value="partner_finder.content_blade.update_create_profile" />
    </h3>
    <p>
      <Translate value="partner_finder.content_blade.top_lead_generators" />
    </p>
    <p>
      <a href={window.directoryConfig.learnMoreLink} target="_blank" rel="noreferrer">
        <Translate value="partner_finder.content_blade.learn_about_managing_listing" />
      </a>
      <Translate value="partner_finder.content_blade.how_to_manage" />
    </p>
    <p>
      <Translate value="partner_finder.content_blade.not_a_partner" />
      <a href={window.directoryConfig.joinTodayLink} target="_blank" rel="noreferrer">
        <Translate value="partner_finder.content_blade.join_today" />
      </a>
    </p>
  </div>
);

export default ContactBlade;
