/* eslint-disable react/jsx-no-useless-fragment */
// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// i18n
import { Translate } from 'react-redux-i18n';

// React Spectrum Components
import {
  ActionButton,
  Divider,
  Flex,
} from '@adobe/react-spectrum';
import Ribbon from '@spectrum-icons/workflow/Ribbon';
import Settings from '@spectrum-icons/workflow/Settings';
import UserGroup from '@spectrum-icons/workflow/UserGroup';
import Education from '@spectrum-icons/workflow/Education';

// React Router
import { withRouter } from './withRouter';

// Icons denoting partner level
import BronzeLevel from '../util/partnerIcons/BronzeLevel';
import SilverLevel from '../util/partnerIcons/SilverLevel';
import GoldLevel from '../util/partnerIcons/GoldLevel';
import PlatinumLevel from '../util/partnerIcons/PlatinumLevel';

import OpenURLButton from './OpenURLButton';
import ViewPhoneNumberButton from './ViewPhoneNumberButton';
import ContactPartnerButton from './ContactPartnerButton';

// Utils
import { addCommaSeparator } from '../util/util';

// Styles
import './css/PartnerInfoCard.scss';

class PartnerInfoCard extends Component {
  // Constructor
  constructor(props) {
    super(props);
    // State used to maintain badges
    this.state = {
      badge: null,
    };
  }

  componentDidMount() {
    const { partnerDetail } = this.props;

    // if the redux store is in-tact, change color based on the accent color
    if (_.has(partnerDetail, 'companyInfo.accentColor')
      && _.has(partnerDetail, 'companyInfo.logoUrl')) {
      this.getAccentColor();
      this.setPartnerLogo();
    }
    if (_.has(partnerDetail, 'resources.Badge')) {
      this.getBadge('Badge');
    }
    if (partnerDetail.resources?.['CSC Badge']) {
      this.getBadge('CSC Badge');
    }
  }

  componentDidUpdate(prevProps) {
    const { partnerDetail } = this.props;

    // if the redux store isn't ready, wait for the data load then change icon color
    if (_.has(partnerDetail, 'companyInfo.accentColor')
    && _.has(partnerDetail, 'companyInfo.logoUrl')) {
      this.getAccentColor();
      this.setPartnerLogo();
    }
    // check if badges are present to get the badge
    if (prevProps.partnerDetail !== partnerDetail) {
      this.getBadge('Badge');
      this.getBadge('CSC Badge');
    }
  }

  /**
   * Get Accent Color
   * change the accent color at the top of the logo container based on partner's selection
   */
  getAccentColor = () => {
    const { partnerDetail } = this.props;
    const accentColorBar = document.querySelector('.accent-color');
    if (accentColorBar) {
      accentColorBar.style.backgroundColor = partnerDetail.companyInfo.accentColor;
    }
  };

  /**
   * Set Partner Logo
   * set the partner logo based on partner's selection
   */
  setPartnerLogo = () => {
    const { partnerDetail } = this.props;
    const logo = document.querySelector('.partner-logo');
    if (logo) {
      logo.style.backgroundImage = `url(${partnerDetail.companyInfo.logoUrl || ''})`;
    }
  };

  /**
   * get the badge from the list of badges that the partner has obtained in the current year
   */
  getBadge = (type) => {
    const { partnerDetail } = this.props;

    // get the current year
    const currentYear = new Date().getFullYear();
    // filter the list of badges, to find the badge that was obtained in the current year
    const badgeList = partnerDetail?.resources?.[type]?.filter(
      (item) => +item.year === currentYear,
    );
    // set the badge to the state
    if (type === 'CSC Badge') {
      this.setState({
        cscBadge: badgeList?.[0] || {},
      });
    } else {
      this.setState({
        badge: badgeList?.[0] || {},
      });
    }
  };

  /**
   * Render Partner Info Card
   * renders the Partner Info Card component
   */
  renderPartnerInfoCard = () => {
    const { partnerDetail, switchTab } = this.props;
    const { badge, cscBadge } = this.state;
    /**
     *
     * @param {*} deploymentCount  : deployment count from company info
     * @param {*} referenceCount : referenceCount count from company info
     * @returns sum of both counts to display deployment count
     */
    // const getDeploymentCount = (deploymentCount, referenceCount) => {
    //   let netCount = 0;

    //   if (deploymentCount > 0) {
    //     netCount += deploymentCount;
    //   }
    //   if (referenceCount > 0) {
    //     netCount += referenceCount;
    //   }

    //   return netCount;
    // };
    // JSX for the mobile view
    const mobileInfo = (
      <div className="mobile-info-container">
        <Divider size="S" />
        <div className="mobile-info">
          <div className="partner-name">
            {partnerDetail.companyInfo.name}
          </div>
          <div className="partner-location">
            {partnerDetail.companyInfo.location}
          </div>
          <div className="button-group">
            <ContactPartnerButton scrollTargetId="contact" />
            <OpenURLButton
              variant="primary"
              translatePath="partner_finder.detail.visit_website"
              websiteUrl={partnerDetail.companyInfo.website}
              target="_blank"
            />
            <div className="break">
              <ViewPhoneNumberButton phoneNumber={partnerDetail.companyInfo.phone} />
            </div>
          </div>
        </div>
      </div>
    );

    /**
     * Render Partner Level Icon
     * renders the partner level icon
     * @param {*} partnerLevel - partner level (bronze, silver, gold or platinum)
     */
    const renderPartnerLevelIcon = (partnerLevel) => {
      switch (partnerLevel) {
        case 'Bronze':
          return (
            <div className="bronzeIcon badgeIcon">
              <BronzeLevel />
              <Translate value="partner_finder.detail.bronze_partner" />
            </div>
          );
        case 'Silver':
          return (
            <div className="silverIcon badgeIcon">
              <SilverLevel />
              <Translate value="partner_finder.detail.silver_partner" />
            </div>
          );
        case 'Gold':
          return (
            <div className="goldIcon badgeIcon">
              <GoldLevel />
              <Translate value="partner_finder.detail.gold_partner" />
            </div>
          );
        case 'Platinum':
        case 'Platinum (Regional)':
          return (
            <div className="platinumIcon badgeIcon">
              <PlatinumLevel />
              <Translate value="partner_finder.detail.platinum_partner" />
            </div>
          );
        default:
          return (
            <div className="defaultIcon badgeIcon">
              <Translate value="partner_finder.detail.partner" />
            </div>
          );
      }
    };

    /**
     * Render Partner Logo Letters
     * renders the first letter of the partner's name in place of the logo.
     */
    const renderLogoLetter = () => {
      if (_.has(partnerDetail, 'companyInfo.logoUrl')) {
        if (!partnerDetail.companyInfo.logoUrl) {
          return partnerDetail.companyInfo.name[0];
        }
      }
      return null;
    };

    // JSX for the partner info top section (partner logo and partner level)
    const partnerInfoTopSection = (
      <>
        <div className="accent-color" />
        <div className="info-top-section">
          <div className="partner-logo-container">
            <div className="partner-logo">
              {renderLogoLetter()}
            </div>
          </div>
          {mobileInfo}
          <Divider size="S" />
          <div className="partner-level">
            {renderPartnerLevelIcon(partnerDetail.companyInfo.level)}
          </div>
        </div>
      </>
    );

    // JSX for the partner badge section
    const partnerBadgeSection = (
      <a href={window.directoryConfig.partnerAwards} target="_blank" rel="noreferrer">
        <div
          className="badge-section"
        >
          <div
            style={{
              backgroundImage: `url(${badge?.link})`,
            }}
            className="badge-image"
          />
        </div>
      </a>
    );

    // JSX for the partner badge section
    const partnerCSCBadgeSection = (
      <a href={window.directoryConfig.cscInfoLink} target="_blank" rel="noreferrer">
        <div className="badge-section">
          {cscBadge?.link?.endsWith('svg') ? <img src={cscBadge?.link} alt="Adobe CSC Logo" className="badge-image" />
            : (
              <div
                style={{
                  backgroundImage: `url(${cscBadge?.link})`,
                }}
                className="badge-image"
              />
            )}
        </div>
      </a>
    );

    const goToDeployments = () => {
      if (window.location.hash === '#expertise#deployments') {
        const headerOffset = 150;
        const elementPosition = document.getElementById('deploymentSection').getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      } else {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.updateCurrentTab();
        // If we have replaceState available to us, set the hash that way.
        if (window.history.replaceState) {
        // This will not add a new history entry.
          window.history.replaceState(null, null, '#expertise#deployments');
        } else {
        // Otherwise use the old way of doing it.
          window.location.hash = '#expertise#deployments';
        }
      }
    };

    // JSX for the partner info bottom section (specializations and certifications)
    const partnerInfoDeploymentSection = (
      <>
        {partnerDetail.totalCustomerDeploymentCount > 0 && (
          <div className="info-deployment-section">
            <ActionButton
              isQuiet
              onPress={() => goToDeployments()}
              UNSAFE_className="deployment-button"
            >
              <Flex alignItems="center" wrap justifyContent="center">
                <div className="deployments">
                  <Settings UNSAFE_style={{ paddingLeft: '0px', paddingRight: '4px' }} />
                  <div className="deployment-number">
                    {partnerDetail.totalCustomerDeploymentCount}
                  </div>
                </div>
                <Translate value="partner_finder.detail.customerDeployment" />
              </Flex>
            </ActionButton>
          </div>
        )}
      </>

    );

    // JSX for the partner info bottom section (specializations and certifications)
    const partnerInfoBottomSection = (
      <>
        {(_.get(partnerDetail.companyInfo, 'certifiedEmployees') > 0
        || _.get(partnerDetail.companyInfo, 'credentials') > 0
        || _.get(partnerDetail.companyInfo, 'specializations') > 0
        || _.get(partnerDetail.companyInfo, 'certifications') > 0) && (
          <div className="info-bottom-section">
            {(_.get(partnerDetail.companyInfo, 'certifiedEmployees') > 0
               || _.get(partnerDetail.companyInfo, 'certifications') > 0)
              && (
              <div className="info-bottom-section-top">
                {_.get(partnerDetail.companyInfo, 'certifiedEmployees') > 0 && (
                <ActionButton
                  isQuiet
                  data-test-id="certifiedEmployees-container"
                  UNSAFE_className="certifiedEmployees-container"
                  onPress={switchTab}
                >
                  <div className="certifiedEmployees">
                    <UserGroup />
                    <div className="certifiedEmployees-number">
                      {addCommaSeparator(partnerDetail.companyInfo.certifiedEmployees)}
                    </div>
                  </div>
                  <Translate value="partner_finder.detail.certifiedEmployees" />
                </ActionButton>
                )}
                {_.get(partnerDetail.companyInfo, 'certifiedEmployees') > 0
              && _.get(partnerDetail.companyInfo, 'certifications') > 0
              && <Divider orientation="vertical" size="S" />}
                {_.get(partnerDetail.companyInfo, 'certifications') > 0 && (
                <ActionButton
                  isQuiet
                  UNSAFE_className="certifications-container"
                  data-test-id="certifications-container"
                  onPress={switchTab}
                >
                  <div className="certification">
                    <Ribbon />
                    <div className="certification-number">
                      {addCommaSeparator(partnerDetail.companyInfo.certifications)}
                    </div>
                  </div>
                  <Translate value="partner_finder.detail.certifications" />
                </ActionButton>
                )}
              </div>
              )}
            {((_.get(partnerDetail.companyInfo, 'certifiedEmployees') > 0
               || _.get(partnerDetail.companyInfo, 'certifications') > 0)
               && (_.get(partnerDetail.companyInfo, 'specializations') > 0
               || _.get(partnerDetail.companyInfo, 'credentials') > 0))
              && <Divider orientation="horizontal" size="S" UNSAFE_style={{ margin: '12px' }} />}
            {(_.get(partnerDetail.companyInfo, 'specializations') > 0
               || _.get(partnerDetail.companyInfo, 'credentials') > 0)
              && (
              <div className="info-bottom-section-bottom">
                {_.get(partnerDetail.companyInfo, 'specializations') > 0 && (
                <ActionButton
                  isQuiet
                  UNSAFE_className="specialization-container"
                  data-test-id="specialization-container"
                  onPress={switchTab}
                >
                  <div className="specialization">
                    <Education />
                    <div className="specialization-number">
                      {addCommaSeparator(partnerDetail.companyInfo.specializations)}
                    </div>
                  </div>
                  <Translate value="partner_finder.detail.specializations" />
                </ActionButton>
                )}
                {_.get(partnerDetail.companyInfo, 'specializations') > 0
              && _.get(partnerDetail.companyInfo, 'credentials') > 0
              && <Divider orientation="vertical" size="S" />}
                {_.get(partnerDetail.companyInfo, 'credentials') > 0 && (
                <ActionButton
                  isQuiet
                  UNSAFE_className="credentials-container"
                  data-test-id="credentials-container"
                  onPress={switchTab}
                >
                  <div className="credentials">
                    <Ribbon />
                    <div className="credentials-number">
                      {addCommaSeparator(partnerDetail.companyInfo.credentials)}
                    </div>
                  </div>
                  <Translate value="partner_finder.detail.credentials" />
                </ActionButton>
                )}
              </div>
              )}
          </div>

        )}
      </>
    );

    return (
      <div className="PartnerInfoCard">
        {partnerInfoTopSection}
        {badge?.link && partnerBadgeSection}
        {cscBadge?.link && partnerCSCBadgeSection}
        {partnerInfoBottomSection}
        {partnerInfoDeploymentSection}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderPartnerInfoCard()}
      </>
    );
  }
}

PartnerInfoCard.propTypes = {
  /**
   * switchTab {Function} - A function provided by Detail Page
   * Clicking on either "Specializations" or "Certifications"
   * should change tabs to the "Expertise" tab.
   */
  switchTab: PropTypes.func,
  updateCurrentTab: PropTypes.func,
  /**
   * partnerDetail {Object} - An object provided by mapStateToProps
   * that contains detail information of the partner
   */
  partnerDetail: PropTypes.shape({
    companyInfo: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.string,
      phone: PropTypes.string,
      website: PropTypes.string,
      heroImageUrl: PropTypes.string,
      logoUrl: PropTypes.string,
      accentColor: PropTypes.string,
      level: PropTypes.string,
      certifiedEmployees: PropTypes.number,
      credentials: PropTypes.number,
      specializations: PropTypes.number,
      certifications: PropTypes.number,
    }),
    resources: PropTypes.shape({
      Badge: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        crmRecordId: PropTypes.string,
        year: PropTypes.string,
      })),
      'CSC Badge': PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        crmRecordId: PropTypes.string,
        year: PropTypes.string,
      })),
    }),
    totalCustomerDeploymentCount: PropTypes.number,
  }),
};

PartnerInfoCard.defaultProps = {
  partnerDetail: {},
  switchTab: null,
  updateCurrentTab: null,
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  partnerDetail: state.partnerDetail,
});

export default withRouter(connect(mapStateToProps, null)(PartnerInfoCard));
