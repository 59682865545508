// React
import React from 'react';
import PropTypes from 'prop-types';

// React Spectrum
import {
  Grid,
  View,
} from '@adobe/react-spectrum';

// I18n
import { Translate } from 'react-redux-i18n';

// Framer Motion
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { DetailTabAnimation } from '../../../util/FramerMotionConfig';

// Styles
import '../../css/CompanyInfoTab.scss';

/**
 * stripAndCreateMarkup
 * Strips all image and script tags from the string and returns an object for display.
 * @param {String} markup - The HTML string
 */
const stripAndCreateMarkup = (markup) => {
  // Most secure way to find all elements.
  const container = document.createElement('div');
  container.innerHTML = markup;

  // Find all script and image tags.
  const images = container.getElementsByTagName('img');
  const scripts = container.getElementsByTagName('script');

  // Remove them.
  while (images[0]) { images[0].parentNode.removeChild(images[0]); }
  while (scripts[0]) { scripts[0].parentNode.removeChild(scripts[0]); }

  return { __html: container.innerHTML };
};

const CompanyInfoTab = ({
  shortDescription, globalParentId, longDescription, companyName, globalParentName,
}) => (
  <motion.div
    initial={DetailTabAnimation.initial}
    animate={DetailTabAnimation.animate}
    exit={DetailTabAnimation.exit}
    transition={DetailTabAnimation.transition}
  >
    <Grid UNSAFE_className="tab-content-container">
      <View gridArea="tab-content" UNSAFE_className="tab-content">
        <div className="company-info-tab">
          <h2>
            <Translate value="partner_finder.detail.company_info_tab.about" />
          </h2>
          {globalParentName !== null && globalParentId ? (
            <div className="subsidiary">
              <span className="subsidiaryCompanyName">{companyName}</span>
              <Translate
                value="partner_finder.detail.company_info_tab.subsidiary"
              />
              <span className="subsidiaryCompanyName"><Link to={`/detail/${globalParentId}`}>{globalParentName}</Link></span>
            </div>
          ) : ''}
          {globalParentName !== null && !globalParentId ? (
            <div className="subsidiary">
              <span className="subsidiaryCompanyName">{companyName}</span>
              <Translate
                value="partner_finder.detail.company_info_tab.subsidiary"
              />
              <span className="subsidiaryCompanyName">{globalParentName}</span>
            </div>
          ) : ''}
          <div className="shortDescription">
            {shortDescription}
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="longDescription" dangerouslySetInnerHTML={stripAndCreateMarkup(longDescription)} />
        </div>
      </View>
    </Grid>
  </motion.div>
);
CompanyInfoTab.propTypes = {
  shortDescription: PropTypes.string,
  longDescription: PropTypes.string,
  companyName: PropTypes.string,
  globalParentName: PropTypes.string,
  globalParentId: PropTypes.string,
};
CompanyInfoTab.defaultProps = {
  shortDescription: null,
  longDescription: null,
  companyName: null,
  globalParentName: null,
  globalParentId: null,
};

export default CompanyInfoTab;
