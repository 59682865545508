// React
import React from 'react';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// React Spectrum
import {
  Grid,
  View,
  Divider,
  Flex,
} from '@adobe/react-spectrum';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// Framer Motion
import { motion } from 'framer-motion';
import { DetailTabAnimation } from '../../../util/FramerMotionConfig';

// Content Card
import ContentCard from '../../ContentCard';

// Styles
import '../../css/TabCommon.scss';
import '../../css/ServicesIndustriesTab.scss';

const ServicesIndustriesTab = ({ services, industries }) => {
  /**
   * renderIndustriesSection
   * Renders the industries section.
   */
  const renderIndustriesSection = () => {
    // If we have industries to show.
    if (_.size(industries) > 0) {
      // Create list items.
      const listItems = industries.map((item) => <li key={item}><div className="industry-list-item">{item}</div></li>);

      // Return the HTML
      return (
        <div className="industries-section">
          <h2><Translate value="partner_finder.detail.services_industries_tab.industries_header" /></h2>
          <ul className="industries">
            {listItems}
          </ul>
          {_.size(services) > 0 ? <Divider UNSAFE_className="divider" size="S" /> : null }
        </div>
      );
    }
    return null;
  };

  /**
   * renderServicesSection
   * Renders the services section.
   */
  const renderServicesSection = () => {
    // If we have services
    if (_.size(services) > 0) {
      // Create the list of cards.
      const cards = services.map((card) => (
        <ContentCard
          key={card.name}
          header={card.name}
          description={card.description}
          linkUrl={card.website}
          linkText={card.website ? I18n.t('partner_finder.detail.services_industries_tab.learn_more') : null}
          cardWidth="2"
        />
      ));

      return (
        <div className="industries-section services-section">
          <h2><Translate value="partner_finder.detail.services_industries_tab.services_header" /></h2>
          <Flex gap="35px" wrap>
            {cards}
          </Flex>
        </div>
      );
    }
    return null;
  };

  return (
    <motion.div
      initial={DetailTabAnimation.initial}
      animate={DetailTabAnimation.animate}
      exit={DetailTabAnimation.exit}
      transition={DetailTabAnimation.transition}
    >
      <Grid UNSAFE_className="tab-content-container">
        <View gridArea="tab-content" UNSAFE_className="tab-content">
          {renderIndustriesSection()}
          {renderServicesSection()}
        </View>
      </Grid>

    </motion.div>
  );
};
ServicesIndustriesTab.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  industries: PropTypes.arrayOf(PropTypes.string),
};
ServicesIndustriesTab.defaultProps = {
  services: [],
  industries: [],
};

export default ServicesIndustriesTab;
