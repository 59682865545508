/* eslint-disable react/jsx-no-useless-fragment */
// React Imports
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

// I18n
import { I18n, Translate } from 'react-redux-i18n';

// React Spectrum Components
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Close from '@spectrum-icons/workflow/Close';
import Info from '@spectrum-icons/workflow/InfoOutline';
import {
  ActionButton,
  Button,
  ButtonGroup,
  Checkbox,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  ProgressCircle,
  Switch,
} from '@adobe/react-spectrum';

import Analytics from './Analytics';

// React Router
import { withRouter } from './withRouter';

// Redux Actions
import {
  addSingleFilter,
  removeSingleFilter,
  getPreviewMatches,
} from '../actions/filterActions';

// Utils
import { addCommaSeparator } from '../util/util';

// Styles
import './css/FilterCommon.scss';
import './css/MultiSelectFilter.scss';

class MultiSelectFilter extends React.Component {
  // Mobile global var.
  isMobile = false;

  constructor(props) {
    super(props);
    // Detect mobile on init.
    this.detectMobile();
    const { appliedFilters, filterName } = this.props;
    this.state = {
      // Temporary list of the selected options
      selected: _.has(appliedFilters, filterName) ? appliedFilters[filterName] : [],
      initialDataLoaded: _.has(appliedFilters, filterName),
      dialogOpen: !this.isMobile,
    };
  }

  // if redux state becomes empty by another action outside of the component's scope,
  // clear local state
  componentDidUpdate(prevProps) {
    const { appliedFilters, filterName } = this.props;

    if ((prevProps.appliedFilters !== appliedFilters && _.size(appliedFilters) === 0)
    || (filterName === 'country' && prevProps.appliedFilters.location?.geo !== appliedFilters.location?.geo)
    ) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ selected: [] });
    }
  }

  /**
   * Update Selected Options
   * Updates the preview matches in the redux store and store a temporary list of selected values
   * @param {String} option - value of the selected option.
   */
  updateSelectedOptions = (option) => {
    const { selected } = this.state;
    const { filterName, getPreviewMatchesAction } = this.props;

    if (selected.includes(option)) {
      const filteredArray = selected.filter((item) => item !== option);
      this.setState({
        selected: filteredArray,
      }, () => {
        getPreviewMatchesAction(filterName, _.get(this.state, 'selected'));
      });
    } else {
      this.setState({
        selected: [...selected, option],
      }, () => {
        getPreviewMatchesAction(filterName, _.get(this.state, 'selected'));
      });
    }
  };

  /**
   * Clear Filter
   * Clears a filter from the redux store and refreshes the component.
   */
  clearFilter = () => {
    const {
      filterName,
      removeSingleFilterAction,
      router,
    } = this.props;

    this.setState({ selected: [] });
    removeSingleFilterAction({ name: filterName }, router);
  };

  /**
   * Apply Filter
   * Applies a filter and set the redux store selected state.
   */
  applyFilter = () => {
    const { selected } = this.state;
    const {
      filterName, addSingleFilterAction, router,
    } = this.props;

    if (selected.length > 0) {
      addSingleFilterAction({ name: filterName, value: selected }, router);
    } else {
      this.clearFilter();
    }
  };

  /**
   * Handle Checkbox State
   * Handles the click of a checkbox in the dropdown menu.
   * @param {String} item - value of the selected item.
   */
  handleCheckboxState = (item) => {
    const { selected } = this.state;

    return selected.includes(item);
  };

  /**
   * Load Initial Checkbox State
   * Loads the initial checkbox state in the dropdown menu.
   */
  loadInitialCheckboxState = () => {
    const { appliedFilters, filterName } = this.props;
    const { selected, initialDataLoaded } = this.state;

    if (!initialDataLoaded) {
      if (selected.length === 0 && _.has(appliedFilters, filterName)) {
        this.setState({ selected: appliedFilters[filterName] });
        this.setState({ initialDataLoaded: true });
      }
    }
  };

  handleToggleSwitch(e) {
    const {
      addSingleFilterAction, removeSingleFilterAction, history, appliedFilters,
    } = this.props;

    if (e) {
      addSingleFilterAction({
        name: 'physical-location',
        value: true,
      }, history);

      // Capture analytics event.
      Analytics.setEvent('FilterApplied', 'filterInteraction');

      const analyticsKeys = [
        { path: 'search.primaryFilter.filterInfo', value: { keyword: 'Applied', category: 'physical-location' } },
        { path: 'search.filter', value: Analytics.getSetFiltersFromState(appliedFilters) },
      ];

      Analytics.setMultipleProps(analyticsKeys);
      Analytics.track('event');
      Analytics.clearMultipleProps(analyticsKeys);
      Analytics.clearEvent();
    } else {
      removeSingleFilterAction({
        name: 'physical-location',
      }, history);
    }
  }

  /**
 * Retrun Physical Location Toggle
 * @returns JSX.Element - The physical location toggle.
 */
  renderPhysicalLocationToggle() {
    const { appliedFilters } = this.props;
    const selected = _.has(appliedFilters, 'physical-location');

    return (
      <Switch
        isEmphasized
        UNSAFE_className="physical-location-toggle"
        isSelected={selected}
        onChange={(e) => this.handleToggleSwitch(e)}
      // isDisabled={!_.has(appliedFilters, 'location.country')}
      >
        <Translate value="partner_finder.filters.physical_location" />
      </Switch>
    );
  }

  countryFilterHeader = () => (
    <Flex alignItems="center">
      <Translate value="partner_finder.filters.countries_label" />
      <DialogTrigger type="popover">
        <ActionButton isQuiet UNSAFE_className="multi-select-filter-description-country">
          <Info aria-label="Information" size="S" />
        </ActionButton>
        <Dialog>
          <Content>
            <Translate value="partner_finder.filters.country_tooltip" className="multi-select-description-text" />
          </Content>
        </Dialog>
      </DialogTrigger>
      {this.renderPhysicalLocationToggle()}
    </Flex>
  );

  /**
 * Detect Mobile
 * Detects the viewport and sets the global isMobile variable.
 */
  detectMobile() {
    // Detect if we are on a mobile device.
    const mql = window.matchMedia('(max-width: 600px)');
    this.isMobile = mql.matches;
  }

  /**
   * Render Multi Select Filter
   * Renders the multi-select filter component.
   */
  renderMultiSelectFilter() {
    const {
      filterName,
      appliedFilters,
      facets,
      resultsCount,
      nameTranslationPath,
      infoTranslationPath,
    } = this.props;
    const { dialogOpen } = this.state;
    const selectedClass = _.size(appliedFilters[filterName]) > 0 ? `selected selected-${filterName}` : '';
    // If we are on mobile, give different css styling to JSX
    const isMobileView = this.isMobile ? 'mobile-' : '';

    // JSX for the Dropdown button

    const dropdownButton = (
      <ActionButton onPress={() => this.loadInitialCheckboxState()} UNSAFE_className={`filterButton ${selectedClass}`}>
        <span>{I18n.t(nameTranslationPath)}</span>
        <div className="caret"><ChevronDown size="XS" /></div>
      </ActionButton>
    );

    // JSX for the "X" Clear button
    const clearFilterButton = (
      <ActionButton
        UNSAFE_className={`clearFilterButton ${selectedClass}`}
        data-test-id={`${filterName}-filter-clear`}
        onPress={() => this.clearFilter()}
      >
        <div className="separator" />
        {selectedClass !== '' ? (
          <>
            <div className="clearFilter"><Close size="XS" /></div>
            <div className="msf-applied-results">
              {_.size(appliedFilters[filterName])}
            </div>
          </>
        ) : (
          <div className="smallCaret"><ChevronDown size="XS" /></div>
        )}
      </ActionButton>
    );

    // JSX for the checkboxes
    const checkBoxes = (
      <>
        {facets[filterName].options.map((item) => (
          <Checkbox
            key={item.id}
            isSelected={this.handleCheckboxState(item.id)}
            onChange={() => this.updateSelectedOptions(item.id)}
            isDisabled={!dialogOpen}
          >
            {item.name}
          </Checkbox>
        ))}
      </>
    );

    // JSX for the Preview Result
    const renderPreviewResults = (
      <>
        {facets[filterName].loadingPreviewMatches
          ? (
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          ) : (
            <Heading level={4}>
              <Translate
                value="partner_finder.filters.preview_results"
                resultsCount={
                  facets[filterName].previewMatches !== null
                    ? addCommaSeparator(facets[filterName].previewMatches)
                    : addCommaSeparator(resultsCount)
                }
              />
            </Heading>
          )}
      </>
    );

    // JSX for the apply button
    const applyButton = (close) => (
      <>
        <ActionButton
          isDisabled={!dialogOpen}
          UNSAFE_className="clearSelectionButton"
          isQuiet
          onPress={() => this.clearFilter()}
        >
          <Translate value="partner_finder.filters.clear" />
        </ActionButton>
        <Button
          isDisabled={!dialogOpen}
          variant="cta"
          onPress={() => this.applyFilter()}
          onPressEnd={close}
        >
          <Translate value="partner_finder.filters.apply" />
        </Button>
      </>
    );

    return (
      <>
        <DialogTrigger
          onOpenChange={
            (e) => {
              if (this.isMobile) {
                setTimeout(() => {
                  this.setState({ dialogOpen: e });
                }, 300);
              }
            }
          }
          isDismissable
          hideArrow={this.isMobile}
          type={this.isMobile ? 'modal' : 'popover'}
          placement={this.isMobile ? '' : 'bottom left'}
          data-test-id={this.isMobile ? 'multi-select-filter-dialogTrigger' : 'multi-select-filter-popoverTrigger'}
        >
          {dropdownButton}
          {(close) => (
            <Dialog UNSAFE_className={`${isMobileView}msf-dialog`}>
              <Heading>
                {
                  filterName === 'country' ? this.countryFilterHeader()
                    : I18n.t(nameTranslationPath)
                }

                {infoTranslationPath
                  && (
                    <DialogTrigger type="popover">
                      <ActionButton isQuiet UNSAFE_className="multi-select-filter-description">
                        <Info aria-label="Information" size="S" />
                      </ActionButton>
                      <Dialog>
                        <Content UNSAFE_className="multi-select-tooltip-content">
                          <Translate value={infoTranslationPath} dangerousHTML className="multi-select-description-text" />
                        </Content>
                      </Dialog>
                    </DialogTrigger>
                  )}
              </Heading>
              <Divider UNSAFE_className={`${isMobileView}divider`} />
              <Content>
                <div className={`${isMobileView}checkbox-container`}>
                  {checkBoxes}
                </div>
              </Content>
              <Divider UNSAFE_className={`${isMobileView}divider`} />
              <ButtonGroup id="button-group">
                <div className={`${isMobileView}filterContent-bottom-section`}>
                  <div className={`${isMobileView}buttons-container`}>
                    { filterName !== 'country' ? renderPreviewResults : <div />}
                    <div className={`${isMobileView}apply-button-group`}>
                      {applyButton(close)}
                    </div>
                  </div>
                </div>
              </ButtonGroup>
            </Dialog>
          )}
        </DialogTrigger>
        {clearFilterButton}
      </>
    );
  }

  // Render the component.
  render() {
    const { filterName } = this.props;
    return (
      <>
        {filterName === 'country' ? this.renderMultiSelectFilter()
          : (
            <div className="cmp-multiSelectFilter" style={{ whiteSpace: 'nowrap' }} id={`${filterName}-filter`}>
              {this.renderMultiSelectFilter()}
            </div>
          )}
      </>
    );
  }
}

MultiSelectFilter.propTypes = {
  /**
   * filterName {String} - A string provided by FilterPage
   * the name of the Filter
   */
  filterName: PropTypes.string.isRequired,
  /**
   * addSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that adds a filter to the redux store.
   */
  addSingleFilterAction: PropTypes.func.isRequired,
  /**
   * updatePreviewMatchesAction {Function} - A function provided by mapDispatchToProps
   * that updates a facet previewMatches from the redux store.
   */
  getPreviewMatchesAction: PropTypes.func.isRequired,
  /**
   * removeSingleFilterAction {Function} - A function provided by mapDispatchToProps
   * that removes a filter from the redux store.
   */
  removeSingleFilterAction: PropTypes.func.isRequired,
  /**
   * resultsCount {Number} - An integer provided by mapStateToProps
   * that contains the total results with the current applied filters
   */
  resultsCount: PropTypes.number.isRequired,
  /**
   * facets {Object} - An object provided by mapStateToProps
   * that contains solution/industry/partnerLevel facet options.
   */
  facets: PropTypes.shape({
    solution: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      previewMatches: PropTypes.number,
      loadingPreviewMatches: PropTypes.bool,
    }),
    industry: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      previewMatches: PropTypes.number,
      loadingPreviewMatches: PropTypes.bool,
    }),
    level: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      previewMatches: PropTypes.number,
      loadingPreviewMatches: PropTypes.bool,
    }),
  }),
  /**
   * appliedFilters {Object} - An object provided by mapStateToProps
   * that contains the selected filter data.
   */
  appliedFilters: PropTypes.shape({
    solution: PropTypes.arrayOf(PropTypes.string),
    industry: PropTypes.arrayOf(PropTypes.string),
    level: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.any,
    country: PropTypes.arrayOf(PropTypes.string),
  }),
  /**
   * router {Object} - An object provided by React Router that allows navigation.
   */
  router: PropTypes.shape({}).isRequired,
  /**
   * nameTranslationPath {Sring} - A string that is the path of the translation
   * string for the filter name.
   */
  nameTranslationPath: PropTypes.oneOfType([
    PropTypes.string,
    // PropTypes.element
  ]).isRequired,
  /**
   * infoTranslationPath {Sring} - A string that is the path of the translation
   * string for the filter description.
   */
  infoTranslationPath: PropTypes.string,
  history: PropTypes.any,
};

MultiSelectFilter.defaultProps = {
  facets: PropTypes.shape({
    solution: {},
    industry: {},
    partnerLevel: {},
  }),
  appliedFilters: {},
  infoTranslationPath: null,
  history: '',
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  resultsCount: state.results.totalResults,
  facets: state.facets,
  appliedFilters: state.filter.selected,
});

const mapDispatchToProps = {
  addSingleFilterAction: addSingleFilter,
  getPreviewMatchesAction: getPreviewMatches,
  removeSingleFilterAction: removeSingleFilter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiSelectFilter));
