/* eslint-disable max-len */
// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Spectrum
import {
  Grid,
  View,
  Flex,
  ProgressCircle,
  IllustratedMessage,
  Heading,
  Content,
} from '@adobe/react-spectrum';

// Spectrum Illustration
import NoSearchResults from '@spectrum-icons/illustrations/NoSearchResults';

// lodash
import _ from 'lodash';

// SEO
import { Helmet } from 'react-helmet';

// I18n
import { Translate, I18n } from 'react-redux-i18n';

// Framer Motion
import { AnimatePresence, motion } from 'framer-motion';
import { PageVariants, PageTransitions } from '../../util/FramerMotionConfig';

// withRouter
import { withRouter } from '../withRouter';

// Local project components
import FilterContainer from '../FilterContainer';
import ToggleFilter from '../ToggleFilter';
import SpecializedToggleFilter from '../SpecializedToggleFilter';
import LocationFilter from '../LocationFilter';
import MultiSelectFilter from '../MultiSelectFilter';
/* commenting this code to remove company-size filter from view  */
/* import CompanySizeFilter from '../CompanySizeFilter'; */
import ContactBlade from '../ContactBlade';
import HeroBlade from '../HeroBlade';
import LoadMore from '../LoadMore';
import ListingCard from '../ListingCard';

// Redux Actions
import { initializeFilters } from '../../actions';
import { getMoreResults } from '../../actions/filterActions';

// Styles
import '../css/FilterPage.scss';

class FilterPage extends Component {
  constructor(props) {
    super(props);
    const {
      initializeFiltersAction,
      initialized,
      router,
    } = this.props;
    if (!initialized) {
      initializeFiltersAction(router);
    }
  }

  componentDidMount() {
    const { scrollPosition, initialized } = this.props;

    // If app has already initialized and we have a scroll position set,
    // scroll back to where the user was.
    if (initialized && scrollPosition > 0) {
      window.scroll({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }

    // Implement Infinite Scrolling
    const intersectionObserver = new IntersectionObserver((entries) => this.loadMoreResults(entries), {
      rootMargin: '0px 0px 200px 0px',
    });
    // Start Observing
    intersectionObserver.observe(document.querySelector('#infinite-scroll-target'));
  }

  // Jump to top of page.
  componentWillUnmount() {
    window.scroll({ top: 0 });
  }

  /**
   * loadMoreResults
   * Infinite scrolling load more results implementation.
   */
  loadMoreResults = (entries) => {
    // Props
    const {
      initialized,
      loadingCards,
      cards,
      totalResults,
      getMoreResultsAction,
    } = this.props;

    // If we are initialized, not loading cards, and not at the end of our results set.
    if (entries[0].isIntersecting
      && initialized && !loadingCards && (totalResults > Object.keys(cards).length)) {
      // Load more results.
      getMoreResultsAction();
    }
  };

  /**
   * renderCards
   * Renders a card for every result in the redux store.
   */
  renderCards = () => {
    const {
      cards, router, loadingCards, initialized,
    } = this.props;

    if (_.size(cards) > 0 || loadingCards || !initialized) {
      const cardArray = Object.keys(cards).map((obj) => {
        const card = cards[obj];
        return (
          <ListingCard
            key={card.id}
            router={router}
            url={card.id}
            partnerName={card.name}
            shortDescription={card.summary}
            partnerLevel={card.level}
            partnerLogoUrl={card.logoUrl}
            certifications={card.certifications}
            certifiedEmployees={card.certifiedEmployees}
            specialized={card.specialized}
            preloading={card.preloading}
            badge={card.badge}
          />
        );
      });

      // Find how many extra spots we have in the row.
      const cardRemainder = _.size(cards) % 3 > 0 ? 3 - (_.size(cards) % 3) : 0;

      // Add spacer divs.
      if (cardRemainder) {
        // For every space we have, add a spacer div.
        for (let i = 0; i < cardRemainder; i += 1) {
          cardArray.push(
            <div className="cmp-listing-card-spacer" key={`spacer-${i}`} />,
          );
        }
      }

      return cardArray;
    }
    // No results
    return (
      <div className="no-results-container">
        <IllustratedMessage>
          <NoSearchResults />
          <Heading><Translate value="partner_finder.filters.no_results_header" /></Heading>
          <Content><Translate value="partner_finder.filters.no_results_description" /></Content>
        </IllustratedMessage>
      </div>
    );
  };

  /**
   * renderLoadingIndicator
   * Renders an overlay over all of the cards when a new filter is
   * selected.
   */
  renderLoadingIndicator = () => {
    const { loadingCards, loadingMoreCards } = this.props;
    if (loadingCards && !loadingMoreCards) {
      return (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.25 }}
          className="loading-motion-container"
        >
          <div className="loading-cards">
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          </div>
        </motion.div>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <Helmet titleTemplate={`%s | ${I18n.t('partner_finder.seo.app_title')}`}>
          <title>{I18n.t('partner_finder.seo.filter_page_title')}</title>
          <meta name="description" content={I18n.t('partner_finder.seo.filter_page_description')} />

          <meta property="og:title" content={`${I18n.t('partner_finder.seo.filter_page_title')} | ${I18n.t('partner_finder.seo.app_title')}`} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={I18n.t('partner_finder.seo.app_title')} />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/thumb.png`} />
          <meta property="og:description" content={I18n.t('partner_finder.seo.filter_page_description')} />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="480" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={`${I18n.t('partner_finder.seo.filter_page_title')} | ${I18n.t('partner_finder.seo.app_title')}`} />
          <meta name="twitter:description" content={I18n.t('partner_finder.seo.filter_page_description')} />
          <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/img/thumb.png`} />
          <meta name="twitter:image:alt" content="Adobe" />
          <meta name="twitter:site" content="@Adobe" />
          <meta name="twitter:creator" content="@Adobe" />
        </Helmet>
        <motion.div
          exit="out"
          animate="in"
          initial="out"
          variants={PageVariants}
          transition={PageTransitions}
        >
          <HeroBlade />
          <Grid
            id="filter-page-container"
            areas={[' . main . ']}
          >
            <View gridArea="main">
              <FilterContainer>
                <LocationFilter />
                <MultiSelectFilter
                  filterName="solution"
                  nameTranslationPath="partner_finder.filters.products_label"
                  infoTranslationPath="partner_finder.filters.products_info"
                />

                <ToggleFilter />

                <MultiSelectFilter
                  filterName="industry"
                  nameTranslationPath="partner_finder.filters.industries_label"
                  infoTranslationPath="partner_finder.filters.industries_info"
                />
                <MultiSelectFilter
                  filterName="level"
                  nameTranslationPath="partner_finder.filters.partner_level_label"
                  infoTranslationPath="partner_finder.filters.partner_level_info"
                />
                {/* commenting this code to remove company-size filter from view  */}
                {/* <CompanySizeFilter /> */}
                <SpecializedToggleFilter />
              </FilterContainer>
              <Flex UNSAFE_className="card-container">
                <AnimatePresence>
                  {this.renderLoadingIndicator()}
                </AnimatePresence>
                {this.renderCards()}
              </Flex>
              <div id="infinite-scroll-target" />
              <LoadMore />
            </View>
          </Grid>
          <ContactBlade />
        </motion.div>
      </>
    );
  }
}

FilterPage.propTypes = {
  /**
   * initializeFiltersAction {Function} - A function provided by mapDispatchToProps
   * that loads all of the facets and results..
   */
  initializeFiltersAction: PropTypes.func.isRequired,
  /**
   * getMoreResultsAction {Function} - A function provided by mapDispatchToProps
   * that loads more results.
   */
  getMoreResultsAction: PropTypes.func.isRequired,
  /**
   * initialized {Boolean} A boolean that tells if the application has already been initialized.
   */
  initialized: PropTypes.bool.isRequired,
  /**
   * scrollPosition {Number} A number of the user's last scroll position.
   */
  scrollPosition: PropTypes.number.isRequired,
  /**
   * router {Object} The router object to navigate around the app.
   */
  router: PropTypes.shape({
    searchParams: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  /**
   * cards {Object} The object with all results.
   */
  cards: PropTypes.shape({}),
  /**
   * loadingCards {Boolean} Whether we are loading any results currently.
   */
  loadingCards: PropTypes.bool.isRequired,
  /**
   * loadingMoreCards {Boolean} If we are loading additional cards via infinite scrolling.
   */
  loadingMoreCards: PropTypes.bool.isRequired,
  /**
   * totalResults {Number} The total number of matching results.
   */
  totalResults: PropTypes.number.isRequired,
};
FilterPage.defaultProps = {
  cards: [],
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  cards: state.results.cards,
  loadingCards: state.results.loadingResults,
  loadingMoreCards: state.results.loadingMoreResults,
  totalResults: state.results.totalResults,
  initialized: state.facets.initialized,
  scrollPosition: state.results.scrollPosition,
});

const mapDispatchToProps = {
  initializeFiltersAction: initializeFilters,
  getMoreResultsAction: getMoreResults,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterPage));
