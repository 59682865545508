/* eslint-disable no-underscore-dangle */
// React
import React from 'react';
import { createRoot } from 'react-dom/client';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

// Redux Thunk for having async action creators
import thunk from 'redux-thunk';

// React Spectrum
import { defaultTheme, Provider as SpectrumProviderV3 } from '@adobe/react-spectrum';

// I18n
import { setLocale, loadTranslations, syncTranslationWithStore } from 'react-redux-i18n';
import { supportedLocales, fallbackLocale } from './i18n/config';
import translations from './i18n/translations';

// Our main App component, and reducers.
import App from './components/App';
import reducers from './reducers';

// Constants
import { SET_PREVIEW_MODE } from './constants';

// Analytics
//  import Analytics from './components/Analytics';

// aep tracking
import AEPTracking from './util/aepTracking';
// Util
import dispatchWindowEvent from './util/dispatchWindowEvent';

// Wire up Redux Dev Tools and create the store from our reducers, wire up thunk middleware.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

// Load translations to Redux, set default locale.
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

// Check if we are receiving a locale in the query parameter.
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('locale')) {
  const paramLocale = urlParams.get('locale');
  const finalLocale = Object.keys(supportedLocales).includes(paramLocale)
    ? supportedLocales[paramLocale]
    : fallbackLocale;
  localStorage.setItem('i18n_lang', finalLocale);
  store.dispatch(setLocale(finalLocale));
} else {
  store.dispatch(setLocale(localStorage.getItem('i18n_lang') || 'en_US'));
}

// Preview mode
if (urlParams.has('preview') && urlParams.get('preview') === 'true') {
  // Dispatch the preview mode action.
  store.dispatch({ type: SET_PREVIEW_MODE, payload: true });
  window.sessionStorage.setItem('mandatorySignIn', true);
} else {
  window.sessionStorage.removeItem('mandatorySignIn');
}

// call  aep tracking script ESSSAL-116438
AEPTracking.init();
const loadFeds = () => {
  // Load FEDS
  const fedsLib = document.createElement('script');
  fedsLib.src = window.directoryConfig.feds.jslib;
  document.body.appendChild(fedsLib);
};

const hideSearchIcon = () => {
  const search = document.querySelector('.feds-search-trigger');
  search.style.display = 'none';
};

// IMS Configuration
window.adobeid = {
  client_id: window.directoryConfig.ims.client,
  environment: window.directoryConfig.ims.environment,
  scope: 'AdobeID,openid',
  locale: 'en_US',
  async onReady() {
    // Dispatch a window event
    dispatchWindowEvent('adobeIMS:ready');

    // Whether we force the user to sign in because of preview mode.
    const mandatorySignIn = window.sessionStorage.getItem('mandatorySignIn');

    // If not signed in
    if (!window.adobeIMS.isSignedInUser()) {
      // Enforce mandatory sign if if it's requested
      if (mandatorySignIn) {
        window.adobeIMS.signIn();
      }
      window.addEventListener('feds.events.experience.loaded', hideSearchIcon);
      loadFeds();
    } else {
      dispatchWindowEvent('adobeIMS:profileReady');
    }
  },
};

// Load IMS
const imsLib = document.createElement('script');
imsLib.src = window.directoryConfig.ims.jslib;
document.body.appendChild(imsLib);

// Highlight the active page in GNAV.
const setActivePageInGnav = () => {
  // Grab the Partner Directory Link
  const linkElement = document.querySelector(".feds-navLink[daa-ll='Partner Directory-7']") || document.querySelector(".feds-navLink[daa-ll='Partner Directory-6']");
  if (linkElement) {
    // Add our active classes.
    linkElement.classList.add('feds-navLink--active', 'feds-navLink--disabled');
    // Make sure it's not clickable.
    linkElement.removeAttribute('href');
  }
};

// Attach listeners to chat links
const attachJarvis = () => {
  const chatLinks = document.querySelectorAll("a[href='#openjarvis']");
  chatLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      // Prevent click
      e.preventDefault();
      // Open Jarvis chat window.
      window.feds.services.jarvis.openChat();
    });
  });
};

// Run all updates to Feds GNAV.
const updateFeds = () => {
  // Highlight the current page.
  setActivePageInGnav();
  // Attach Jarvis to links.
  attachJarvis();
};

// Wait for FEDS to fully load and then set the active page.
window.addEventListener('feds.events.experience.loaded', updateFeds);

// Scroll Fix
if ('scrollRestoration' in window.history) {
  // Back off, browser, I got this...
  window.history.scrollRestoration = 'manual';
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <SpectrumProviderV3 theme={defaultTheme} colorScheme="light">
      <App />
    </SpectrumProviderV3>
  </Provider>,
);
