/* eslint-disable class-methods-use-this */
// React Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// React Router
import { withRouter } from './withRouter';

// Style
import './css/HeroBanner.scss';

class HeroBanner extends Component {
  constructor(props) {
    super(props);

    this.bannerRef = React.createRef(null);
  }

  handleResize = () => {
    if (this.bannerRef.current) {
      // Calculate the height of the banner based on the width of bannerRef and 16:9 aspect ratio.
      //  Recommended image size is 1920 x 200
      this.bannerRef.current.style.height = `${this.bannerRef.current.offsetWidth * (200 / 1920)}px`;
    }
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Render Hero Banner
   * Renders the Herp Banner component
   */
  renderHeroBanner = () => {
    const { heroImageUrl } = this.props;

    return (
      <div ref={this.bannerRef} className="hero-banner-image" style={{ backgroundImage: `url(${heroImageUrl || `${process.env.PUBLIC_URL}/img/default-detail-bg.jpg`})`, backgroundSize: 'cover' }} />
    );
  };

  render() {
    return (
      <div className="heroBanner">
        {this.renderHeroBanner()}
      </div>
    );
  }
}

HeroBanner.propTypes = {
  /**
   * heroImageUrl {String} - A string provided by the mapStateToProps
   * the image url of the hero banner.
   */
  heroImageUrl: PropTypes.string,
};

HeroBanner.defaultProps = {
  heroImageUrl: null,
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  heroImageUrl: state.partnerDetail.companyInfo.heroImageUrl,
});

export default withRouter(connect(mapStateToProps, null)(HeroBanner));
