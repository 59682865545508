export const PageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const PageTransitions = {
  duration: 0.3,
};

export const LoadingAnimation = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: { duration: 1 },
};

export const ContentBladeAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: { ease: 'easeIn', duration: 1 },
};

export const LocationFilterAnimation = {
  initial: {
    opacity: 0,
    left: '-97px',
    marginRight: '-97px',
  },
  animate: {
    position: 'relative',
    opacity: 1,
    left: '0px',
    marginRight: '0px',
  },
  exit: {
    opacity: 0,
    left: '-97px',
    marginRight: '-97px',
  },
  transition: { ease: 'easeInOut', duration: 0.5 },
};

export const DetailTabAnimation = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
  },
  transition: { ease: 'easeInOut', duration: 0.3 },
};
